import React, { useContext, useMemo } from 'react';
import { Drawer, Box, Typography, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Button, useTheme, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Carrinho } from '../contexts/carrinho';
import { Link } from 'react-router-dom';
import { CounterField } from './counterField';
import { getIcon } from './icons';
import { corrigirNome, numberToReal } from './masks';
import { whatVlrFinal } from './oneProduct';

export function CarrinhoDrawer() {
    const { data: carrinhoData, add, isLoading, openCart, handleCart, changeQuantidade } = useContext(Carrinho);
    const isDrawerOpen = openCart;
    const theme = useTheme();
    const subtotal = useMemo(() => {
        return carrinhoData?.reduce((acc, item) => acc + whatVlrFinal(item) * item.quantidade, 0).toFixed(2);
    }, [carrinhoData]);
    const itensPorLoja = useMemo(() => {
        return carrinhoData?.reduce((acc, item) => {
            if (!acc[item.loja_nome]) {
                acc[item.loja_nome] = [];
            }
            acc[item.loja_nome].push(item);
            return acc;
        }, {});
    }, [carrinhoData]);

    const toggleDrawer = () => () => {
        handleCart();
    };

    function handleQuantidade(item, quantidade) {
        if (quantidade < 1) return;
        changeQuantidade(item, quantidade);
    }
    const finalizarDisabled = carrinhoData?.length === 0
    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{ sx: { background: theme.palette.background.default, width: { xs: '100%', sm: '430px' }, borderRadius: '12px', boxShadow: theme.shadows[5] } }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>Carrinho de compras</Typography>
                <IconButton onClick={toggleDrawer(false)} color="inherit">
                    <CloseIcon />
                </IconButton>
            </Box>
            {carrinhoData?.length > 0 ? (
                <Box sx={{
                    height: '75%', overflowY: 'auto', padding: 2, '&::-webkit-scrollbar': { background: theme.palette.background.default },
                    '&::-webkit-scrollbar-thumb': { background: theme.palette.background.paper },
                }}>
                    {itensPorLoja && Object.entries(itensPorLoja).map(([lojaNome, itens], idx) => (
                        <Box key={idx} sx={{ mb: 3, padding: 1, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 2 }}>
                                <Avatar src={itens[0]?.loja_avatar} sx={{ width: 48, height: 48 }} />
                                <Box>
                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{lojaNome}</Typography>
                                    <Typography component={Link} to={`loja?id=${itens[0]?.loja}`} sx={{ fontSize: 13, color: theme.palette.secondary.dark, textDecoration: 'none' }} onClick={() => handleCart()}>
                                        Compre mais dessa loja
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider />
                            <List>
                                {itens.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem alignItems="center" sx={{ paddingY: 1, gap: 2 }}>
                                            <ListItemAvatar >
                                                <Avatar component={Link} to={`/produto?id=${item?.cod_barra}`} variant="square" src={item.image} alt={item.nome} sx={{ width: 30, height: 30, objectFit: 'fill' }} onClick={() => handleCart()} />
                                            </ListItemAvatar>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography component={Link} to={`/produto?id=${item?.cod_barra}`} sx={{ textDecoration: 'none', fontSize: 15, fontWeight: 500, color: theme.palette.text.primary }} onClick={() => handleCart()}>{corrigirNome(item.nome)}</Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                                                    <CounterField quantidade={String(item.quantidade)} handleDecrease={() => handleQuantidade(item, parseInt(item.quantidade) - 1)} handleIncrease={() => handleQuantidade(item, parseInt(item.quantidade) + 1)} />
                                                    <IconButton aria-label="delete" size="small" sx={{ ml: 1 }} onClick={() => add(item)}>
                                                        <DeleteIcon fontSize="small" color='error' />
                                                    </IconButton>
                                                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.secondary.dark }}>
                                                        R$ {numberToReal(whatVlrFinal(item))}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </ListItem>
                                        {item.qtd && (
                                            <Typography color='secondary' sx={{ fontSize: 12, paddingLeft: 2 }}>{`Na compra de ${item.qtd}un R$${numberToReal(item.qtdPreco)}`}</Typography>
                                        )}
                                        {item?.error &&
                                            <Typography color='error' sx={{ fontSize: 12, paddingLeft: 2 }}>{item.error}</Typography>
                                        }
                                    </React.Fragment>
                                ))}
                            </List>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box sx={{ width: '100%', height: '75%' }}>
                    <Alert severity='info'>Você ainda não possui itens no carrinho</Alert>
                </Box>
            )}
            <Box sx={{ padding: 2, borderTop: `1px solid ${theme.palette.divider}`, position: 'sticky', bottom: 0, background: theme.palette.background.default }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Subtotal:</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: 25, }}>R$ {numberToReal(subtotal)}</Typography>
                </Box>
                <Button disabled={finalizarDisabled} component={Link} to='/finalizar-compra' fullWidth size='large' variant="contained" onClick={() => handleCart()} sx={{
                    background: theme.palette.background.default,
                    borderWidth: 1,
                    borderColor: theme.palette.secondary.main,
                    borderStyle: 'solid',
                    color: theme.palette.secondary.main,
                    textTransform: 'none',
                    fontWeight: 'bold',
                    '&:hover': {
                        background: theme.palette.secondary.main,
                        color: theme.palette.text.onPrimary,
                    },
                    '&.Mui-disabled': {
                        background: theme.palette.background.default,
                        borderColor: theme.palette.action.disabled,
                        color: theme.palette.action.disabled,
                    },
                }}>
                    Prosseguir com pedido
                </Button>
            </Box>
            {isLoading && (
                <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                    {getIcon(25, theme.palette.secondary.main, 100, false, true)}
                    <Typography sx={{ fontSize: 20, color: theme.palette.text.primary }}>Calculando Valores</Typography>
                </Box>
            )}
        </Drawer>
    );
}
