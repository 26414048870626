import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { Banners } from "./components/Banners";
import { FBFADM } from "../../apis/FBFAdm";
import { useEffect, useState } from "react";
import { Beneficios } from "./components/beneficios";
import { Sections } from "./components/sections";



export function Inicio({ }) {
    const [data, setData] = useState(null)
    const theme = useTheme()
    async function getHome(counter) {
        if (counter > 3) {
            setData(false)
            return
        }
        const get = await FBFADM('marketplace/public/getHome', 'GET', null, null)
        if (get?.status !== 200) {
            getHome(counter + 1)
        }
        else setData(get?.apiReturn?.apiReturn)
    }

    useEffect(() => {
        getHome(1)
    }, [])

    return (
        <Container>
            <Box id={'home'} sx={{ display: 'flex', flexDirection: 'column', gap: 5, width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}>
                    <Banners data={data} />
                    <Beneficios data={data} />
                </Box>
                <Sections data={data} />
            </Box>
        </Container>
    )
}