import { Box, Skeleton, Typography, Select, MenuItem, Pagination, useTheme, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { OneProduct } from "../../../components/oneProduct";
import { MapGoogle } from "./map";

export function ListItens({ lojaId, loja, produtos, handleChangePage, changeLimit }) {
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const allProdutos = Array.isArray(produtos) ? [...produtos] : null
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = useState('Mais procurados');
    const [hasMorePages, setHasMorePages] = useState(true);
    const lojaLatLng = loja?.latitude ? { lat: parseFloat(loja?.latitude), lng: parseFloat(loja?.longitude) } : null

    useEffect(() => {
        if (!produtos || produtos.length < itemsPerPage) {
            setHasMorePages(false);
        } else {
            setHasMorePages(true);
        }
    }, [produtos, itemsPerPage]);

    function handleChangePagination(event, value) {
        setPage(value);
        handleChangePage(value);
    }

    function handleOrderChange(event) {
        setOrder(event.target.value);
    }

    function handleItemsPerPageChange(value) {
        setItemsPerPage(value);
        changeLimit(value);
        setPage(1);
    }

    function orderProdutos() {
        if (!allProdutos) return null;
        if (order === 'Menor preço') {
            return allProdutos.sort((a, b) => parseFloat(a.vlr_final) - parseFloat(b.vlr_final));
        } else if (order === 'Maior preço') {
            return allProdutos.sort((a, b) => parseFloat(b.vlr_final) - parseFloat(a.vlr_final));
        }
        return allProdutos;
    }

    const sortProdutos = orderProdutos();
    const totalPages = hasMorePages ? page + 1 : page;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>Ordenar Página:</Typography>
                    <Select
                        value={order}
                        onChange={handleOrderChange}
                        size="small"
                        sx={{ minWidth: 150 }}
                    >
                        <MenuItem value="Mais procurados">Mais procurados</MenuItem>
                        <MenuItem value="Menor preço">Menor preço</MenuItem>
                        <MenuItem value="Maior preço">Maior preço</MenuItem>
                    </Select>
                </Box>
                {!isSmallScreen && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography>Exibir:</Typography>
                        <Select
                            value={String(itemsPerPage)}
                            onChange={(e) => handleItemsPerPageChange(e.target.value)}
                            size="small"
                            sx={{ minWidth: 100 }}
                        >
                            <MenuItem value={8}>8 por página</MenuItem>
                            <MenuItem value={20}>20 por página</MenuItem>
                            <MenuItem value={50}>50 por página</MenuItem>
                        </Select>
                    </Box>
                )}
            </Box>
            <Box sx={{ display: isMobile ? 'grid' : 'flex', gridTemplateColumns: '1fr 1fr', justifyContent: isMobile ? 'center' : 'space-between', width: '100%', gap: 0, flexWrap: 'wrap' }}>
                {sortProdutos && sortProdutos.length > 0 ? (
                    sortProdutos.map((e, idx) => (
                        <OneProduct key={idx} produto={e} margin={isMobile ? 0.1 : 0.5} width={isMobile ? '75%' : 200} isTiniPrice={true} loja={lojaId} />
                    ))
                ) : produtos === null ? (
                    Array.from(new Array(6)).map((_, index) => (
                        <Box key={index} sx={{ width: 250, margin: 2 }}>
                            <Skeleton variant="rectangular" width={250} height={280} />
                            <Skeleton />
                            <Skeleton width="60%" />
                        </Box>
                    ))
                ) : (
                    <Box sx={{ display: 'flex', padding: 2, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <Box sx={{ width: '50%' }}>
                            <Typography>Ops! Não foram encontrados produtos para os filtros selecionados.</Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4, width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePagination}
                    color="primary"
                />
            </Box>
            {isSmallScreen && lojaLatLng && (
                <Box sx={{ flex: 1, marginTop: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Localização</Typography>
                    <MapGoogle center={lojaLatLng} Markers={[{ latitude: lojaLatLng.lat, longitude: lojaLatLng.lng }]} />
                </Box>
            )}
        </Box>
    );
}
