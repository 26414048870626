import { Box, Divider, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { getIcon } from "../../../components/icons"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';



export function Beneficios({ data }) {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'))
    const beneficios = data?.beneficios
    return (
        <Box sx={{ width: '100%', minHeight: 60 }}>
            {Array.isArray(beneficios) ?
                <Box sx={{ padding: 3 }}>
                    <Swiper
                        slidesPerView={1}
                        breakpoints={{
                            700: {
                                slidesPerView: 2,
                            },
                            900: {
                                slidesPerView: 3,
                            },
                            1300: {
                                slidesPerView: 3
                            },
                            1500: {
                                slidesPerView: 4,
                            }
                        }}
                        modules={[Pagination, Navigation, Autoplay]}
                        loop={true}
                        style={{ width: '98%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        autoplay={{
                            delay: 2000
                        }}
                        spaceBetween={15}
                    >
                        {beneficios.map((e, idx) => {
                            return (
                                <SwiperSlide key={e.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Box sx={{ gap: 1, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: 1, borderColor: theme.palette.divider, borderRadius: 3, borderStyle: 'solid', padding: 2, textAlign: 'center', height: 100 }}>
                                        {getIcon(e?.icon, theme.palette.primary.main, 40)}
                                        <Box>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}
                                            >
                                                {e?.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: theme.palette.text.primary }}
                                            >
                                                {e?.texto}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Box>
                : <Skeleton width={'100%'} height={60} variant='rectangular' />
            }
        </Box>
    )
}
{/* <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'flex-start' : 'center', justifyContent: 'flex-start', background: theme.palette.secondary.main, padding: 2 }}>
    {beneficios.map((e) => (
        <Box sx={{ flex: 1, gap: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {getIcon(e?.icon, theme.palette.text.onPrimary, 30)}
            <Typography sx={{ color: theme.palette.text.onPrimary }}>{e?.texto}</Typography>
        </Box>
    ))}
</Box> */}