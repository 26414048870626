import { createContext, useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from "../components/encDec";
import { FBFADM } from "../apis/FBFAdm";
import moment from "moment";



export const Cliente = createContext()

export default function ({ children }) {
    const [data, setData] = useState(null)
    const [enderecos, setEnderecos] = useState([])
    const [cepInfo, setCepInfo] = useState(null)
    const cookies = new Cookies()
    const bandeira = process.env.REACT_APP_API_APP_BANDEIRA
    async function getCliente() {
        let user = cookies.get(`FBFMarketplace${bandeira}-cliente`)
        if (!user) {
            setData(false)
            return
        }
        user = decrypt(user)
        if (!user.token) {
            setData(false)
            return
        }
        const validaCliente = await FBFADM('marketplace/private/validaCliente', 'GET', null, user.token)
        if (validaCliente.status !== 200) {
            cookies.remove(`FBFMarketplace${bandeira}-cliente`)
            setData(false)
            return
        }
        setData(validaCliente?.apiReturn?.apiReturn)
        if (validaCliente?.apiReturn?.enderecos) {
            setEnderecos(validaCliente?.apiReturn?.enderecos)
        }
    }
    async function editCliente(newUser) {
        const save = await FBFADM('marketplace/private/editCliente', 'POST', newUser, data.token)
        const apiData = save?.apiReturn?.apiReturn
        if (apiData) {
            cookies.set(`FBFMarketplace${bandeira}-cliente`, encrypt(apiData), {
                //secure: true,
                expires: moment().add(3, 'days').toDate(),
                sameSite: 'strict',
                maxAge: 999999,
                path: '/'
            })
            setData(apiData)
            return true
        } else {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao editar o cliente.'
            return message
        }
    }
    async function updateCliente(newUser) {
        cookies.set(`FBFMarketplace${bandeira}-cliente`, encrypt(newUser), {
            //secure: true,
            expires: moment().add(3, 'days').toDate(),
            sameSite: 'strict',
            maxAge: 999999,
            path: '/'
        })
        setData(newUser)
        getCliente()
    }
    async function updateEndereco(endereco) {
        if (!endereco?.rua || !endereco?.numero || !endereco?.bairro || !endereco?.cidade || !endereco?.estado || !endereco?.cep) return 'Dados inválidos.'
        const formatEnd = {
            cep: endereco?.cep,
            rua: endereco.rua,
            numero: endereco.numero,
            bairro: endereco.bairro,
            cidade: endereco.cidade,
            estado: endereco.estado,
            complemento: endereco?.complemento
        }
        const save = await FBFADM('marketplace/private/cadastraEnd', 'POST', formatEnd, data.token)
        if (save.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar o endereço.'
            return message
        }
        formatEnd.id = save?.apiReturn?.apiReturn
        const newEnd = [...enderecos]
        newEnd.push(formatEnd)
        setEnderecos(newEnd)
        return true
    }
    async function deleteEndereco(id) {
        if (!id) return 'Id precisa ser informado.'
        const del = await FBFADM(`marketplace/private/delEnd/${id}`, 'DELETE', null, data?.token)
        if (del?.status !== 200) {
            const message = del?.apiReturn?.message ? del?.apiReturn?.message : 'Ocorreu um erro ao excluir o endereço.'
            return message
        }
        const newEnd = enderecos.filter((e) => e.id !== id)
        setEnderecos(newEnd)
        return true
    }
    function logOff() {
        cookies.remove(`FBFMarketplace${bandeira}-cliente`)
        setData(false)
    }
    useEffect(() => {
        getCliente()
    }, [])
    return (
        <Cliente.Provider value={{ data, enderecos, updateEndereco, updateCliente, deleteEndereco, logOff, editCliente }}>
            {children}
        </Cliente.Provider>
    )
}