import { Box, Button, Typography, useTheme, useMediaQuery, Badge } from "@mui/material";
import { getIcon } from "../icons";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Carrinho } from "../../contexts/carrinho";
import { Favoritos } from "../../contexts/favoritos";

export function ButtonsHeader({ handleCart }) {
    const user = true;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const carrinho = useContext(Carrinho)?.data;
    const favoritos = useContext(Favoritos)?.data
    const itemCount = carrinho?.length || 0;
    const itemCountFav = favoritos?.length || 0;
    const location = window.location.pathname

    const data = [
        { title: 'Minha Conta', icon: 33, to: '/minha-conta' },
        { title: 'Meus pedidos', icon: 50, to: '/minha-conta?mod=0' },
        { title: 'Favoritos', icon: 84, to: '/favoritos' },
        { title: 'Carrinho', icon: 85, click: handleCart, disabled: location === '/finalizar-compra' },
    ];

    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            {data.map((e, idx) => {
                const isCarrinho = e.title === 'Carrinho';
                const isVaf = e.title === 'Favoritos'
                return (
                    <Button
                        component={e?.to && Link}
                        to={e?.to && e.to}
                        key={idx}
                        onClick={() => !e?.disabled && e?.click && e.click()}
                        sx={{ textTransform: 'none' }}
                    >
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <Badge
                                badgeContent={isCarrinho ? String(itemCount) : isVaf && String(itemCountFav)}
                                color='warning'
                                sx={{
                                    '& .MuiBadge-badge': {
                                        color: 'white', // Define a cor do conteúdo do badge para branco
                                        backgroundColor: isVaf ? theme.palette.error.main : theme.palette.warning.main, // Mantém a cor de fundo
                                    },
                                }}
                                invisible={!isCarrinho && !isVaf}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: theme.palette.primary.main,
                                    padding: 1.2,
                                    borderRadius: 5,
                                    height: 15,
                                    width: 15
                                }}>
                                    {getIcon(e.icon, theme.palette.text.onPrimary, 20)}
                                </Box>
                            </Badge>
                            {!isSmallScreen &&
                                <Typography sx={{ fontSize: 12, color: theme.palette.text.primary }}>
                                    {e.title}
                                </Typography>
                            }
                        </Box>
                    </Button>
                );
            })}
        </Box>
    );
}
