import BrasilLogo from '../../inc/assets/images/brasilLogo.png'
import BrasilLogoMin from '../../inc/assets/images/brasilMinLogo.png'
import Cartao from '../../inc/assets/cartoes/cartao_brasil.png'

export const BrasilTheme = (mode) => {
    return {
        palette: {
            nome: 'Brasil Poupa Lar',
            logo: BrasilLogo,
            minLogo: BrasilLogoMin,
            cartao: Cartao,
            mode: mode,
            ...(mode === 'light'
                ? {
                    primary: {
                        main: '#009741',
                        light: '#0AD260',
                        dark: '#015425'
                    },
                    secondary: {
                        main: '#1955A6',
                        light: '#2380FE',
                        dark: '#10376C'
                    },
                    tertiary: {
                        main: '#ed3438',
                        light: '#FFB054',
                        dark: '#9A5401'
                    },
                    background: {
                        default: '#FFFFFF',
                        paper: '#EEEEEE',
                        inverse: '#141414'
                    },
                    warning: { main: '#FFAE00' },
                    success: { main: '#66FF33' },
                    error: { main: '#FF3333' },
                    text: {
                        primary: '#000',
                        onPrimary: '#FFFFFF',
                        secondary: '#212529',
                    }
                } : {
                    primary: {
                        main: '#009741',
                        light: '#00FF6E',
                        dark: '#015425'
                    },
                    secondary: {
                        main: '#1955A6',
                        light: '#2380FE',
                        dark: '#10376C'
                    },
                    tertiary: {
                        main: '#E37B00',
                        light: '#FFB054',
                        dark: '#9A5401'
                    },
                    background: {
                        default: '#141414',
                        paper: '#292929',
                        inverse: '#fff'
                    },
                    warning: { main: '#FFAE00' },
                    success: { main: '#66FF33' },
                    error: { main: '#FF3333' },
                    text: {
                        primary: '#949494',
                        onPrimary: '#FFFFFF',
                    }
                }
            )
        }
    }
}