import { Box, Checkbox, Drawer, Fab, IconButton, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { getIcon } from '../../../components/icons';
import { MapGoogle } from './map';

export function FiltersLoja({ loja, filters, changeParamsUrl, closedParam, ObjParams }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const latitude = loja?.latitude ? parseFloat(loja.latitude) : 53.54992;
    const longitude = loja?.longitude ? parseFloat(loja.longitude) : 10.00678;
    const lojaLatLng = { lat: latitude, lng: longitude };
    const toggleDrawer = (open) => () => setDrawerOpen(open);
    const renderFilterContent = () => {
        if (filters === null) return <LoadingSkelFilters />;
        if (filters === false) return <Typography>Ocorreu um erro ao buscar os filtros.</Typography>;
        return Object.keys(filters).map((filterKey) => {
            const filterArray = filters[filterKey];
            if (!Array.isArray(filterArray) || filterArray.length === 0 || filterArray[0] === null) return null;
            return (
                <Box key={filterKey} sx={{ marginBottom: 2 }}>
                    <Box sx={{ borderLeft: 7, borderLeftColor: theme.palette.primary.main, padding: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', color: '#000' }}>{nameToNome(filterKey)}</Typography>
                    </Box>
                    <Box
                        sx={{
                            maxHeight: isMobile ? 200 : 400,
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': { background: theme.palette.background.default },
                            '&::-webkit-scrollbar-thumb': { background: isSmallScreen ? theme.palette.secondary.main : theme.palette.background.paper },
                        }}
                    >
                        {filterArray.map((filterItem) => {
                            const idParam = titleToId(filterKey);
                            const id = filterItem?.[idParam];
                            if (!id) return null;
                            const key = `${filterKey} - ${id}`;
                            const param = titleToParam(filterKey);
                            return (
                                <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Checkbox
                                        checked={ObjParams[filterKey] === String(id)}
                                        onChange={(event) => changeParamsUrl(event, filterItem, nameToParams[filterKey])}
                                    />
                                    <Typography>{filterItem[param]}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            );
        });
    };
    const drawerContent = (
        <Box sx={{ width: isMobile ? '100%' : 300, padding: 2 }}>
            <IconButton onClick={toggleDrawer(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                <CloseIcon />
            </IconButton>
            {renderFilterContent()}
        </Box>
    );
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: 'auto' }}>
            {isSmallScreen ? (
                <>
                    <Fab
                        color="secondary"
                        onClick={toggleDrawer(true)}
                        sx={{ position: 'absolute', left: '5%', bottom: '5%', display: 'flex', alignItems: 'center' }}
                    >
                        {getIcon(95)}
                    </Fab>
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        sx={{ '& .MuiDrawer-paper': { width: isMobile ? '100%' : 300, background: theme.palette.background.default } }}
                    >
                        {drawerContent}
                    </Drawer>
                </>
            ) : (
                <>
                    <Box sx={{ borderLeft: 7, borderLeftColor: theme.palette.primary.main, padding: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>Localização</Typography>
                    </Box>
                    {loja === null ? (
                        <LoadingSkelMap />
                    ) : (
                        <MapGoogle center={lojaLatLng} Markers={[{ latitude: lojaLatLng.lat, longitude: lojaLatLng.lng }]} />
                    )}
                    {renderFilterContent()}
                </>
            )}
        </Box>
    );
}
export function LoadingSkelMap() {
    return (
        <Box sx={{ padding: 2 }}>
            <Skeleton variant="text" width={120} height={30} />
            <Box sx={{ marginTop: 2 }}>
                <Skeleton variant="rectangular" width="100%" height={50} />
                <Skeleton variant="rectangular" width="100%" height={200} sx={{ marginTop: 1 }} />
            </Box>
        </Box>
    );
}
export function LoadingSkelFilters() {
    return (
        <Box sx={{ padding: 2 }}>
            <Skeleton variant="text" width={120} height={30} />
            <Box sx={{ marginTop: 2 }}>
                <Skeleton variant="text" width={100} height={20} />
                <Skeleton variant="rectangular" width="100%" height={40} sx={{ marginTop: 1 }} />
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <Skeleton variant="text" width={100} height={20} />
                <Skeleton variant="rectangular" width="100%" height={40} sx={{ marginTop: 1 }} />
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <Skeleton variant="text" width={100} height={20} />
                <Skeleton variant="rectangular" width="100%" height={40} sx={{ marginTop: 1 }} />
            </Box>
        </Box>
    );
}
export const titleToParam = (title) => {
    const titleMap = {
        grupos: 'grupo_nome',
        grupo: 'grupo_nome',
        categorias: 'categ_nome',
        categoria: 'categ_nome',
        deptos: 'depto_nome',
        depto: 'depto_nome',
    };
    return titleMap[title] || 'nome';
};
export const titleToId = (title) => {
    const idMap = {
        grupos: 'grupo_id',
        grupo: 'grupo_id',
        categorias: 'categ_id',
        categoria: 'categ_id',
        deptos: 'depto_id',
        depto: 'depto_id',
    };
    return idMap[title] || 'id';
};
const nameToNome = (name) => {
    const nameMap = {
        grupos: 'Grupo',
        categorias: 'Categoria',
        deptos: 'Departamento',
        fabricantes: 'Fabricante',
        marcas: 'Marca',
        princ_ativos: 'Princípio Ativo',
    };
    return nameMap[name] || 'Outros';
};
const nameToParams = {
    grupos: 'grupo',
    categorias: 'categoria',
    deptos: 'depto',
    nome: 'nome',
    marcas: 'marca',
    fabricantes: 'fabricante',
    princ_ativos: 'princ_ativo',
};
