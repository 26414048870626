import { ThemeProvider } from "@mui/material";
import { Router } from "./routes/routes";
import { useMode } from "./theme/theme";
import ClienteContext from './contexts/cliente'
import CarrinhoContext from "./contexts/carrinho";
import FavoritosContext from "./contexts/favoritos";
import LojaContext from "./contexts/loja";
import { CepContext } from "./contexts/cep";
function App() {
  const [theme, colorMode] = useMode()
  return (
    <div className="content" style={{ display: 'flex', flex: 1 }}>
      <LojaContext>
        <ClienteContext>
          <CarrinhoContext>
            <FavoritosContext>
              <CepContext>
                <ThemeProvider theme={theme}>
                  <Router />
                </ThemeProvider>
              </CepContext>
            </FavoritosContext>
          </CarrinhoContext>
        </ClienteContext>
      </LojaContext>
    </div>
  );
}

export default App;
