import { Box } from '@mui/material';
import {
    APIProvider,
    Map,
    Marker
} from '@vis.gl/react-google-maps';
import { useMemo } from 'react';

export function MapGoogle({ Markers, center }) {
    return useMemo(() => (
        <Box sx={{ height: '100%' }}>
            <APIProvider apiKey={process.env.REACT_APP_API_GOOGLE_MAPS}>
                <Map
                    key={JSON.stringify(center)}
                    defaultCenter={center}
                    defaultZoom={12}
                    gestureHandling={'greedy'}
                    style={{
                        height: '300px',
                        width: '100%',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    }}
                >
                    {Array.isArray(Markers) && Markers.map((e, idx) => {
                        if (!e?.latitude || !e?.longitude) return null;
                        return (
                            <Marker key={idx} position={{ lat: e.latitude, lng: e.longitude }} />
                        );
                    })}
                </Map>
            </APIProvider>
        </Box>
    ), [Markers, center]);
}
