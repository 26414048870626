import { Alert, Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Cliente } from "../../../contexts/cliente";
import { AuthTextField } from "../../../components/textFields";
import { celularMask, cpfMask, maskGender } from "../../../components/masks";
import moment from "moment";
import { TestaCELULAR, TestaCPF, TestaDataNascimento, TestaFullName, TestaSenha } from "../../../components/validaCampo";



export function MeusDados({ scrollToTop }) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { data: clienteData, editCliente } = useContext(Cliente)
    const dataInit = {
        nome: clienteData?.nome,
        cpf: cpfMask(clienteData?.cpf),
        email: clienteData?.email,
        telefone: celularMask(clienteData?.telefone),
        dat_nascimento: clienteData?.dat_nascimento,
        genero: clienteData?.genero,
        dat_cadastro: moment(clienteData?.dat_cadastro).format('DD/MM/YYYY HH:mm:ss'),
        dat_atualizado: moment(clienteData?.dat_atualizado).format('DD/MM/YYYY HH:mm:ss'),
        senha: '',
    }
    const [data, setData] = useState(dataInit)
    const [message, setMessage] = useState(null)
    const [campErros, setCampErros] = useState([])
    const isDisable = ['email', 'dat_cadastro', 'dat_atualizado']
    const isShort = ['dat_nascimento', 'genero', 'dat_cadastro', 'dat_atualizado']
    const isDate = ['dat_nascimento']
    const isSenha = ['senha', 'confirmSenha']
    const masks = {
        cpf: cpfMask,
        telefone: celularMask,
        genero: maskGender
    }
    function changeText(value, prop, mask) {
        const newData = { ...data }
        let newValue = value
        if (mask) {
            newValue = mask(value)
        }
        newData[prop] = newValue
        setData(newData)
    }
    const isDataChanged = JSON.stringify(data) !== JSON.stringify(dataInit);
    async function saveData() {
        const errors = testaCampos()
        if (errors) {
            setMessage({
                severity: 'error',
                message: 'Ajuste os campos para prosseguir'
            })
            return
        }
        const tryEdit = await editCliente(data)
        if (tryEdit !== true) {
            scrollToTop()
            setMessage({
                severity: 'error',
                message: tryEdit
            })
        } else {
            scrollToTop()
            setMessage({
                severity: 'success',
                message: 'Informações editadas com sucesso.'
            })
        }
    }
    function testaCampos() {
        const newErrors = validaCamposVazios();
        let hasErrors = newErrors.length > 0 ? true : false;
        const handleErrors = (campo, isValid, errorMessage) => {
            const findIndex = newErrors.findIndex((e) => e.campo === campo);
            if (!isValid) {
                hasErrors = true;
                if (findIndex === -1) {
                    newErrors.push({ campo, err: errorMessage });
                } else {
                    newErrors[findIndex] = { campo, err: errorMessage };
                }
            } else if (findIndex !== -1) {
                newErrors.splice(findIndex, 1); // Remove o erro se o campo for válido
            }
        };
        if (data.cpf) handleErrors('cpf', TestaCPF(data.cpf), 'CPF inválido preenchido');
        if (data.nome) handleErrors('nome', TestaFullName(data.nome), 'Nome Completo inválido preenchido');
        if (data.telefone) handleErrors('telefone', TestaCELULAR(data.telefone), 'Telefone inválido preenchido.');
        if (data.dat_nascimento) handleErrors('dataNascimento', TestaDataNascimento(data.dat_nascimento), 'Você precisa ter mais de 18 anos para criar uma conta.');
        setCampErros(newErrors);
        return hasErrors
    }
    function validaCamposVazios() {
        let newErrors = [...campErros]
        Object.keys(data).map((e) => {
            const findIndex = newErrors.findIndex((b) => b.campo === e);
            if (!data[e]) {
                const objErr = {
                    campo: e,
                    err: 'Campo precisa ser preenchido'
                }
                if (findIndex === -1) {
                    newErrors.push(objErr)
                }
                else {
                    newErrors[findIndex] = objErr
                }
            }
            else {
                newErrors = newErrors.filter((b) => b.campo !== e)
            }
        })
        return newErrors
    }
    useEffect(() => {
        setData({
            nome: clienteData?.nome,
            cpf: cpfMask(clienteData?.cpf),
            email: clienteData?.email,
            telefone: celularMask(clienteData?.telefone),
            dat_nascimento: clienteData?.dat_nascimento,
            genero: clienteData?.genero,
            dat_cadastro: moment(clienteData?.dat_cadastro).format('DD/MM/YYYY HH:mm:ss'),
            dat_atualizado: moment(clienteData?.dat_atualizado).format('DD/MM/YYYY HH:mm:ss'),
            senha: '',
        })
    }, [clienteData])
    return (
        <Box sx={{ width: '100%', padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {message && (
                <Alert severity={message?.severity}>{message.message}</Alert>
            )}
            <Typography variant="h5" gutterBottom>Meus Dados</Typography>
            <Box sx={{ width: isMobile ? '90%' : '50%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: 2 }}>
                {Object.keys(data).map((e, idx) => (
                    <Box key={idx} sx={{ width: isMobile ? '100%' : isShort.includes(e) ? '45%' : '100%' }}>
                        <Typography>{nameToNome[e]}</Typography>
                        <AuthTextField errors={campErros} changeText={changeText} prop={e} type={isDate.includes(e) ? 'date' : isSenha.includes(e) && 'password'} value={data[e]} disabled={isDisable.includes(e)} fullWidth mask={masks.hasOwnProperty(e) && masks[e]} />
                    </Box>
                ))}
                <Button onClick={saveData} disabled={!isDataChanged} variant='contained' color="secondary" size='large' fullWidth sx={{ textTransform: 'none', fontWeight: 'bold' }}>Salvar</Button>
            </Box>
        </Box>
    )
}

const nameToNome = {
    nome: 'Nome',
    cpf: 'CPF',
    telefone: 'Telefone',
    dat_nascimento: 'Data de nascimento',
    genero: 'Gênero(M/F)',
    email: 'E-mail',
    senha: 'Para prosseguir, informe sua senha.',
    dat_cadastro: 'Data de cadastro',
    dat_atualizado: 'Data de atualização'
}