import { useTheme, Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getIcon } from "./icons";

export function ModalInfo({ visible, title, subtitle, canDesactive, hideModal, icon, funcS }) {
    const theme = useTheme();
    return (
        <Modal
            open={visible}
            onClose={() => {
                if (canDesactive && hideModal) {
                    hideModal();
                }
            }}
            sx={{
                display: 'flex',
                borderWidth: 'none',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                    }
                }
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                width: '22%',
                height: '30%',
                minHeight: 300,
                minWidth: 350,
                background: theme.palette.background.default,
                outline: 'none',
                borderRadius: 3,
                position: 'relative',  // Adiciona esta linha
                [theme.breakpoints.down('md')]: {
                    width: '50%',
                    height: '30%',
                },
                [theme.breakpoints.down('lg')]: {
                    width: '40%',
                    height: '30%',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '70%',
                    height: '30%',
                },
            }}>
                {canDesactive &&
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: theme.palette.text.primary
                        }}
                        onClick={hideModal}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
                    <Typography sx={{ color: theme.palette.text.primary, textAlign: 'center', fontWeight: 'bold', fontSize: 35 }}>{title}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, textAlign: 'center', fontSize: 20 }}>{subtitle}</Typography>
                </Box>
                {icons(icon, theme, funcS, hideModal)}
            </Box>
        </Modal>
    )
}

const icons = (icon, theme, funcS, dismiss) => {
    const x = {
        exclamation: getIcon(24, theme.palette.text.primary, 70),
        loading: getIcon(25, theme.palette.text.primary, 70),
        sucess: getIcon(23, theme.palette.text.primary, 70),
        question: <Box sx={{ display: 'flex', gap: 2 }}>
            <Button type='button' onClick={dismiss} sx={{ background: theme.palette.primary.main, color: theme.palette.text.onPrimary }}>Não</Button>
            <Button onClick={funcS} sx={{ background: theme.palette.primary.main, color: theme.palette.text.onPrimary }}>Sim</Button>
        </Box>
    };
    if (!x.hasOwnProperty(icon)) return null;
    return x[icon];
}
