import { Box, useTheme } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

export function BoxHover({ children, width, newTheme, padding, margin, notShadow }) {
    const baseTheme = useTheme()
    const theme = newTheme ? newTheme : baseTheme
    const boxStyle = {
        width: width ? width : '250px',
        backgroundColor: theme.palette.background.default,
        padding: padding ? padding : '20px',
        margin: margin ? margin : '15px',
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        boxShadow: notShadow ? 'none' : theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease-in-out',
        position: 'relative',
        overflow: 'hidden'

    };
    return (
        <Box sx={boxStyle}>
            {children}
        </Box>
    )
}