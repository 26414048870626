import { Box, Checkbox, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { getIcon } from "../../../components/icons";

export function Buscar({ value, changeText, onClick, searchWithFilters, updateSWFilters, ativeClass }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onClick();
        }
    };
    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', gap: isSmallScreen ? 1 : 0 }}>
            <TextField
                placeholder="O que está procurando?"
                size="small"
                value={value}
                onChange={(e) => changeText(e.target.value)}
                onKeyDown={handleKeyDown}
                sx={{
                    width: '100%',
                    minWidth: 300,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.divider,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.divider,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.divider,
                        },
                    }
                }}
                InputProps={{
                    endAdornment: ativeClass ? (
                        <InputAdornment position="end">
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: isSmallScreen ? 'column' : 'row',
                                    whiteSpace: 'nowrap', // Evitar quebra de linha em telas pequenas
                                }}
                            >
                                <Checkbox
                                    checked={searchWithFilters}
                                    onChange={updateSWFilters}
                                    color="primary"
                                    size="small"
                                    sx={{
                                        padding: 0,
                                        marginRight: isSmallScreen ? 0 : theme.spacing(1),
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: isSmallScreen ? '0.75rem' : '1rem',
                                        maxWidth: isSmallScreen ? '130px' : '180px', // Ajuste para telas pequenas
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textAlign: isSmallScreen ? 'center' : 'left',
                                    }}
                                >
                                    Pesquisar em {ativeClass}?
                                </Typography>
                            </Box>
                        </InputAdornment>
                    ) : null,
                    style: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    }
                }}
            />
            <IconButton size={isSmallScreen ? 'small' : 'medium'} onClick={onClick} sx={{ borderRadius: 0, background: theme.palette.primary.main, borderTopRightRadius: isSmallScreen ? 0 : 3, borderBottomRightRadius: isSmallScreen ? 0 : 3, width: isSmallScreen ? '100%' : 'auto' }}>
                {getIcon(53, theme.palette.text.onPrimary)}
            </IconButton>
        </Box>
    );
}
