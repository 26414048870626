export const cpfMask = value => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
export function cnpjMask(cnpj) {
    cnpj = cnpj.replace(/\D/g, ""); // Remove qualquer caractere não numérico
    if (cnpj.length === 14) {
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
    return cnpj; // Retorna o CNPJ sem formatação se não tiver 14 dígitos
}

export const celularMask = value => {
    return value.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
}
export const telefoneMask = value => {
    if (!value || value.length !== 10) {
        return "Número inválido";
    }

    const ddd = value.slice(0, 2);
    const prefixo = value.slice(2, 6);
    const sufixo = value.slice(6, 10);

    return `(${ddd}) ${prefixo}-${sufixo}`;
}
export const telefoneRegex = value => {
    return value.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
}
export const cepMask = value => {
    return value.replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')
}

export const onlyString = value => {
    return value.replace(/[^a-zA-Z]+/g, '')
}
export const onlyStringEEspaco = value => {
    return value.replace(/[^a-zA-Z ]+/g, '')
}
export const onlyInteger = value => {
    value = typeof value === 'number' ? value.toString() : value
    return value.replace(/\D/g, "")
}
export const realMask = value => {
    if (!value) {
        return '00,00';
    }
    value = typeof value !== 'string' ? value.toString() : value
    let newText = value.replace(/(\D)/g, '').replace(/^0+/, '');

    /* if (newText.length < 4) {
        for (let i = newText.length; i < 4; i++) {
            newText = '0' + newText;
        }
    } */
    newText = newText
        .replace(/(\d{2}$)/g, ',$&')
        .replace(/(\d{1})(\d{3})([,])/, '$1$2$3')
        .replace(/(\d{1})(\d{3})([.])/, '$1$2$3')
        .replace(/(\d{1})(\d{3})([.])/, '$1$2$3');
    return newText;
}
export const numberToReal = value => {
    if (!value) return '0,00'
    value = typeof value === 'number' ? value.toFixed(2) : parseFloat(value).toFixed(2)
    value = typeof value !== 'string' ? value.toString() : value
    return value.replace('.', ',')
}
export function removerCaracteresEspeciais(str) {
    const mapaAcentos = {
        'Á': 'A', 'á': 'a',
        'É': 'E', 'é': 'e',
        'Í': 'I', 'í': 'i',
        'Ó': 'O', 'ó': 'o',
        'Ú': 'U', 'ú': 'u',
        'Â': 'A', 'â': 'a',
        'Ê': 'E', 'ê': 'e',
        'Î': 'I', 'î': 'i',
        'Ô': 'O', 'ô': 'o',
        'Û': 'U', 'û': 'u',
        'Ã': 'A', 'ã': 'a',
        'Õ': 'O', 'õ': 'o',
        'À': 'A', 'à': 'a',
        'È': 'E', 'è': 'e',
        'Ì': 'I', 'ì': 'i',
        'Ò': 'O', 'ò': 'o',
        'Ù': 'U', 'ù': 'u',
        'Ä': 'A', 'ä': 'a',
        'Ë': 'E', 'ë': 'e',
        'Ï': 'I', 'ï': 'i',
        'Ö': 'O', 'ö': 'o',
        'Ü': 'U', 'ü': 'u',
        'Ç': 'C', 'ç': 'c',
        'Ñ': 'N', 'ñ': 'n',
        'Ý': 'Y', 'ý': 'y',
        '[': ' ', ']': ' ',
        '{': ' ', '}': ' ',
        '`': ' ', '´': ' ',
        "'": ' ', '"': ' ',
        '~': ' ', '^': ' ',
        ';': ' ', '/': ' ',
        '\\': ' ', '|': ' ',
        '&': ' ', '%': ' ',
        '$': ' ', '#': ' ',
        '@': ' ', '!': ' ',
        '?': ' ', '(': ' ',
        ')': ' ', '=': ' ',
        '+': ' ', '*': ' ',
        ',': ' ', '.': ' ',
        '<': ' ', '>': ' ',
        ':': ' ', '-': ' ',
        '_': ' ', '§': ' ',
        '¬': ' ', '¨': ' ',
        ' ': ' ', '\n': ' ',
        '\r': ' ', '\t': ' ',
        '\v': ' ', '\f': ' '
    };
    // Usa uma expressão regular para remover caracteres especiais
    return str.replace(/[^\w\s]/gi, function (match) {
        return mapaAcentos[match] || match;
    });
}
export function removeEspacos(str) {
    return str.replace(/\s+/g, '');
}
export function corrigirNome(nome) {
    if (!nome) return nome
    return nome
        .toLowerCase()
        .split(' ')
        .map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1))
        .join(' ');
}
export function maskGender(input) {
    const value = input.toUpperCase();
    if (value === 'M' || value === 'F') {
        return value;
    } else {
        return ''; // Retorna uma string vazia se não for 'M' ou 'F'
    }
}

export function calcDesconto(vlrVenda, vlrFinal) {
    vlrVenda = parseFloat(vlrVenda)
    vlrFinal = parseFloat(vlrFinal)
    const desconto = ((vlrFinal - vlrVenda) / vlrVenda) * 100
    return Math.abs(desconto.toFixed(2))
}