import { Alert, Box, Button, Divider, Icon, Tooltip, Typography, useTheme } from "@mui/material";
import { getIcon } from "../../../components/icons";
import { calcDesconto, numberToReal } from "../../../components/masks";
import { CounterField } from "../../../components/counterField";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Cep } from "../../../contexts/cep";
import { FBFADM } from "../../../apis/FBFAdm";
import { Carrinho } from "../../../contexts/carrinho";
import { ModalLoja } from "../../../components/modalLoja";



const modalLojaInit = {
    loja: null,
    visible: false
}
export function ProdutoPrice({ bestPrice, allPrices, priceRef, produto }) {
    const { data: cepData, updateCep, updateDrawerOpen } = useContext(Cep)
    const { data: carrinhoData, add, handleCart } = useContext(Carrinho)
    const theme = useTheme()
    const [modalLojaVisible, setModalLojaVisible] = useState(modalLojaInit)
    const [prevPrice, setPrevPrice] = useState(bestPrice)
    const { qtd_estoque, vlr_venda, vlr_final, nom_fantasia, cod_barra, userId } = prevPrice
    const [noFrete, setNoFrete] = useState(false)
    const [typePrice, setTypePrice] = useState(1)
    const [data, setData] = useState(null)
    const { title, info } = typesPrices(typePrice)
    const [quantidade, setQuantidade] = useState(1)
    const styleLojaInfo = {
        fontSize: 12
    }
    const AllLojas = useMemo(() =>
        allPrices.map(item => ({
            userId: item.userId,
            vlr_final: item.vlr_final,
            cepOrigem: item.cep,
        })), [allPrices]
    );
    async function getPrices() {
        const get = await FBFADM('marketplace/public/getBestFrete', 'POST', { lojas: AllLojas, produto: cod_barra, cepDestino: cepData.cep })
        const apiData = get?.apiReturn?.apiReturn
        if (apiData) {
            setData(apiData)
            let validaExistFrete = []
            apiData?.allFretes.map((e) => {
                if (!Array.isArray(e?.fretes) || e?.fretes?.length === 0) {
                    validaExistFrete.push(false)
                } else {
                    validaExistFrete.push(true)
                }
            })
            if (!validaExistFrete.includes(true)) {
                setNoFrete(true)
            }
            if (apiData?.maisBarato?.loja === bestPrice?.userId) {
                setTypePrice(2)
            }
        } else {
            setNoFrete(true)
            setData(false)
        }
    }
    const fretesBestPrice = Array.isArray(data?.allFretes) && data.allFretes.find((e) => e.loja === prevPrice.userId)
    const bestFreteBestPrice = fretesBestPrice && fretesBestPrice.fretes.reduce((red, item) => {
        red.valor = typeof red.valor === 'string' ? red.valor.replace(',', '.') : red.valor
        item.valor = typeof item.valor === 'string' ? item.valor.replace(',', '.') : item.valor
        return parseFloat(item.valor) < parseFloat(red.valor) ? item : red
    }, fretesBestPrice.fretes[0])
    const bestPriceFretes = useMemo(() => {
        if (!data || noFrete) return
        const get = formatPricesAndFretes(bestPrice?.userId, data.allFretes, allPrices)
        return get
    }, [data])

    function changeSelectedPrice(type, loja) {
        const getNewPrice = getAllPriceLoja(allPrices, loja)
        setPrevPrice(getNewPrice)
        setTypePrice(type)
    }
    function handleChangeQuantidade(type) {
        if (type === 1) {
            if (quantidade - 1 === 0) return
            setQuantidade((quantidade) => quantidade - 1)
        } else {
            if (quantidade + 1 > prevPrice?.qtd_estoque) return
            setQuantidade((quantidade) => quantidade + 1)
        }
    }
    function ComprarProduto() {
        const newData = {
            cod_barra: produto?.cod_barra,
            image: produto?.image,
            loja: prevPrice?.loja,
            loja_avatar: prevPrice?.loja_avatar,
            loja_nome: prevPrice?.loja_nome,
            nome: produto?.produto_nome,
            quantidade: quantidade,
            table: prevPrice?.table,
            vlr_final: prevPrice?.vlr_final,
            vlr_venda: prevPrice?.vlr_venda,
            qtd: prevPrice?.qtd,
            qtdPreco: prevPrice?.qtdPreco,

        }
        const find = carrinhoData.findIndex((e) => e.cod_barra == newData.cod_barra && e.loja == newData.loja)
        if (find === -1) {
            add(newData)
        }
        handleCart()
    }
    useEffect(() => {
        if (data?.maisBarato?.loja === bestPrice?.userId) {
            setTypePrice(2)
        }
    }, [bestPrice])
    useEffect(() => {
        if (data === null && cepData?.cep) {
            getPrices()
        }
    }, [data, cepData])
    useEffect(() => {
        setPrevPrice(bestPrice)
        setData(null)
    }, [produto])
    console.log(data?.allFretes)
    return (
        <Box ref={priceRef} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: 2, borderColor: theme.palette.divider, padding: 1, borderRadius: 3 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Icon sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: theme.palette.warning.main, height: 30, width: 30, borderRadius: 15 }}>
                        {getIcon(77, theme.palette.text.onPrimary, 15)}
                    </Icon>
                    <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
                    <Tooltip title={info} placement='right'>
                        {getIcon(104, theme.palette.text.disabled, 20)}
                    </Tooltip>
                </Box>
                <Divider />
                <Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                            <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary, textDecoration: 'line-through', }}>R$</Typography>
                            <Typography sx={{ fontSize: 18, color: theme.palette.text.secondary, textDecoration: 'line-through', }}>{numberToReal(vlr_venda)}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                            <Typography sx={{ fontSize: 20 }}>R$</Typography>
                            <Typography sx={{ fontSize: 30, fontWeight: 'bold', color: theme.palette.primary.main }}>{numberToReal(vlr_final)}</Typography>
                        </Box>
                    </Box>
                </Box>
                {noFrete ? (
                    <Box sx={{ background: 'rgba(25,85,166, 0.1)', padding: 2, display: 'flex', flexDirection: 'column', gap: 2, borderRadius: 5 }}>
                        <Typography sx={{ fontSize: 14 }}>Não existem formas de entrega para sua região. Tente novamente abaixo</Typography>
                        <Button fullWidth variant='contained' color="secondary" sx={{ textTransform: 'none' }} onClick={updateDrawerOpen}>Informar CEP</Button>
                    </Box>
                ) : !cepData ? (
                    <Box sx={{ background: 'rgba(25,85,166, 0.1)', padding: 2, display: 'flex', flexDirection: 'column', gap: 2, borderRadius: 5 }}>
                        <Typography sx={{ fontSize: 14 }}>Informe seu CEP para ver se o produto está disponível na sua região.</Typography>
                        <Button fullWidth variant='contained' color="secondary" sx={{ textTransform: 'none' }} onClick={updateDrawerOpen}>Informar CEP</Button>
                    </Box>
                ) : data === null ? (
                    <Alert severity='info' icon={getIcon(25, theme.palette.secondary.main, 12, null, true)}>Carregando...</Alert>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {prevPrice?.qtd_estoque == 0 ? (
                            <Alert severity="info">O Estoque do produto acabou.</Alert>
                        ) : fretesBestPrice ? (
                            <>
                                <Box>
                                    <CounterField quantidade={quantidade} handleDecrease={() => handleChangeQuantidade(1)} handleIncrease={() => handleChangeQuantidade(2)} />
                                    <Typography sx={{ fontSize: 12, paddingLeft: 2 }}>{`${qtd_estoque}un em estoque`}</Typography>
                                </Box>
                                <Box>
                                    <Button fullWidth variant='contained' color="secondary" onClick={ComprarProduto}>Comprar</Button>
                                </Box>
                            </>
                        ) : <Alert severity="info">Não disponível para sua região</Alert>}
                        <Divider />
                        <Box>
                            <Button sx={{ textTransform: 'none' }} onClick={() => setModalLojaVisible({ visible: true, loja: userId })}>
                                <Typography sx={[styleLojaInfo, { color: theme.palette.text.primary }]}>Vendido e entrega por <strong style={{ color: theme.palette.tertiary.main }}>{nom_fantasia}</strong></Typography>
                            </Button>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={styleLojaInfo}>Preço de venda</Typography>
                                <Typography sx={styleLojaInfo}>R${numberToReal(vlr_venda)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={styleLojaInfo}>Desconto</Typography>
                                <Typography sx={styleLojaInfo}>{calcDesconto(vlr_venda, vlr_final)}%</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={styleLojaInfo}>Preço Unitário</Typography>
                                <Typography sx={styleLojaInfo}>R${vlr_final}</Typography>
                            </Box>
                        </Box>
                        {fretesBestPrice && (
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Fretes</Typography>
                                {fretesBestPrice.fretes.map((e) => (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={styleLojaInfo}>{e?.tipo_nome}</Typography>
                                        <Typography sx={styleLojaInfo}>{'R$' + e?.valor}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                        {bestFreteBestPrice && (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Preço Final</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                    <Typography sx={{ fontSize: 20 }}>R$</Typography>
                                    <Typography sx={{ fontSize: 25, fontWeight: 'bold', color: theme.palette.primary.main }}>{numberToReal(parseFloat(vlr_final) + parseFloat(bestFreteBestPrice.valor))}</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            {data?.allFretes && !noFrete && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {data.allFretes?.length > 1 && bestPrice?.userId !== data?.maisBarato?.loja && (
                        <Typography sx={{ color: theme.palette.secondary.dark, fontSize: 22, fontWeight: 'bold' }}>Trocar para</Typography>
                    )}
                    {bestPrice?.userId !== data?.maisBarato?.loja && typePrice !== 1 && (
                        <Button fullWidth sx={{ textTransform: 'none', textAlign: 'left', justifyContent: 'flex-start', color: theme.palette.text.primary, gap: 2 }} onClick={() => changeSelectedPrice(1, bestPrice.userId)}>
                            <Icon sx={{ background: theme.palette.primary.main, height: 40, width: 40, borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {getIcon(100, theme.palette.text.onPrimary, 20)}
                            </Icon>
                            <Box sx={{ width: '100%' }}>
                                <Typography sx={{ fontWeight: 'bold', }}>Melhor Oferta</Typography>
                                <Typography sx={{ fontSize: 12 }}>Melhor Preço de venda para o produto</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={styleLojaInfo}>Preço de venda</Typography>
                                    <Typography sx={styleLojaInfo}>R${numberToReal(bestPrice?.vlr_final)}</Typography>
                                </Box>
                                {!bestPriceFretes ? (
                                    <Alert severity="info">Não disponível para sua região</Alert>
                                ) :
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography sx={styleLojaInfo}>Melhor Frete</Typography>
                                            <Typography sx={styleLojaInfo}>R${bestPriceFretes?.getBestFrete?.valor}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Venda Final</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                                <Typography sx={{ fontSize: 20 }}>R$</Typography>
                                                <Typography sx={{ fontSize: 25, fontWeight: 'bold', color: theme.palette.primary.main }}>{numberToReal(bestPriceFretes?.vlrMaisFrete)}</Typography>
                                            </Box>
                                        </Box>
                                    </>}
                            </Box>
                        </Button>
                    )}
                    {typePrice !== 2 && (
                        <Button fullWidth sx={{ textTransform: 'none', textAlign: 'left', justifyContent: 'flex-start', color: theme.palette.text.primary, gap: 2 }} onClick={() => changeSelectedPrice(2, data?.maisBarato?.loja)}>
                            <Icon sx={{ background: theme.palette.primary.main, height: 40, width: 40, borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {getIcon(50, theme.palette.text.onPrimary, 20)}
                            </Icon>
                            <Box sx={{ width: '100%' }}>
                                <Typography sx={{ fontWeight: 'bold', }}>Melhor Oferta C/ Frete</Typography>
                                <Typography sx={{ fontSize: 12 }}>Melhor preço com frete incluso</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={styleLojaInfo}>Preço de venda</Typography>
                                    <Typography sx={styleLojaInfo}>R${numberToReal(data?.maisBarato?.vlr_final)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={styleLojaInfo}>Melhor Frete</Typography>
                                    <Typography sx={styleLojaInfo}>R${data?.maisBarato?.frete?.valor}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Venda Final</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                        <Typography sx={{ fontSize: 20 }}>R$</Typography>
                                        <Typography sx={{ fontSize: 25, fontWeight: 'bold', color: theme.palette.primary.main }}>{numberToReal(parseFloat(data?.maisBarato?.vlr_final) + parseFloat(data?.maisBarato?.frete?.valor))}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Button>
                    )}
                </Box >
            )
            }
            <ModalLoja visible={modalLojaVisible.visible} loja={modalLojaVisible.loja} dismiss={() => setModalLojaVisible(modalLojaInit)} />
        </Box >
    )
}
function getAllPriceLoja(allPrices, loja) {
    const filterPrices = allPrices.filter((e) => e.userId === loja)
    return filterPrices[0]
}


function formatPricesAndFretes(loja, fretes, allPrices) {
    const filterFrete = fretes.filter((e) => e.loja === loja)
    if (filterFrete.length === 0) return false
    const frete = filterFrete[0].fretes
    const filterPrice = allPrices.filter((e) => e.userId === loja)
    const price = filterPrice[0]
    const getBestFrete = frete.reduce((red, item) => {
        const redValor = parseFloat(red.valor)
        const itemValor = parseFloat(item.valor)
        if (redValor > itemValor) return item
        return red
    }, frete[0])
    const vlrMaisFrete = (parseFloat(price.vlr_final) + parseFloat(getBestFrete.valor)).toFixed(2)
    return { getBestFrete, vlrMaisFrete, price }
}
const typesPrices = (typePrice) => {
    const prices = {
        1: {
            title: 'Melhor Oferta',
            info: 'Melhor preço de venda.'
        },
        2: {
            title: 'Melhor Oferta c/ Frete',
            info: 'Melhor preço com frete incluso.'
        },
    }
    return prices[typePrice]
}