import { Avatar, Box, Button, Link, Skeleton, Typography, useTheme } from "@mui/material";
import { cepMask, cnpjMask, telefoneMask } from "../../../components/masks";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Buscar } from "./buscar";
import { ModalLoja } from "../../../components/modalLoja";

export function Header({ loja, changeActiveMenu, activeMenu, changeNome, searchWithFilters, updateSWFilters, ativeClass, lojaId }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [buscarText, setBuscarText] = useState('')
    const [modalLoja, setModalLoja] = useState(false)

    if (loja === null) return <SkeletonHeader />;
    console.log(loja)

    const titleStyle = {
        color: theme.palette.text.onPrimary,
    };

    function handleClick() {
        changeNome(buscarText)
    }

    function handleSarch() {
        updateSWFilters()
    }

    const getButtonStyle = (module) => ({
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        fontWeight: activeMenu === module ? 'bold' : 'normal',
        textTransform: 'none',
        padding: '6px 12px',
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.primary.contrastText,
        },
        borderBottom: activeMenu === module ? `3px solid ${theme.palette.tertiary.main}` : '3px solid transparent',
        transition: 'border-bottom 0.3s ease',
    });

    return (
        <Box>
            <Box sx={{ display: 'flex', position: 'relative', height: 300, overflow: 'hidden' }}>
                <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <img src={loja.banner_loja} alt="background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'center' : 'center', gap: 2, padding: isMobile ? 2 : 3, width: isMobile ? '100%' : 'auto', maxWidth: isMobile ? '100%' : 500, background: 'rgba(0,0,0,0.5)', height: '100%', zIndex: 1 }}>
                    <Avatar src={loja?.avatar} alt={loja.nom_fantasia} sx={{ width: 100, height: 100, border: `3px solid ${theme.palette.primary.main}` }} />
                    <Box sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                        <Typography sx={titleStyle} variant="h6">{loja.nom_fantasia}</Typography>
                        <Typography sx={titleStyle} variant="body2">{loja.rua}, {loja.numero} - {loja.bairro}</Typography>
                        <Typography sx={titleStyle} variant="body2">{loja.cidade_nome} - {loja.estado_uf}, {cepMask(loja.cep)}</Typography>
                        <Typography sx={titleStyle} variant="body2">CNPJ: {cnpjMask(loja.cnpj)}</Typography>
                        <Typography sx={titleStyle} variant="body2">Email: <Link href={`mailto:${loja.email}`} sx={{ color: theme.palette.text.onPrimary }}>{loja.email}</Link></Typography>
                        <Typography sx={titleStyle} variant="body2">Telefone: {telefoneMask(loja.telefone)}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', padding: isMobile ? '8px 16px' : '8px 16px', borderBottom: 1, borderLeft: 1, borderRight: 1, borderColor: theme.palette.divider }}>
                <Box sx={{ display: 'flex', gap: 1, order: isMobile ? 2 : 1, width: isMobile ? '100%' : 'auto', justifyContent: isMobile ? 'space-around' : 'flex-start' }}>
                    <Button onClick={() => changeActiveMenu('products')} sx={getButtonStyle('products')} fullWidth={isMobile}>
                        Produtos
                    </Button>
                    <Button onClick={() => setModalLoja(true)} sx={getButtonStyle('info')} fullWidth={isMobile}>
                        Informações
                    </Button>
                    <Button onClick={() => changeActiveMenu('about')} sx={getButtonStyle('about')} fullWidth={isMobile}>
                        Sobre
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end', width: isMobile ? '100%' : 'auto', order: isMobile ? 1 : 2 }}>
                    <Buscar ativeClass={ativeClass} value={buscarText} changeText={(value) => setBuscarText(value)} onClick={handleClick} searchWithFilters={searchWithFilters} updateSWFilters={handleSarch} />
                </Box>
            </Box>
            <ModalLoja loja={lojaId} visible={modalLoja} dismiss={() => setModalLoja(false)} />
        </Box>
    );
}

function SkeletonHeader() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, background: 'rgba(0,0,0,0.6)', height: 300 }}>
            <Box display="flex" alignItems="center" mb={2}>
                <Skeleton variant="circular" width={100} height={100} sx={{ mr: 2 }} />
                <Box width="100%">
                    <Skeleton variant="text" width="60%" height={30} />
                    <Skeleton variant="text" width="40%" height={25} />
                    <Skeleton variant="text" width="50%" height={25} />
                </Box>
            </Box>
            <Skeleton variant="text" width="50%" height={25} />
            <Skeleton variant="text" width="80%" height={25} />
            <Skeleton variant="text" width="70%" height={25} />
            <Skeleton variant="text" width="60%" height={25} />
        </Box>
    );
}
