import { useContext, useRef, useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../components/header';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Inicio } from '../views/inicio/inicio';
import { Buscar } from '../views/buscar/buscar';
import { Login } from '../views/auth/login';
import { Register } from '../views/auth/register';
import { ErrorPage } from '../views/other/errorPage';
import { MinhaConta } from '../views/minha-conta/minhaConta';
import { Cliente } from '../contexts/cliente';
import { RedefinirSenha } from '../views/auth/redefinir-senha';
import { scroller } from 'react-scroll';
import { Loja } from '../views/loja/loja';
import { CarrinhoDrawer } from '../components/carrinho';
import { FinalizarCompra } from '../views/finalizar-compra/finalizarCompra';
import { Footer } from '../components/footer';
import { FAV } from '../views/favoritos/favoritos';
import { ContaLoja } from '../views/conta-loja/contaLoja';
import { AuthLoja } from '../views/conta-loja/auth';
import { Loja as LojaContext } from '../contexts/loja';
import { CepDrawer } from '../components/cepDrawer';
import { Produto } from '../views/produto copy/produto';

export function Router() {
    const theme = useTheme();
    const mainRef = useRef();
    const cliente = useContext(Cliente);
    const loja = useContext(LojaContext)
    const [cartOpen, setCartOpen] = useState(false)
    const isUser = cliente?.data?.token ? true : false;
    const isLoja = loja?.data?.token
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    const prevPathnameRef = useRef(location.pathname);
    function scrollToTop(id) {
        const whatId = id ? id : 'routes';
        if (mainRef.current) {
            scroller.scrollTo(whatId, {
                containerId: 'mainContainer',
                duration: 700,
                delay: 0,
                smooth: 'easeInOutQuad'
            });
        }
    }
    useEffect(() => {
        if (location.pathname !== prevPathnameRef.current) {
            scrollToTop();
        }
        prevPathnameRef.current = location.pathname;
    }, [location.pathname]);
    return (
        <Box id="mainContainer" ref={mainRef} sx={{
            display: 'flex', flexGrow: 1, height: '100%', width: '100%', flex: 1, background: theme.palette.background.default,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { background: theme.palette.background.paper },
            '&::-webkit-scrollbar-thumb': { background: theme.palette.divider },
        }}>
            <Box name={'routes'} sx={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
                <Header />
                <Box sx={{ display: 'flex', flexGrow: 1, marginTop: isSmallScreen ? 25 : 20 }}>
                    <Routes>
                        {routerData(scrollToTop, mainRef).map((e, idx) => (
                            <Route key={idx} path={e.path} element={e.element} />
                        ))}
                        {!isUser ? routerNoUser(scrollToTop).map((e, idx) => (
                            <Route key={idx} path={e.path} element={e.element} />
                        )) :
                            routerIsUser(scrollToTop).map((e, idx) => (
                                <Route key={idx} path={e.path} element={e.element} />
                            ))
                        }
                        {isLoja ? routerIsLoja(scrollToTop).map((e, idx) => (
                            <Route key={idx} path={e.path} element={e.element} />
                        )) :
                            routerNoLoja(scrollToTop).map((e, idx) => (
                                <Route key={idx} path={e.path} element={e.element} />
                            ))
                        }
                    </Routes>
                </Box>
                <Box sx={{ marginTop: isSmallScreen ? 10 : 10 }} />
                <Footer />
                <CarrinhoDrawer />
                <CepDrawer />
            </Box>
        </Box>
    )
}

const routerData = (scrollToTop, mainRef) => {
    return [
        { path: '*', element: <Navigate to='/' /> },
        { path: '/', element: <Inicio /> },
        { path: '/produto', element: <Produto scrollToTop={scrollToTop} mainRef={mainRef} /> },
        { path: '/errorPage', element: <ErrorPage /> },
        { path: '/buscar', element: <Buscar scrollToTop={scrollToTop} /> },
        { path: '/favoritos', element: <FAV scrollToTop={scrollToTop} /> },
        { path: '/loja', element: <Loja scrollToTop={scrollToTop} /> },
        { path: '/redefinir-senha', element: <RedefinirSenha /> }
    ]
}

const routerIsLoja = (scrollToTop) => {
    return [
        { path: 'conta-loja', element: <ContaLoja scrollToTop={scrollToTop} /> }
    ]
}
const routerNoLoja = () => {
    return [
        { path: 'conta-loja', element: <AuthLoja /> }
    ]
}

const routerNoUser = () => {
    return [
        { path: '/minha-conta', element: <Navigate to='/entrar' /> },
        { path: '/finalizar-compra', element: <Navigate to='/entrar' /> },
        { path: '/entrar', element: <Login /> },
        { path: '/registrar', element: <Register /> },
    ]
}
const routerIsUser = (scrollToTop) => {
    return [
        { path: '/minha-conta', element: <MinhaConta scrollToTop={scrollToTop} /> },
        { path: '/entrar', element: <Navigate to={'/minha-conta'} /> },
        { path: '/registrar', element: <Navigate to={'/minha-conta'} /> },
        { path: '/finalizar-compra', element: <FinalizarCompra to={'/minha-conta'} scrollToTop={scrollToTop} /> }
    ]
}
