import React from "react";
import { useTheme, Box, Typography, IconButton, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import { getIcon } from "../../../../../components/icons";

export function DetailsSection({ title, keys, data, updateNfeInfo, updatePostagemInfo }) {
    const theme = useTheme()
    const sectionData = keys.reduce((acc, { key, label, mask }) => {
        if (data[key] !== undefined && data[key] !== '') {
            acc[label] = mask ? mask(data[key]) : data[key];
        }
        return acc;
    }, {});

    const dataKeys = Object.keys(sectionData);
    // Função para abrir o XML em uma nova janela
    function downloadXml(xmlContent) {
        // Cria um blob com o conteúdo do XML
        const blob = new Blob([xmlContent], { type: "application/xml" });
        const url = URL.createObjectURL(blob);

        // Cria um link temporário e simula o clique para iniciar o download
        const a = document.createElement("a");
        a.href = url;
        a.download = "nota_fiscal.xml"; // Nome do arquivo de download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Libera a URL criada
        URL.revokeObjectURL(url);
    }

    return (
        <Box mb={3}>
            {dataKeys.length > 0 && (
                <React.Fragment>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{`${title}`}</Typography>
                        {title === 'NFe' && data?.nfe_num ?
                            <IconButton sx={{ padding: 1 }} onClick={() => updateNfeInfo(true, data)}>
                                {
                                    getIcon(27, theme.palette.secondary.main, 15)
                                }
                            </IconButton> : title === 'Postagem' && data?.prePostagem ?
                                <IconButton sx={{ padding: 1 }} onClick={() => updatePostagemInfo(true, data)}>
                                    {
                                        getIcon(27, theme.palette.secondary.main, 15)
                                    }
                                </IconButton> : null
                        }
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                {dataKeys.map(label => {
                                    return (
                                        <TableRow key={label}>
                                            <TableCell component="th" scope="row">{label.replace(/_/g, ' ')}</TableCell>
                                            {label === 'Xml da nota fiscal' && sectionData[label] ?
                                                <IconButton onClick={() => downloadXml(sectionData[label])} sx={{ padding: 0, marginLeft: 2 }}>
                                                    {
                                                        getIcon(78)
                                                    }
                                                </IconButton>
                                                : <TableCell>{sectionData[label]}</TableCell>}

                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            )}
        </Box>
    );
}