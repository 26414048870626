import moment from 'moment'
export function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    strCPF = strCPF.replace('.', '').replace('.', '')
    strCPF = strCPF.replace('-', '')
    if (strCPF == "00000000000") return false;
    let i = 1
    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export function TestaCNPJ(strCNPJ) {
    strCNPJ = strCNPJ.replace(/[^\d]+/g, '');

    if (strCNPJ.length !== 14) return false;

    // Elimina CNPJs inválidos conhecidos
    if (/^(\d)\1{13}$/.test(strCNPJ)) return false;

    let tamanho = strCNPJ.length - 2;
    let numeros = strCNPJ.substring(0, tamanho);
    let digitos = strCNPJ.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) return false;

    return true;
}

export const TestaEMAIL = (email) => {
    const valida = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))
    return valida
}

export const TestaCELULAR = (celular) => {
    var expressao = /\(\d{2,}\) \d{4,}\-\d{4}/g;
    var regex = new RegExp(expressao);
    const newCelular = celular.replace(' ', '')
    return regex.test(celular);
}

export const TestaFullName = (fullName) => {
    var regName = /^[a-zA-Zà-úÀ-Ú]+( [a-zA-Zà-úÀ-Ú]+)+$/;
    if (!regName.test(fullName)) {
        return false
    } else {
        return true
    }
}
export const TestaDataNascimento = (data, format) => {
    const parsedDate = moment(data, format, true); // O terceiro parâmetro (true) ativa o modo estrito

    // Verificar se a data é válida
    if (!parsedDate.isValid()) {
        return false;
    }

    // Verificar se o usuário tem mais de 18 anos
    const idadeMinima = 18;
    const hoje = moment();
    const idadeUsuario = hoje.diff(parsedDate, 'years');

    return idadeUsuario >= idadeMinima;
}

export const TestaSenha = (senha) => {
    if (!senha) return false
    if (senha.length < 8) return false
    return true
}

export const TestaConfirmSenha = (senha, confirmSenha) => {
    if (senha !== confirmSenha) return false
    return true
}
export function ValidaCEP(cep) {
    // Remove qualquer caractere que não seja número
    const cepLimpo = cep.replace(/\D/g, '');

    // Verifica se o CEP tem 8 dígitos
    if (cepLimpo.length !== 8) {
        return false;
    }

    // Regex para validar o formato de CEP
    const cepRegex = /^[0-9]{5}-?[0-9]{3}$/;

    // Testa o CEP contra o regex
    return cepRegex.test(cep);
}

export function containsNumber(str) {
    return /\d/.test(str);
}
export function containsCaracter(str) {
    return /\D/.test(str);
}