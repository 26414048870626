import { Box, Skeleton } from '@mui/material'

export function LoadingProduto() {
    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%', gap: 2 }}>
            <Box sx={{ width: '40%' }}>
                <Skeleton variant="rectangular" width={'100%'} height={450} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%', gap: 2 }}>
                <Skeleton variant="text" width={'60%'} height={40} />
                <Skeleton variant="text" width={'40%'} height={40} />
                <Skeleton variant="rectangular" width={'30%'} height={40} />
                <Skeleton variant="text" width={'100%'} height={20} />
                <Skeleton variant="text" width={'100%'} height={20} />
                <Skeleton variant="text" width={'100%'} height={20} />
                <Skeleton variant="text" width={'100%'} height={20} />
                <Skeleton variant="rectangular" width={'50%'} height={50} />
                <Skeleton variant="text" width={'50%'} height={30} />
            </Box>
        </Box>
    );
}