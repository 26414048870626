import { Alert, Box, CircularProgress, Modal, Paper, Typography, useTheme, Grid, Avatar, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FBFADM } from "../../../apis/FBFAdm";
import { Loja } from "../../../contexts/loja";
import { celularMask, cepMask, cpfMask, numberToReal } from "../../../components/masks";
import moment from "moment";
import { TableProdutos } from "./components/pedido/tableProdutos";
import { ProcessoPedido } from "./components/pedido/processoPedido";
import { DetailsSection } from "./components/pedido/DetailsSection";

export function Pedido({ visible, pedidoId, dismiss }) {
    const theme = useTheme();
    const { data: lojaData } = useContext(Loja);
    const [data, setData] = useState(null);
    async function getPedido() {
        const get = await FBFADM(`marketplace/loja/getPedido/${pedidoId}`, 'GET', null, lojaData?.token);
        if (get?.status !== 200) {
            setData(false);
            return;
        }
        setData(get?.apiReturn?.apiReturn);
    }
    function updateStatus(status) {
        const newD = { ...data }
        newD.pedido.status = status
        setData(newD)
    }
    function handleDismiss() {
        dismiss();
    }
    useEffect(() => {
        if (!pedidoId) {
            setData(null);
            return;
        }
        getPedido();
    }, [pedidoId]);

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper sx={{ width: 1200, maxWidth: 1200, maxHeight: '90vh', p: 4, outline: 'none', background: theme.palette.background.paper }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, color: theme.palette.secondary.dark }}>
                    {`Pedido #${pedidoId}`}
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Box sx={{
                    paddingRight: 4, display: 'flex', flexDirection: 'column', marginBottom: 2, overflowY: 'auto', height: '70vh', '&::-webkit-scrollbar': { background: theme.palette.background.paper },
                    '&::-webkit-scrollbar-thumb': { background: theme.palette.divider },
                }}>
                    {data === null ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                            <CircularProgress color="secondary" />
                        </Box>
                    ) : data === false ? (
                        <Alert severity="error">Ocorreu um erro ao carregar os dados.</Alert>
                    ) : (
                        <Box>
                            <ProcessoPedido currentStatus={data?.pedido?.status} pedido={pedidoId} updateStatus={updateStatus} entregaTipo={data?.pedido.frete_tipo} />
                            <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                            <DetailsSection data={data?.pedido} title="Informações da Venda" keys={[
                                { key: 'id', label: 'ID do Pedido' },
                                { key: 'venda', label: 'ID da Venda' },
                                { key: 'dataPedido', label: 'Data do pedido', mask: (value) => moment(value).format('HH:mm DD/MM/YYYY') },
                                { key: 'dataAtualizado', label: 'Data da última atualização', mask: (value) => moment(value).format('HH:mm DD/MM/YYYY') },
                            ]} />
                            <DetailsSection data={data?.pedido} title="Informações do cliente" keys={[
                                { key: 'cliente_cpf', label: 'CPF', mask: cpfMask },
                                { key: 'cliente_tel', label: 'Celular', mask: celularMask },
                                { key: 'cliente_nascimento', label: 'Data de Nascimento', mask: (value) => moment(value).format('DD/MM/YYYY') },
                                { key: 'cliente_genero', label: 'Gênero' },
                                { key: 'cliente_email', label: 'E-mail' },
                                { key: 'cliente_cadastro', label: 'Data de cadastro', mask: (value) => moment(value).format('HH:mm DD/MM/YYYY') },

                            ]} />
                            <DetailsSection data={data?.pedido} title="Informações de entrega" keys={[
                                { key: 'cod_rastreio', label: 'Código de rastreio' },
                                { key: 'frete_nome', label: 'Tipo de Entrega' },
                                { key: 'frete_valor', label: 'Valor do frete' },
                                { key: 'entrega_cep', label: 'CEP', mask: cepMask },
                                { key: 'entrega_rua', label: 'Rua' },
                                { key: 'entrega_numero', label: 'Número' },
                                { key: 'entrega_bairro', label: 'Bairro' },
                                { key: 'entrega_cidade', label: 'Cidade' },
                                { key: 'entrega_estado', label: 'Estado' },
                                { key: 'entrega_complemento', label: 'Complemento' },

                            ]} />
                            {/* nf.numeroFiscal as nf_numeroFiscal, nf.modelo as nf_modelo, nf.chave as nf_chave, nf.xml as nf_xml */}
                            <DetailsSection data={data?.pedido} title="Informações da Nota Fiscal" keys={[
                                { key: 'nf_numeroFiscal', label: 'N° da nota' },
                                { key: 'nf_modelo', label: 'Modelo da Nota Fiscal' },
                                { key: 'nf_chave', label: 'Chave da Nota Fiscal' },
                                { key: 'nf_xml', label: 'Xml da nota fiscal' },

                            ]} />
                            <DetailsSection data={data?.pedido} title="Valores totais" keys={[
                                { key: 'vlr_frete', label: 'Valor do Frete', mask: numberToReal },
                                { key: 'vlr_venda', label: 'Valor de venda', mask: numberToReal },
                                { key: 'vlr_final', label: 'Valor com desconto', mask: numberToReal },
                                { key: 'vlr_total', label: 'Valor total', mask: numberToReal },

                            ]} />
                            <TableProdutos data={data?.produtos} />
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button type='button' variant='contained' color='secondary' size='large' sx={{ width: 100, textTransform: 'none', background: theme.palette.secondary.dark }} onClick={handleDismiss}>Voltar</Button>
                </Box>
            </Paper>
        </Modal>
    );
}
/* 
 "qtd_itens": 3,
        "vlr_frete": 0,
        "vlr_venda": 171.32,
        "vlr_final": 148.99,
        "vlr_total": 148.99,
*/