import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Boleto from '../../../inc/assets/icons/boleto.png'
import Cartao from '../../../inc/assets/icons/cartao.png'
import PIX from '../../../inc/assets/icons/pix.png'
import { getIcon } from "../../../components/icons";



export function FormasPagamento({ changeCadEndereco }) {
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>Métodos de pagamento</Typography>
                <Typography sx={{}}>Selecione uma forma de pagamento e preencha os dados necessários para finalizar</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button onClick={() => changeCadEndereco(2)} sx={{ textTransform: 'none', color: theme.palette.secondary.main }}>
                        {getIcon(96, theme.palette.secondary.main)}
                        Voltar ao carrinho
                    </Button>
                </Box>
            </Box>
            <Box sx={{ border: 1, borderRadius: 2, borderColor: theme.palette.divider, display: 'flex', flexDirection: 'column' }}>
                <Title title={'Boleto bancário.'} subtitle={'Confirmação em até 2 dias úteis.'} image={Boleto} />
            </Box>
            <Box sx={{ border: 1, borderRadius: 2, borderColor: theme.palette.divider, display: 'flex', flexDirection: 'column' }}>
                <Title title={'Cartão de crédito'} subtitle={'Parcele em até 12x'} image={Cartao} />
            </Box>
            <Box sx={{ border: 1, borderRadius: 2, borderColor: theme.palette.divider, display: 'flex', flexDirection: 'column' }}>
                <Title title={'PIX'} subtitle={'Aprovação imediata'} image={PIX} />
            </Box>
        </Box>
    )
}

function Title({ title, subtitle, image }) {
    const theme = useTheme()
    return (
        <Button sx={{ display: 'block', textAlign: 'left', textTransform: 'none', color: theme.palette.text.primary, padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <IconButton sx={{ width: 50, height: 50 }} >
                        <img src={image} style={{ objectFit: 'contain', width: 25 }} />
                    </IconButton>
                    <Box>
                        <Typography sx={{ fontSize: 20, color: theme.palette.text.primary }}>{title}</Typography>
                        <Typography sx={{ color: theme.palette.primary.main }}>{subtitle}</Typography>
                    </Box>
                </Box>
                {getIcon(2, theme.palette.text.secondary)}
            </Box>
        </Button>
    )
}