import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { useContext, useState } from "react";
import { getIcon } from "../../components/icons";
import { celularMask, cpfMask } from "../../components/masks";
import { Link } from "react-router-dom";
import { TestaCELULAR, TestaCPF, TestaDataNascimento, TestaEMAIL, TestaFullName, TestaSenha, TestaConfirmSenha } from "../../components/validaCampo";
import { ModalInfo } from "../../components/modalInfo";
import { FBFADM } from "../../apis/FBFAdm";
import { Cliente } from "../../contexts/cliente";
import { AuthTextField } from '../../components/textFields'



export function Register() {
    const theme = useTheme()
    const cliente = useContext(Cliente)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'))
    const [data, setData] = useState(dataInit)
    const [campErros, setCampErros] = useState([])
    const [concordaPol, setConcordaPol] = useState(false)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    const titleStyle = {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000'
    }
    const subtitleStyle = {
        fontWeight: 'bold',
        color: '#000'
    }
    async function saveUser() {
        const camposValidos = testaCampos()
        if (camposValidos) return
        if (!concordaPol) {
            updateModalInfo(true, true, 'Atenção', 'Você precisa concordar com a política de privacidade para continuar.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Salvando', 'Estamos criando sua conta', 'loading')
        const save = await FBFADM('marketplace/public/registerCliente', 'POST', data)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao criar sua conta.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        cliente.updateCliente(save?.apiReturn?.apiReturn)
        updateModalInfo()
    }
    function testaCampos() {
        const newErrors = validaCamposVazios();
        let hasErrors = newErrors.length > 0 ? true : false;
        const handleErrors = (campo, isValid, errorMessage) => {
            const findIndex = newErrors.findIndex((e) => e.campo === campo);
            if (!isValid) {
                hasErrors = true;
                if (findIndex === -1) {
                    newErrors.push({ campo, err: errorMessage });
                } else {
                    newErrors[findIndex] = { campo, err: errorMessage };
                }
            } else if (findIndex !== -1) {
                newErrors.splice(findIndex, 1); // Remove o erro se o campo for válido
            }
        };
        if (data.cpf) handleErrors('cpf', TestaCPF(data.cpf), 'CPF inválido preenchido');
        if (data.nomeCompleto) handleErrors('nomeCompleto', TestaFullName(data.nomeCompleto), 'Nome Completo inválido preenchido');
        if (data.email) handleErrors('email', TestaEMAIL(data.email), 'E-mail inválido preenchido.');
        if (data.telefone) handleErrors('telefone', TestaCELULAR(data.telefone), 'Telefone inválido preenchido.');
        if (data.dataNascimento) handleErrors('dataNascimento', TestaDataNascimento(data.dataNascimento), 'Você precisa ter mais de 18 anos para criar uma conta.');
        if (data.senha) handleErrors('senha', TestaSenha(data.senha), 'Senha precisa ter no mínimo 8 caracteres')
        if (data.confirmSenha) handleErrors('confirmSenha', TestaConfirmSenha(data.senha, data.confirmSenha), 'As duas senhas precisam ser iguais')
        setCampErros(newErrors);
        return hasErrors
    }
    function validaCamposVazios() {
        let newErrors = [...campErros]
        Object.keys(data).map((e) => {
            const findIndex = newErrors.findIndex((b) => b.campo === e);
            if (!data[e]) {
                const objErr = {
                    campo: e,
                    err: 'Campo precisa ser preenchido'
                }
                if (findIndex === -1) {
                    newErrors.push(objErr)
                }
                else {
                    newErrors[findIndex] = objErr
                }
            }
            else {
                newErrors = newErrors.filter((b) => b.campo !== e)
            }
        })
        return newErrors
    }

    function changeText(value, prop, mask) {
        const newData = { ...data }
        let newValue = value
        if (mask) {
            newValue = mask(value)
        }
        newData[prop] = newValue
        setData(newData)
    }
    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: isMobile ? '100%' : '80%', gap: 5 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                        <img src={theme.palette.cartao} style={{ objectFit: 'contain', width: 100 }} alt="Cartão fidelidade" />
                        <Box>
                            <Typography sx={{ fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>Novo Cadastro</Typography>
                            <Typography sx={{}}>Faça seu cadastro, pontue no fidelidade, concorra a prêmios e compre os melhores produtos.</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', flex: 1, width: '100%', gap: isMobile ? 3 : 10 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', gap: 5 }}>
                            <Typography sx={titleStyle}>Dados Pessoais</Typography>
                            <Box>
                                <Typography sx={subtitleStyle}>Nome Completo</Typography>
                                <AuthTextField errors={campErros} fullWidth placeHolder={'Ex: João da Silva'} prop={'nomeCompleto'} value={data.nomeCompleto} changeText={changeText} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', gap: 3, width: '100%' }}>
                                <Box>
                                    <Typography sx={subtitleStyle}>CPF</Typography>
                                    <AuthTextField errors={campErros} icon={getIcon(33)} fullWidth placeHolder={'000.000.000-00'} prop={'cpf'} value={data.cpf} mask={cpfMask} changeText={changeText} />
                                </Box>
                                <Box>
                                    <Typography sx={subtitleStyle}>Celular</Typography>
                                    <AuthTextField errors={campErros} icon={getIcon(91)} fullWidth placeHolder={'(00) 00000-0000'} prop={'telefone'} value={data.telefone} mask={celularMask} changeText={changeText} />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', gap: 3, width: '100%' }}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography sx={subtitleStyle}>Data de nascimento</Typography>
                                    <AuthTextField errors={campErros} type={'date'} fullWidth changeText={changeText} value={data.dataNascimento} prop={'dataNascimento'} />
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <Typography sx={subtitleStyle}>Gênero</Typography>
                                    <RadioGroup>
                                        {['Masculino', 'Feminino'].map((e, idx) => {
                                            const value = idx === 0 ? 'M' : 'F'
                                            return (
                                                <FormControlLabel key={idx} value={value} control={<Radio checked={data.genero === value} onChange={(value) => changeText(value.target.value, 'genero')} />} label={e} />
                                            )
                                        })}
                                    </RadioGroup>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', gap: 5 }}>
                            <Typography sx={titleStyle}>Dados de acesso</Typography>
                            <Box>
                                <Typography sx={subtitleStyle}>E-mail</Typography>
                                <AuthTextField errors={campErros} icon={getIcon(92)} fullWidth placeHolder={'exemplo@exemplo.com.br'} prop={'email'} value={data.email} changeText={changeText} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 3, width: '100%' }}>
                                <Box>
                                    <Typography sx={subtitleStyle}>Senha</Typography>
                                    <AuthTextField type='password' errors={campErros} icon={getIcon(93)} fullWidth placeHolder={'Digite uma senha'} prop={'senha'} value={data.senha} changeText={changeText} />
                                </Box>
                                <Box>
                                    <Typography sx={subtitleStyle}>Confirmar Senha</Typography>
                                    <AuthTextField type='password' errors={campErros} icon={getIcon(93)} fullWidth placeHolder={'Repita a senha'} prop={'confirmSenha'} value={data.confirmSenha} changeText={changeText} />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>Veja nossa</Typography>
                                    <Button sx={{ fontWeight: 'bold', textTransform: 'none', color: theme.palette.secondary.main }} href="https://privacidade.fbf.far.br/f/" target="_blank">
                                        <Typography>Política de Privacidade.</Typography>
                                    </Button>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Radio checked={concordaPol} onClick={() => setConcordaPol(!concordaPol)} />
                                    <Typography>Estou de acordo com a política de privacidade.</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Button sx={{ background: theme.palette.primary.main, color: theme.palette.text.onPrimary, fontWeight: 'bold', padding: 2, '&:hover': { background: theme.palette.secondary.main } }} fullWidth onClick={saveUser}>
                                    Cadastrar
                                </Button>
                                <Button component={Link} to='/entrar' sx={{ background: theme.palette.background.paper, color: theme.palette.text.secondary, fontWeight: 'bold', padding: 2, '&:hover': { background: theme.palette.secondary.main, color: theme.palette.text.onPrimary } }} fullWidth>
                                    Já tenho uma conta
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Container>
    )
}

const dataInit = {
    nomeCompleto: '',
    cpf: '',
    telefone: '',
    dataNascimento: '',
    genero: 'M',
    email: '',
    senha: '',
    confirmSenha: ''
}