import { Fragment, useContext, useEffect, useState } from "react";
import { Cliente } from "../../../contexts/cliente";
import { Alert, Box, Card, Typography, useTheme, CardContent, Divider, Avatar, Accordion, AccordionSummary, AccordionDetails, Chip, Grid, Pagination, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, useMediaQuery, Collapse } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { FBFADM } from "../../../apis/FBFAdm";
import { getIcon } from "../../../components/icons";
import { ModalLoja } from "../../../components/modalLoja";
import moment from "moment";
import { BoxHover } from "../../../components/boxHover";
import { numberToReal } from "../../../components/masks";

export function Compras() {
    const theme = useTheme();
    const { data: clienteData } = useContext(Cliente);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [data, setData] = useState(null);
    const [pedidosCol, setPedidosCol] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3);
    const modLojaInit = {
        visible: false,
        loja: null
    }
    const [modLoja, setModLoja] = useState(modLojaInit)
    async function getData() {
        const get = await FBFADM('marketplace/private/getPedidosCliente', 'GET', null, clienteData?.token);
        const apiData = get?.apiReturn?.apiReturn;
        console.log(apiData)
        if (apiData) setData(apiData);
        else setData(false);
    }
    useEffect(() => {
        getData();
    }, []);
    const agruparPorLoja = (produtos, fretes) => {
        const lojas = {};
        produtos.forEach(produto => {
            if (!lojas[produto.loja]) {
                lojas[produto.loja] = {
                    produtos: [],
                    frete: fretes.find(f => f.loja === produto.loja)
                };
            }
            lojas[produto.loja].produtos.push(produto);
        });
        return lojas;
    };
    function handleColPed(idx) {
        if (idx === pedidosCol) {
            setPedidosCol(null)
        } else {
            setPedidosCol(idx)
        }
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = Array.isArray(data) ? data?.slice(indexOfFirstItem, indexOfLastItem) : []
    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };
    return (
        <Box sx={{ width: '100%', padding: isMobile ? 0 : 2 }}>
            <Typography variant="h5" gutterBottom>Histórico de Compras</Typography>
            <Box sx={{ width: '100%' }}>
                {data === null ? (
                    <Alert icon={getIcon(25, theme.palette.secondary.dark, 20, null, true)} severity='info'>
                        {`Carregando compras`}
                    </Alert>
                ) : data === false || !Array.isArray(data) ? (
                    <Alert severity='error'>{`Ocorreu um erro ao buscar suas compras`}</Alert>
                ) : data.length === 0 ? (
                    <Alert severity='info'>{`Você ainda não possui pedidos.`}</Alert>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <BoxHover width={'auto'}>
                            {
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Pedidos</TableCell>
                                                <TableCell>Endereço</TableCell>
                                                <TableCell>Venda</TableCell>
                                                <TableCell>Valor Produtos</TableCell>
                                                <TableCell>Valor Frete</TableCell>
                                                <TableCell>Valor Total</TableCell>
                                                <TableCell>Data Pedido</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {currentData.map((e, idx) => (
                                                <Fragment key={idx}>
                                                    <TableRow>
                                                        <TableCell onClick={() => handleColPed(idx)}>{getIcon(77, theme.palette.secondary.main)}</TableCell>
                                                        <TableCell>{getIcon(50, theme.palette.error.main)}</TableCell>
                                                        <TableCell>{e?.venda?.venda}</TableCell>
                                                        <TableCell>R${numberToReal(e?.venda?.vlr_final)}</TableCell>
                                                        <TableCell>R${numberToReal(e?.venda?.vlr_frete)}</TableCell>
                                                        <TableCell>R${numberToReal(e?.venda?.vlr_total)}</TableCell>
                                                        <TableCell>{moment(e?.venda?.dataPedido).format('HH:mm DD/MM/YYYY')}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Collapse>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow></TableRow>
                                                                    </TableHead>
                                                                </Table>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </BoxHover>
                        <Pagination
                            count={Math.ceil(data.length / itemsPerPage)} // Total de páginas
                            page={currentPage}
                            onChange={handleChangePage}
                            color="primary"
                            sx={{ alignSelf: 'center', marginTop: 2 }}
                        />
                    </Box>
                )}
            </Box>
            <ModalLoja loja={modLoja.loja} visible={modLoja.visible} dismiss={() => setModLoja(modLojaInit)} />
        </Box>
    );
}
