import { Box, IconButton, TextField, InputAdornment, useTheme } from "@mui/material";
import { getIcon } from "./icons";

export function CounterField({ quantidade, handleIncrease, handleDecrease }) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
            <TextField
                value={quantidade}
                inputProps={{
                    style: {
                        textAlign: 'center',
                        padding: '5px 5px',
                        width: '40px',
                        color: '#000',
                        fontWeight: 'bold'
                    },
                    readOnly: true
                }}
                size='medium'
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton size="small" onClick={handleDecrease} edge="start">
                                {getIcon(89, theme.palette.text.secondary, 15)}
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton size="small" onClick={handleIncrease} edge="end">
                                {getIcon(55, theme.palette.secondary.main, 15)}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{ maxWidth: '120px' }}
            />
        </Box>
    );
}
