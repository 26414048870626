import { Alert, Box, Drawer, IconButton, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { Cep } from "../contexts/cep";
import CloseIcon from '@mui/icons-material/Close';
import { AuthTextField } from "./textFields";
import { getIcon } from "./icons";
import { cepMask } from "./masks";
import { FBFADM } from "../apis/FBFAdm";
import { ValidaCEP } from "./validaCampo";



export function CepDrawer() {
    const theme = useTheme()
    const { isDrawerOpen, updateDrawerOpen, updateCep, updateData } = useContext(Cep)
    const [text, setText] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    function handleChangeText(value) {
        value = cepMask(value)
        setText(value)
        if (ValidaCEP(value)) {
            getCep(value)
        }
    }
    async function getCep(value) {
        setLoading(true)
        const get = await FBFADM('marketplace/public/getCep', 'POST', { cep: value })
        setLoading(false)
        const apiData = get?.apiReturn?.apiReturn
        if (get?.status !== 200 || !apiData?.estado) {
            setError('Ocorreu um erro ao consultar o seu cep')
            return
        }
        setError('')
        updateData(apiData)
        updateDrawerOpen()
    }
    return (
        <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={updateDrawerOpen}
            PaperProps={{ sx: { background: theme.palette.background.default, width: { xs: '100%', sm: '430px' }, borderRadius: '12px', boxShadow: theme.shadows[5] } }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>Defina sua localização</Typography>
                <IconButton onClick={updateDrawerOpen} color="inherit">
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ padding: 2 }}>
                <Typography>Preços, ofertas e disponibilidade podem variar de acordo com a sua localização</Typography>
            </Box>
            <Box sx={{ padding: 2 }}>
                <Typography sx={{ fontWeight: 'bold' }}>Informe seu CEP</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AuthTextField value={text} changeText={(value) => handleChangeText(value)} placeHolder={'89665-000'} size={'small'} icon={getIcon(53)} />
                    <IconButton color="secondary" sx={{ background: theme.palette.secondary.main, borderRadius: 2, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                        {getIcon(53, theme.palette.text.onPrimary)}
                    </IconButton>
                </Box>
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
            {loading && <Alert severity="info" icon={getIcon(25, theme.palette.secondary.light, 16, false, true)}>{`Buscando Cep...`}</Alert>}
        </Drawer>
    )
}