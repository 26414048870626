import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import { useContext, useRef, useState } from "react";
import { AuthTextField } from "../../components/textFields";
import { TestaCPF, TestaEMAIL, TestaSenha } from "../../components/validaCampo";
import { cpfMask, onlyInteger } from "../../components/masks";
import { ModalInfo } from "../../components/modalInfo";
import { FBFADM } from "../../apis/FBFAdm";
import { Link } from "react-router-dom";
import { getIcon } from "../../components/icons";
import { Cliente } from "../../contexts/cliente";



export function RedefinirSenha({ }) {
    const theme = useTheme()
    const [swiper, setSwiper] = useState(null);
    const { data: clientData, updateCliente } = useContext(Cliente)
    const [activeIndex, setActiveIndex] = useState(0)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [finalizado, setFinalizado] = useState(false)
    const [data, setData] = useState({
        usuario: '',
        cod: '',
        senha: '',
        confirmSenha: '',
        codEnviado: false
    })
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function changeData(value, type, mask) {
        const newA = { ...data }
        if (type === 'usuario') {
            value = TestaCPF(value) ? cpfMask(value) : value
        }
        newA[type] = mask ? mask(value) : value
        setData(newA)
    }

    async function validaStep1() {
        if (data.codEnviado) {
            swiper.slideTo(activeIndex + 1)
            return
        }
        if (!data.usuario) {
            updateModalInfo(true, true, 'Atenção', 'O campo E-mail/CPF não pode ser vazio.', 'exclamation')
            return
        }
        if (!TestaCPF(data.usuario) && !TestaEMAIL(data.usuario)) {
            updateModalInfo(true, true, 'Atenção', 'O usuário informado não é valido.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Carregando', 'Validando dados.', 'loading')
        const save = await FBFADM('marketplace/public/redefinirSenha', 'POST', { step: 1, usuario: data.usuario })
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao validar o cliente.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo()
        changeData(true, 'codEnviado')
        swiper.slideTo(activeIndex + 1)
    }

    async function validaStep2() {
        if (!data.cod) {
            updateModalInfo(true, true, 'Atenção', 'O campo código não pode ser vazio.', 'exclamation')
            return
        }
        if (!data.senha) {
            updateModalInfo(true, true, 'Atenção', 'O campo senha não pode ser vazio.', 'exclamation')
            return
        }
        if (!TestaSenha(data.senha)) {
            updateModalInfo(true, true, 'Atenção', 'Senha precisa ter no mínimo 8 caracteres.', 'exclamation')
            return
        }
        if (!data.confirmSenha) {
            updateModalInfo(true, true, 'Atenção', 'O campo de confirmação da senha não pode ser vazio.', 'exclamation')
            return
        }
        if (data.senha !== data.confirmSenha) {
            updateModalInfo(true, true, 'Atenção', 'As senhas não são iguais', 'exclamation')
        }
        updateModalInfo(true, false, 'Carregando', 'Validando dados.', 'loading')
        const save = await FBFADM('marketplace/public/redefinirSenha', 'POST', { step: 2, usuario: data.usuario, cod: data.cod, senha: data.senha })
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao validar o cliente.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        if (clientData?.token && typeof save?.apiReturn?.apiReturn === 'object') {
            updateCliente(save?.apiReturn?.apiReturn)
        }
        updateModalInfo()
        setFinalizado(true)
    }

    function handleClick(type) {
        if (type === 'Próximo') {
            if (activeIndex === 0) {
                validaStep1()
                return
            }
            if (activeIndex === 1) {
                validaStep2()
                return
            }
        }
        swiper.slideTo(activeIndex - 1)
    }

    return (
        <Container>
            <Box id={'redefinir-senha'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {finalizado ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '30%' }}>
                        <Typography sx={{ fontSize: 35, fontWeight: 'bold', color: theme.palette.secondary.main, textAlign: 'center' }}>Sucesso</Typography>
                        <Typography sx={{ fontSize: 18, color: '#000', textAlign: 'center' }}>Sua senha foi redefinida. Clique no botão abaixo e volte para a tela de login.</Typography>
                        <Button component={Link} to={'/entrar'} sx={{ background: theme.palette.primary.main, color: theme.palette.text.onPrimary, fontWeight: 'bold', padding: 2, '&:hover': { background: theme.palette.tertiary.main, color: theme.palette.text.onPrimary } }} fullWidth>
                            Voltar
                        </Button>
                    </Box>
                    :
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, width: isMobile ? '100%' : '50%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                            <Typography sx={{ fontSize: 80, fontWeight: 'bold', color: theme.palette.secondary.main, textAlign: 'center' }}>Ops!</Typography>
                            <Box>
                                <Typography sx={{ fontSize: 22, fontWeight: 'bold', color: theme.palette.text.primary, textAlign: 'center' }}>Parece que esqueceu sua senha.</Typography>
                                <Typography sx={{ fontSize: 18, color: '#000', textAlign: 'center' }}>Não tem problema. É só seguir alguns passos que iremos redefini-la.</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ overflow: 'hidden', height: 'auto' }}>
                            <Swiper
                                onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                                onSwiper={setSwiper}
                                slidesPerView={1}
                                modules={[Pagination]}
                                pagination={{
                                    clickable: false,
                                }}
                                allowTouchMove={false}
                                style={{ width: '100%', overflow: 'visible' }}
                            >
                                <SwiperSlide>
                                    <Step1 changeData={changeData} usuario={data.usuario} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Step2 changeData={changeData} cod={data.cod} senha={data.senha} confirmSenha={data.confirmSenha} />
                                </SwiperSlide>
                            </Swiper>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 3 }}>
                            {['Voltar', 'Próximo'].map((e, idx) => {
                                if (activeIndex === 0 && idx === 0) return
                                const background = idx === 0 ? theme.palette.background.paper : theme.palette.primary.main
                                const color = idx === 0 ? theme.palette.text.secondary : theme.palette.text.onPrimary
                                return (
                                    <Button key={idx} onClick={() => handleClick(e)} sx={{ background: background, color: color, fontWeight: 'bold', padding: 2, '&:hover': { background: theme.palette.tertiary.main, color: theme.palette.text.onPrimary } }} fullWidth>
                                        {e}
                                    </Button>
                                )
                            })}
                        </Box>
                        <Typography component={Link} to={'/entrar'} sx={{ textAlign: 'right', textDecoration: 'none', color: theme.palette.text.primary }}>Lembrei minha senha.</Typography>
                    </Box>
                }
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Container>
    )
}

function Step1({ usuario, changeData }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'center' }}>Primeiro Passo</Typography>
                <Typography sx={{ textAlign: 'center' }}>Precisamos saber quem você é. Preencha com seu E-mail ou CPF para prosseguirmos com a redefinição.</Typography>
            </Box>
            <Box sx={{ width: '99%' }}>
                <Typography>E-mail/CPF</Typography>
                <AuthTextField icon={getIcon(33)} disableTab fullWidth value={usuario} changeText={changeData} prop={'usuario'} />
            </Box>
        </Box>
    )
}
function Step2({ cod, changeData, senha, confirmSenha }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'center' }}>Segundo Passo</Typography>
                <Typography>Um e-mail com o código de verificação foi enviado para você. Informe-o abaixo com sua nova senha</Typography>
            </Box>
            <Box sx={{ width: '99%' }}>
                <Typography>Código de verificação.</Typography>
                <AuthTextField disableTab fullWidth value={cod} changeText={changeData} prop={'cod'} mask={onlyInteger} />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, width: '99%' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography>Nova Senha</Typography>
                    <AuthTextField icon={getIcon(93)} disableTab fullWidth value={senha} changeText={changeData} prop={'senha'} type={'password'} />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Typography>Confirmar senha</Typography>
                    <AuthTextField icon={getIcon(93)} fullWidth value={confirmSenha} changeText={changeData} prop={'confirmSenha'} type={'password'} />
                </Box>
            </Box>
        </Box>
    )
}