import { Box, Button, Collapse, Divider, IconButton, Popover, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BoxHover } from "./boxHover";
import { useContext, useEffect, useMemo, useState } from "react";
import { getIcon } from "./icons";
import { corrigirNome, numberToReal } from "./masks";
import { Link } from "react-router-dom";
import { Carrinho } from "../contexts/carrinho";
import { Favoritos } from "../contexts/favoritos";



export function OneProduct({ produto, margin, width, loja, isTiniPrice }) {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState(null)
    function handleMouseEnter(value) {
        setAnchorEl(value.currentTarget)
    }
    function handleClose() {
        setAnchorEl(null)
    }
    const markaLink = loja ? `/loja?id=${loja}&marca=${produto.marca_id}` : `/buscar?marca=${produto.marca_id}`
    const produtoLink = loja ? `/produto?id=${produto.cod_barra}&loja=${loja}` : `/produto?id=${produto.cod_barra}`
    const oneLineText = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    }
    const tableInfo = produto?.qtd ? 'Super Combo' : produto?.table === 'prodVig' ? 'Super Oferta' : ''
    return (
        <Box sx={{ height: 480, overflowY: 'visible' }}>
            <BoxHover margin={margin && margin} width={width && width}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleClose}>
                    {useMemo(() => (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: width === 130 ? 350 : 350, width: '100%' }}>
                            <Box component={Link} to={produtoLink} sx={{ alignSelf: 'center', height: 120, width: 120 }}>
                                <img src={produto.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} alt={`produto - ${produto.cod_barra}`} />
                            </Box>
                            <Buttons produto={produto} />
                            <Box sx={{ height: 50, textDecoration: 'none', width: '100%' }} component={Link} to={`/produto?id=${produto?.cod_barra}`}>
                                <Typography sx={[isTiniPrice && oneLineText, { width: '100%', color: theme.palette.text.primary, textAlign: 'center', textDecoration: 'none', WebkitLineClamp: 2, }]}>{corrigirNome(produto.nome)}</Typography>
                            </Box>
                            {/* <Typography sx={{ color: theme.palette.primary.main, fontWeight: 'bold', textAlign: 'center' }}>{produto.cod_barra}</Typography> */}
                            <Typography component={Link} to={markaLink} sx={[isTiniPrice && oneLineText, { color: theme.palette.secondary.dark, textOverflow: 'ellipsis', fontWeight: 'bold', textAlign: 'center', textDecoration: 'none' }]}>{produto.marca}</Typography>
                            <Divider />
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: 1 }}>
                                <Prices produto={produto} isTiniPrice={isTiniPrice} />
                            </Box>
                            {tableInfo &&
                                <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
                                    <Box sx={{ background: theme.palette.secondary.main, padding: 1, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                                        <Typography sx={{ color: theme.palette.text.onPrimary, fontSize: 12, fontWeight: 'bold' }}>{tableInfo}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    ), [produto])}
                    {useMemo(() => (
                        <ComprarButton anchorEl={anchorEl} produtoLink={produtoLink} />
                    ), [anchorEl])}
                </Box>
            </BoxHover >
        </Box>
    )
}

function ComprarButton({ anchorEl, produtoLink }) {
    const theme = useTheme()
    return (
        <Box sx={{ display: anchorEl ? 'block' : 'none', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1, paddingTop: 2 }}>
                <Button component={Link} to={produtoLink} type='button' fullWidth sx={{
                    textTransform: 'none', border: 1, padding: 1, borderColor: theme.palette.secondary.main
                }}>
                    <Typography sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>Ver mais</Typography>
                </Button>
            </Box>
        </Box>
    )
}


function Buttons({ produto }) {
    const theme = useTheme()
    const carrinho = useContext(Carrinho)
    const favoritos = useContext(Favoritos)
    //Init fav info
    const favData = favoritos?.data
    const existInFav = Array.isArray(favData) ? favData?.findIndex((e) => e.cod_barra == produto.cod_barra && e.loja == produto?.loja) : -1
    const bgColorFav = existInFav !== -1 ? theme.palette.error.main : theme.palette.background.paper
    const txtColorFav = existInFav !== -1 ? theme.palette.text.onPrimary : theme.palette.text.secondary
    const iconFav = favoritos?.isLoading && favoritos?.isLoading?.cod_barra === produto?.cod_barra ? getIcon(25, txtColorFav, 20, false, true) : getIcon(84, txtColorFav, 20)
    //End fav info
    //Init carrinho info
    const carrinhoData = carrinho?.data
    const existIn = Array.isArray(carrinhoData) ? carrinhoData?.findIndex((e) => e.cod_barra == produto.cod_barra && e.loja == produto?.loja) : -1
    const bgColor = existIn !== -1 ? theme.palette.warning.main : theme.palette.background.paper
    const txtColor = existIn !== -1 ? theme.palette.text.onPrimary : theme.palette.text.secondary
    const iconCarrinho = carrinho?.isLoading && carrinho?.isLoading?.cod_barra === produto?.cod_barra ? getIcon(25, txtColor, 20, false, true) : getIcon(85, txtColor, 20)
    //End carrinho info
    const newData = {
        cod_barra: produto?.cod_barra,
        image: produto?.image,
        loja: produto?.loja,
        loja_avatar: produto?.loja_avatar,
        loja_nome: produto?.loja_nome,
        nome: produto?.nome,
        quantidade: produto?.quantidade,
        table: produto?.table,
        vlr_final: produto?.vlr_final,
        vlr_venda: produto?.vlr_venda,
        qtd: produto?.qtd,
        qtdPreco: produto?.qtdPreco,
    }
    if (newData.cod_barra == '7506306244184') {
        console.log(newData, produto)
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '5px', right: '5px' }}>
            <IconButton sx={{ background: bgColor }} onClick={() => carrinho.add(newData)}>
                {iconCarrinho}
            </IconButton>
            <IconButton sx={{ background: bgColorFav }} onClick={() => favoritos.add(newData)}>
                {iconFav}
            </IconButton>
        </Box>
    )
}

export function whatVlrFinal(item) {
    if (item.qtd && item.quantidade >= item.qtd) return item.qtdPreco
    return item.vlr_final
}
export function Prices({ produto, othrVendaStl, bestPrcStyle, strkStyle, isTiniPrice, textAlign }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { vlr_venda, vlr_final, reg, qtd, qtdPreco, table } = produto;
    const desconto = (Math.abs((whatVlrFinal({ ...produto, ...{ quantidade: qtd ? qtd : 1 } }) - vlr_venda) / vlr_venda) * 100).toFixed(0);
    const fontSize = isMobile && isTiniPrice ? 16 : 24;
    const styles = {
        otherPriceStyle: othrVendaStl ? othrVendaStl : { color: '#777', fontWeight: 'bold', fontSize: 16 }, // Preço riscado com cor clara
        bestPriceStyle: bestPrcStyle ? bestPrcStyle : { color: theme.palette.primary.main, fontWeight: 'bold', fontSize: fontSize, textAlign: textAlign ? textAlign : 'center' },
        strikeThrough: strkStyle ? strkStyle : { textDecoration: 'line-through', fontWeight: 'bold', color: '#B0B0B0', fontSize: 16, textAlign: textAlign ? textAlign : 'center' }, // Estilo riscado
    };
    let formattedVlrVenda = `R$ ${numberToReal(vlr_venda)}`;
    let formattedNewPrice = `R$ ${numberToReal(vlr_final)}`;
    if (qtd) {
        formattedVlrVenda = '1 UN ' + formattedNewPrice
        formattedNewPrice = `${qtd} UN R$ ${numberToReal(qtdPreco)}`
    }
    return (
        <Box sx={{ position: 'relative', padding: !textAlign && '16px', borderRadius: '8px', textAlign: 'center' }}>
            <Typography variant="body2" sx={styles.strikeThrough}>{formattedVlrVenda}</Typography>
            {!textAlign && (
                <Box sx={{ position: 'absolute', top: '8px', right: '8px', backgroundColor: theme.palette.tertiary.main, borderRadius: '8px', padding: '4px 8px' }}>
                    <Typography sx={{ color: 'white', fontSize: '12px', fontWeight: 'bold' }}>-{desconto}%</Typography>
                </Box>
            )}
            <Typography variant="h5" sx={styles.bestPriceStyle}>{formattedNewPrice}</Typography>
        </Box>
    );
}
