import { Alert, Box, Typography, useTheme, TablePagination, CircularProgress, IconButton, Tooltip, Collapse, Select, MenuList, MenuItem } from "@mui/material";
import { FBFADM } from "../../../apis/FBFAdm";
import { Loja } from "../../../contexts/loja";
import { useContext, useEffect, useState } from "react";
import { BoxHover } from "../../../components/boxHover";
import { getIcon } from "../../../components/icons";
import { AuthTextField } from "../../../components/textFields";
import { TablePedidos } from "./components/tablePedidos";
import moment from "moment";

export function Pedidos() {
    const theme = useTheme();
    const { data: lojaData } = useContext(Loja);
    const [data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [buscar, setBuscar] = useState('')
    const [collActive, setCollActive] = useState(null)
    const [statusPedidos, setStatusPedidos] = useState(null)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        init: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        status: ''
    })
    async function getPedidos(page = 1, limit = 5, nome = '', filters) {
        setLoading(true)
        let params = `page=${page}&limit=${limit}`;
        if (nome) {
            params += `&buscar=${encodeURIComponent(nome)}`;
        }
        if (collActive === 'date') {
            if (filters?.init) {
                params += `&init=${encodeURIComponent(filters?.init)}`;
            }
            if (filters?.end) {
                params += `&end=${encodeURIComponent(filters?.end)}`;
            }
        }
        if (collActive === 'status') {
            params += `&status=${filters?.status}`
        }
        const get = await FBFADM(`marketplace/loja/getPedidos/${params}`, 'GET', null, lojaData?.token);
        if (get?.status !== 200) {
            setData(false);
        } else {
            setData(get?.apiReturn?.apiReturn);
            setTotalItems(get?.apiReturn?.totalCount || 0);
        }
        setLoading(false)
    }
    async function getStatusPedidos() {
        const get = await FBFADM('marketplace/loja/getStatusPedidos', 'GET', null, lojaData?.token)
        if (get.status !== 200) {
            setStatusPedidos(false)
            return
        }
        setStatusPedidos(get?.apiReturn?.apiReturn)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    function handleConfirmBuscar() {
        setCollActive('zero')
        getPedidos(1, 5, buscar)
    }
    const handleChangeFilters = (value, type) => {
        setBuscar('')
        const newFilters = { ...filters }
        newFilters[type] = value
        setFilters(newFilters)
    }
    const handleChangeColl = (value) => {
        setBuscar('')
        if (collActive === value) {
            setCollActive(null)
            return
        }
        setCollActive(value)
    }
    useEffect(() => {
        getPedidos(page + 1, rowsPerPage);
    }, [page, rowsPerPage]);
    useEffect(() => {
        if (collActive === 'zero') return
        getPedidos(1, 5, null, filters)
    }, [collActive, filters])
    useEffect(() => {
        if (collActive === 'status' && statusPedidos === null) {
            getStatusPedidos()
        }
    }, [collActive])
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ paddingLeft: 2, display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, color: theme.palette.secondary.dark }}>
                    Pedidos
                </Typography>
                {loading && (
                    <Alert severity='info' icon={getIcon(25, theme.palette.secondary.main, 15, null, true)} sx={{ fontSize: 12 }}>Carregando</Alert>
                )}
            </Box>
            <BoxHover width={'auto'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                        <AuthTextField size={'small'} placeHolder={'Buscar...'} isSubmit value={buscar} changeText={(value) => setBuscar(value)} enterFunc={handleConfirmBuscar} />
                        <IconButton sx={{ background: theme.palette.secondary.main, color: theme.palette.text.onPrimary, borderRadius: 0 }} onClick={handleConfirmBuscar}>
                            {getIcon(53)}
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Collapse orientation="horizontal" in={collActive === 'date'}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <AuthTextField type='date' size={'small'} value={filters.init} prop={'init'} changeText={handleChangeFilters} />
                                    <AuthTextField type='date' size={'small'} value={filters.end} prop={'end'} changeText={handleChangeFilters} />
                                </Box>
                            </Collapse>
                            <Tooltip title={'Filtrar por data'}>
                                <IconButton onClick={() => handleChangeColl('date')}>{getIcon(66)}</IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Collapse orientation="horizontal" in={collActive === 'status'}>
                                <Select
                                    size="small"
                                    displayEmpty
                                    value={filters?.status}
                                    renderValue={() => {
                                        if (statusPedidos === null) {
                                            return 'Carregando status...';
                                        } else if (statusPedidos === false) {
                                            return 'Erro ao carregar status';
                                        } else if ([null, false, ''].includes(filters.status)) {
                                            return 'Selecione um status';
                                        } else {
                                            return statusPedidos.find((e) => e.id === filters.status).nome
                                        }
                                    }}
                                    sx={{ background: theme.palette.background.default }}
                                    onChange={(value) => handleChangeFilters(value.target.value, 'status')}
                                >
                                    {statusPedidos && statusPedidos !== false && statusPedidos.map((status, index) => (
                                        <MenuItem key={index} value={status.id} sx={{ textAlign: 'center' }}>
                                            {status.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Collapse>
                            <Tooltip title={'Status dos Pedidos'}>
                                <IconButton onClick={() => handleChangeColl('status')}>
                                    {getIcon(22)}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </BoxHover>
            {
                data === null ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <CircularProgress color="secondary" />
                    </Box>
                ) : data === false ? (
                    <Alert severity="error">Ocorreu um erro ao carregar os dados.</Alert>
                ) : (
                    <BoxHover width={'auto'} padding={0.1}>
                        <TablePedidos data={data} />
                        <TablePagination
                            component="div"
                            count={totalItems}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                            labelRowsPerPage={'Pedidos por página'}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 20, 30]}
                        />
                    </BoxHover>
                )
            }
        </Box >
    );
}

