import { Avatar, Typography, Box, Link, Alert, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { useEffect, useState, useMemo } from "react";
import { FBFADM } from "../../apis/FBFAdm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Header } from "./components/header";
import { FiltersLoja, titleToId, titleToParam } from "./components/filters";
import { ListItens } from "./components/listItens";

const updateUrlParams = (urlParams, type, data, add) => {
    if (add) {
        urlParams.set(type, data);
    } else {
        urlParams.delete(type);
    }
};
const resetDependentFilters = (urlParams, updateFilter, type, data, isChecked) => {
    if (type === 'grupo') {
        updateFilter('categorias', data?.categs || [], isChecked);
        urlParams.delete('categoria');
        urlParams.delete('depto');
    }
    if (type === 'categoria') {
        updateFilter('deptos', data?.deptos || [], isChecked);
        urlParams.delete('depto');
    }
};
export function Loja({ scrollToTop }) {
    const navigate = useNavigate();
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchParams] = useSearchParams();
    const { id, produto: productId, grupo, categoria, depto, nome, marca, fabricante, princ_ativo, page, limit } = Object.fromEntries(searchParams.entries());
    const [loja, setLoja] = useState(null);
    const [produtos, setProdutos] = useState(null);
    const [filters, setFilters] = useState(null);
    const [ativeMenu, setAtiveMenu] = useState('products');
    const [searchWithFilters, setSearchWithFilters] = useState(false)
    const [ativeClass, setActiveClass] = useState(null)
    const [selectedValues, setSelectedValues] = useState({ grupo, categoria, depto });
    const getLoja = async () => {
        if (!id) return;
        scrollToTop();
        const response = await FBFADM(`marketplace/public/getLoja/${id}`, 'GET');
        if (response?.status !== 200) {
            navigate('/errorPage?error=1');
            return;
        }
        const apiData = response?.apiReturn?.apiReturn;
        if (!Array.isArray(apiData) || apiData.length < 1) {
            setLoja(false);
            return;
        }
        setLoja(apiData[0]);
        getProdutos();
    };
    const updateFilter = (type, data, isChecked) => {
        const updatedFilters = { ...filters };
        if (type === 'categorias') {
            if (isChecked) {
                updatedFilters['categorias'] = data;
            } else {
                delete updatedFilters['categorias'];
            }
            delete updatedFilters['deptos'];
        }
        if (type === 'deptos') {
            if (isChecked) {
                updatedFilters['deptos'] = data;
            } else {
                delete updatedFilters['deptos'];
            }
        }
        setFilters(updatedFilters);
    };
    const getMenu = async () => {
        const response = await FBFADM('marketplace/public/getMenu', 'GET');
        const apiData = response?.apiReturn?.apiReturn;
        if (response.status === 200 && Array.isArray(apiData) && apiData.length > 0) {
            setFilters({ ...filters, grupos: apiData });
        } else {
            setFilters(false);
        }
    };
    const updateSelectedValues = (type, value, isChecked) => {
        setSelectedValues(prevState => {
            const updatedValues = { ...prevState };
            if (isChecked) {
                updatedValues[type] = value;
            } else {
                delete updatedValues[type];
            }
            return updatedValues;
        });
    };
    const changeParamsUrl = (event, data, type) => {
        const isChecked = event.target.checked;
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('nome');

        const whatId = titleToId(type);
        const dataId = data?.[whatId];
        const whatParam = titleToParam(type);
        if (!dataId) return;
        updateSelectedValues(type, data?.[whatParam], isChecked);
        let nextActiveClass = null;
        if (isChecked) {
            nextActiveClass = data?.[whatParam];
        } else {
            if (type === 'depto') {
                nextActiveClass = selectedValues.categoria || selectedValues.grupo || null;
            } else if (type === 'categoria') {
                nextActiveClass = selectedValues.grupo || null;
            }
        }
        setActiveClass(nextActiveClass);
        resetDependentFilters(urlParams, updateFilter, type, data, isChecked);
        updateUrlParams(urlParams, type, dataId, isChecked);
        urlParams.set('page', 1);
        if (!urlParams.has('limit')) {
            urlParams.set('limit', 8);
        }
        navigate({ search: urlParams.toString() });
    };
    const changeNome = (nome) => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!nome) {
            urlParams.delete('nome');
        } else {
            urlParams.set('nome', nome);
        }
        urlParams.set('page', 1);
        navigate({ search: urlParams.toString() });
    };
    const changeLimit = (limit) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('limit', limit);
        urlParams.set('page', 1);
        navigate({ search: urlParams.toString() });
    };
    const handleChangePage = (value) => {
        scrollToTop()
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', value);
        navigate({ search: urlParams.toString() });
    };
    const getProdutos = async () => {
        if (!id) return;
        setProdutos(null);
        const searchParams = [];
        if (productId) searchParams.push(`id=${productId}`);
        if (grupo) searchParams.push(`grupo=${grupo}`);
        if (categoria) searchParams.push(`categoria=${categoria}`);
        if (depto) searchParams.push(`depto=${depto}`);
        if (nome) searchParams.push(`nome=${nome}`);
        if (marca) searchParams.push(`marca=${marca}`);
        if (fabricante) searchParams.push(`fabricante=${fabricante}`);
        if (princ_ativo) searchParams.push(`princ_ativo=${princ_ativo}`);
        if (page) searchParams.push(`page=${page}`);
        if (limit) searchParams.push(`limit=${parseInt(limit) + 1}`);
        if (searchParams.length === 0) searchParams.push('all=all');

        const searchQuery = searchParams.join('&');
        const url = nome && !searchWithFilters ? `marketplace/public/buscarLoja/loja=${id}&nome=${nome}&page=${page}&limit=${limit}` : `marketplace/public/buscarLoja/loja=${id}&${searchQuery}`;
        try {
            const response = await FBFADM(url, 'GET', null, null);
            const apiData = response?.apiReturn?.apiReturn;
            setProdutos(apiData || false);
        } catch (error) {
            setProdutos(false);
            setFilters(false);
        }
    };
    const changeActiveMenu = (menu) => {
        setAtiveMenu(menu);
    };
    useEffect(() => {
        getMenu();
        getLoja();
    }, [id]);
    useEffect(() => {
        if (loja) getProdutos();
    }, [productId, grupo, categoria, depto, nome, marca, fabricante, princ_ativo, page, limit]);
    const filtersLojaMemo = useMemo(() => (
        <FiltersLoja
            loja={loja}
            filters={filters}
            ObjParams={{ productId, grupos: grupo, categorias: categoria, deptos: depto, nome, marcas: marca, fabricantes: fabricante, princ_ativos: princ_ativo }}
            changeParamsUrl={changeParamsUrl}
        />
    ), [loja, filters, productId, grupo, categoria, depto, nome, marca, fabricante, princ_ativo, changeParamsUrl]);
    const listItensMemo = useMemo(() => (
        <ListItens produtos={produtos} changeLimit={changeLimit} handleChangePage={handleChangePage} loja={loja} lojaId={id} />
    ), [produtos, changeLimit, handleChangePage, loja]);
    return (
        <Container>
            {loja === false || !id ? (
                <Alert severity="error">Loja não encontrada ou não disponível.</Alert>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Header
                        loja={loja}
                        lojaId={id}
                        activeMenu={ativeMenu}
                        changeActiveMenu={changeActiveMenu}
                        changeNome={changeNome}
                        searchWithFilters={searchWithFilters}
                        ativeClass={ativeClass}
                        updateSWFilters={() => {
                            changeNome()
                            setSearchWithFilters(!searchWithFilters)
                        }
                        }
                    />
                    {ativeMenu === 'products' && (
                        <Box sx={{ display: 'flex', gap: isMobile ? 2 : 3 }}>
                            <Box sx={{ width: isSmallScreen ? '0%' : '25%' }}>
                                {filtersLojaMemo}
                            </Box>
                            <Box sx={{ width: '100%', position: 'relative', left: isMobile && -10 }}>
                                {listItensMemo}
                            </Box>
                        </Box>
                    )}
                    {ativeMenu === 'about' && (
                        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{loja?.sobre}</Typography>
                    )}
                </Box>
            )}
        </Container>
    );
}