import { Avatar, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import { numberToReal } from "../../../../../components/masks";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Loja } from "../../../../../contexts/loja";



export function TableProdutos({ data }) {
    const theme = useTheme()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const { data: dataLoja } = useContext(Loja)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const tableProdutos = Array.isArray(data) ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : []
    return (
        <>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                Produtos do Pedido
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Imagem</TableCell>
                            <TableCell>Produto</TableCell>
                            <TableCell align="right">Código de Barras</TableCell>
                            <TableCell align="right">Quantidade</TableCell>
                            <TableCell align="right">Preço Unitário</TableCell>
                            <TableCell align="right">Preço Final</TableCell>
                            <TableCell align="right">Desconto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableProdutos.map((produto, index) => (
                            <TableRow key={index}>
                                <TableCell component={Link} to={`/produto?id=${produto?.cod_barra}&loja=${dataLoja?.id}`}>
                                    <Avatar variant="square" src={produto.image} sx={{ width: 30, height: 30 }} />
                                </TableCell>
                                <TableCell component={Link} to={`/produto?id=${produto?.cod_barra}&loja=${dataLoja?.id}`} sx={{ color: theme.palette.text.primary, textDecoration: 'none' }}>{produto.nome}</TableCell>
                                <TableCell align="right">{produto.cod_barra}</TableCell>
                                <TableCell align="right">{produto.quantidade}</TableCell>
                                <TableCell align="right">R$ {numberToReal(produto.vlr_venda)}</TableCell>
                                <TableCell align="right">R$ {numberToReal(produto.vlr_final)}</TableCell>
                                <TableCell align="right">{numberToReal(produto.desc_prc)}% (R$ {numberToReal(produto.desc_vlr)})</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.length || 1}
                    rowsPerPage={rowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                    labelRowsPerPage={'Produtos por página'}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}