import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';



export function Banners({ data }) {
    const banners = data?.banners
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const parameter = isMobile ? 'mobile' : 'full'
    return (
        <Box sx={{ width: '100%' }}>
            {!banners || !Array.isArray(banners) ?
                <Skeleton variant='rectangular' width={'100%'} height={300} />
                :
                <>
                    <Swiper
                        slidesPerView={1}
                        modules={[Pagination, Navigation, Autoplay]}
                        pagination={{
                            el: '.custom-pagination-banner',
                            clickable: true,
                        }}
                        loop={true}
                        style={{ width: '100%' }}
                        autoplay={{ delay: 3000 }}
                    >
                        {banners.map((e, idx) => {
                            return (
                                <SwiperSlide key={idx} style={{ width: '100%', maxHeight: 390 }}>
                                    <img src={e?.[parameter]} style={{ objectFit: 'initial', width: '100%', maxHeight: 390 }} />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <Box className="custom-pagination-banner" sx={{
                        marginTop: 1, display: 'flex', justifyContent: 'center',
                        '& .swiper-pagination-bullet': {
                            width: '9px',
                            height: '9px',
                            borderRadius: '7px',
                            backgroundColor: 'transparent',
                            margin: '0 4px',
                            opacity: 1,
                            border: 2,
                            borderColor: theme.palette.secondary.main,
                        },
                        '& .swiper-pagination-bullet-active': {
                            backgroundColor: theme.palette.secondary.main,
                        }
                    }} />
                </>
            }
        </Box>
    )
}