import { Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { Menu } from "./conta/menu";
import { Tela } from "./conta/tela";





export function ContaLoja({ scrollToTop }) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const modulos = [
        {
            title: 'Dashboard',
            icon: 98
        },
        {
            title: 'Pedidos',
            icon: 85
        },
        {
            title: 'Meus dados',
            icon: 11
        },
        {
            title: 'Configurações de Entrega',
            icon: 50
        },
    ]
    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 1, height: '100%' }}>
                <Box sx={{ width: isMobile ? '100%' : '30%' }}>
                    <Menu modulos={modulos} />
                </Box>
                <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
                <Tela modulos={modulos} scrollToTop={scrollToTop} />
            </Box>
        </Container>
    )
}