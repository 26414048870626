import { Alert, Avatar, Box, Button, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { getIcon } from "../../../components/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthTextField, MultTextField } from "../../../components/textFields";
import { FBFADM } from "../../../apis/FBFAdm";
import { Loja } from "../../../contexts/loja";
import { ValidaCEP } from "../../../components/validaCampo";
import { cepMask, onlyInteger, telefoneMask, telefoneRegex } from "../../../components/masks";

export function MinhasInfo({ scrollToTop }) {
    const theme = useTheme();
    const { data: lojaData } = useContext(Loja)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(dataInit);
    const [errorMessage, setError] = useState('')
    const [sucessMessage, setSucessMessage] = useState('')
    async function getData() {
        setLoading(true)
        const get = await FBFADM('marketplace/loja/getMinhasInfo', 'GET', null, lojaData?.token)
        setLoading(false)
        scrollToTop()
        if (get?.status !== 200) {
            setError('Ocorreu um erro ao buscar suas informações.')
            return
        }
        setData(get?.apiReturn?.apiReturn)
    }
    async function updateData(value, type) {
        let newD = { ...data }
        if (type === 'cep' && ValidaCEP(value)) {
            scrollToTop()
            setLoading(true)
            const getCep = await FBFADM('marketplace/public/getCep', 'POST', { cep: value })
            setLoading(false)
            if (getCep.status !== 200) {
                updateError('Ocorreu um erro ao buscar o cep.')
            }
            else {
                newD = { ...newD, ...getCep?.apiReturn?.apiReturn }
            }
        }
        newD[type] = value
        setData(newD)
    }
    async function saveData() {
        setLoading(true)
        scrollToTop()
        const save = await FBFADM('marketplace/loja/saveMinhasInfo', 'POST', data, lojaData?.token)
        if (save.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar as informações.'
            setError(message)
            setSucessMessage('')
            setLoading(false)
            return
        }
        setError('')
        setSucessMessage('Informações salvas com sucesso.')
        setLoading(false)
    }
    function updateError(value) {
        scrollToTop()
        setError(value)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Box sx={{ width: '100%' }}>
            {errorMessage && (
                <Box sx={{ padding: 2, paddingRight: 0 }}>
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                </Box>
            )}
            {sucessMessage && (
                <Box sx={{ padding: 2, paddingRight: 0 }}>
                    <Alert severity='success'>
                        {sucessMessage}
                    </Alert>
                </Box>
            )}
            <Box sx={{ paddingLeft: 2, display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, color: theme.palette.secondary.dark }}>
                    Meus Dados
                </Typography>
                {loading && (
                    <Alert severity='info' icon={getIcon(25, theme.palette.secondary.main, 15, null, true)} sx={{ fontSize: 12 }}>
                        Carregando
                    </Alert>
                )}
            </Box>
            <Box sx={{ paddingLeft: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ position: 'absolute', bottom: 0, left: 0, height: '100%', background: 'rgba(0,0,0,0.5)', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Logo data={data} updateData={updateData} updateError={updateError} />
                    </Box>
                    <Banner data={data} updateData={updateData} updateError={updateError} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: theme.palette.secondary.dark }}>Informações principais</Typography>
                        <Tooltip title="Solicite ao suporte da franquia a troca dessas informações." placement='top-start'>
                            {getIcon(103, theme.palette.secondary.dark, 15)}
                        </Tooltip>
                    </Box>
                    <InfoNaoEdit data={data} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: theme.palette.secondary.dark }}>Outras informações</Typography>
                    <InfoEdit data={data} changeData={updateData} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: theme.palette.secondary.dark }}>Horário de funcionamento</Typography>
                    <MultTextField value={data.hor_funcionamento} preWrap changeText={updateData} prop={'hor_funcionamento'} minRows={10} fullWidth />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: theme.palette.secondary.dark }}>Sobre</Typography>
                    <MultTextField value={data.sobre} preWrap changeText={updateData} prop={'sobre'} minRows={10} fullWidth />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Button variant='contained' color="secondary" onClick={saveData}>Salvar</Button>
                </Box>
            </Box>
        </Box >
    );
}
const dataInit = {
    avatar: '',
    banner: '',
    raz_social: '',
    nom_marketplace: '',
    email: '',
    bandeira: '',
    cnpj: '',
    telefone: '',
    cep: '',
    rua: '',
    numero: '',
    bairro: '',
    estado: '',
    cidade: '',
    latitude: '',
    longitude: '',
    hor_funcionamento: '',
    farm_resp: '',
    afe: '',
    alvara: '',
    sobre: ''
};
function InfoEdit({ data, changeData }) {
    const campos = {
        nom_marketplace: data.nom_marketplace,
        telefone: telefoneRegex(data.telefone),
        rua: data.rua,
        cep: cepMask(data.cep),
        numero: onlyInteger(data.numero),
        bairro: data.bairro,
        cidade: data.cidade,
        estado: data.estado,
        latitude: data.latitude,
        longitude: data.longitude,
    }
    const disableds = ['estado', 'cidade']
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {Object.keys(campos).map((e, idx) => {
                return (
                    <Box key={idx}>
                        <Typography>{camposToNome(e)}</Typography>
                        <AuthTextField value={campos[e]} size={'small'} disabled={disableds.includes(e)} changeText={changeData} prop={e} />
                    </Box>
                )
            })}
        </Box>
    )
}
function InfoNaoEdit({ data }) {
    const campos = {
        email: data.email,
        cnpj: data.cnpj,
        farm_resp: data.farm_resp,
        afe: data.afe,
        alvara: data.alvara
    }
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {Object.keys(campos).map((e, idx) => {
                return (
                    <Box key={idx}>
                        <Typography>{camposToNome(e)}</Typography>
                        <AuthTextField disabled={true} value={campos[e]} size={'small'} />
                    </Box>
                )
            })}
        </Box>
    )
}
function Banner({ data, updateData, updateError }) {
    const fileInputRef = useRef(null);
    const theme = useTheme()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/png' && file.size <= 10 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result;
                    updateError(''); // Clear any previous error
                    updateData(base64String, 'banner')
                };
                reader.readAsDataURL(file);
            } else {
                updateError('Por favor, selecione um arquivo PNG dentro do limite de tamanho (10MB)');
            }
        } else {
            updateError('Por favor, selecione um arquivo PNG');
        }
    };

    const handleBannerClick = () => {
        fileInputRef.current.click();
    };
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: 300,
                    background: '#f0f0f0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={handleBannerClick}
            >
                {data.banner ? (
                    <img src={data.banner} alt="Banner" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                    getIcon(102, theme.palette.divider, 150)
                )}
            </Box>
            <input
                type="file"
                accept="image/png"
                id="upload-image"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: 'none' }}
            />
        </>
    )
}
function Logo({ data, updateData, updateError }) {
    const fileInputRef = useRef(null);
    const theme = useTheme()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/png' && file.size <= 10 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result;
                    updateError(''); // Clear any previous error
                    updateData(base64String, 'avatar')
                };
                reader.readAsDataURL(file);
            } else {
                updateError('Por favor, selecione um arquivo PNG dentro do limite de tamanho (10MB)');
            }
        } else {
            updateError('Por favor, selecione um arquivo PNG');
        }
    };

    const handleBannerClick = () => {
        fileInputRef.current.click();
    };
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
            <Button onClick={handleBannerClick}>
                <Avatar src={data.avatar} sx={{ width: 150, height: 150, background: theme.palette.secondary.main }} />
            </Button>
            <input
                type="file"
                accept="image/png"
                id="upload-image"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: 'none' }}
            />
        </Box>
    )
}
const camposToNome = (campo) => {
    const campos = {
        nom_marketplace: 'Nome no Marketplace',
        email: 'E-mail',
        cnpj: 'CNPJ',
        rua: 'Rua',
        cep: 'CEP',
        numero: 'Número',
        bairro: 'Bairro',
        estado: 'Estado',
        cidade: 'Cidade',
        latitude: 'Latitude',
        longitude: 'Longitude',
        farm_resp: 'Farmacêutico responsável',
        afe: 'AFE',
        alvara: 'Alvará',
        telefone: 'Telefone',
        hor_funcionamento: 'Horário de funcionamento',
    }
    if (campos.hasOwnProperty(campo)) return campos[campo]
    return campo
}