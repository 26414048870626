const local = false

const PHPApiAdress = local === false ? process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_LOCAL_URL

export const FBFADM = async (type, method, body, userToken) => {
    const headers = {
        'Cache-Control': 'no-cache, no-store',
        'App-token': process.env.REACT_APP_API_APP_TOKEN,
        'Pragma': 'no-cache',
        'Content-Type': 'application/json',
        'user-token': userToken ? userToken : '',
        'if-none-match': '',
        'bandeira': process.env.REACT_APP_API_APP_BANDEIRA
    }
    const requestReturn = {
        status: false,
        apiReturn: []
    }
    const timeOutApi = new Promise((resolve, reject) => {
        const x = {
            status: 3200,
            message: 'O servidor está com problemas. Tente novamente mais tarde.'
        }
        setTimeout(resolve, 15000, x)
    })
    const send = body != null ? {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    } : {
        method: method,
        headers: headers,
    }
    const apiRequest = async () => {
        await fetch(PHPApiAdress + '/' + type, send).then(async (response) => {
            requestReturn.status = response.status
            return response.json()
        }).then((json) => {
            requestReturn.apiReturn = json
        }).catch((error) => {
            console.log(error)
        })
        return requestReturn
    }
    return await Promise.race([apiRequest(), timeOutApi]).then((value) => {
        return value
    })
}