import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { corrigirNome } from "../../../components/masks";

export function ProdutoBreadCrumb({ produto }) {
    console.log(produto)
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {produto?.grupo_nome && (
                <Link to={`/buscar?grupo=${produto.grupo_id}`} style={{ textDecoration: 'none' }}>
                    <Typography color="text.primary">{produto.grupo_nome}</Typography>
                </Link>
            )}
            {produto?.categ_nome && (
                <Link to={`/buscar?categoria=${produto.categ_id}`} style={{ textDecoration: 'none' }}>
                    <Typography color="text.primary">{produto.categ_nome}</Typography>
                </Link>
            )}
            {produto?.depto_nome && (
                <Link to={`/buscar?depto=${produto.depto_id}`} style={{ textDecoration: 'none' }}>
                    <Typography color="text.primary">{produto.depto_nome}</Typography>
                </Link>
            )}
            {produto?.produto_nome && (
                <Typography color="text.primary">{corrigirNome(produto.produto_nome)}</Typography>
            )}
        </Breadcrumbs>
    );
}
