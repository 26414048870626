import { useEffect, useState } from "react";
import { Box, Button, IconButton, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "./container";
import { getIcon } from "./icons";
import { Link as RouterLink } from "react-router-dom"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FBFADM } from "../apis/FBFAdm";



export function Footer() {
    const theme = useTheme()
    const [info, setInfo] = useState('')
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    async function getFooterInfo() {
        const get = await FBFADM('marketplace/public/getFooterInfo', 'GET')
        const apiData = get?.apiReturn?.apiReturn
        if (apiData) {
            setInfo(apiData)
        }
    }
    const textStyle = {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontSize: 14
    }
    const boxStyle = {
        display: 'flex', flexDirection: 'column', gap: 1, flex: 1, minWidth: 150
    }
    useEffect(() => {
        getFooterInfo()
    }, [])
    return (
        <Box>
            <Box sx={{ background: theme.palette.primary.main }}>
                <Container paddingRight={0.1} paddingLeft={0.1}>
                    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? 3 : 0, padding: 3, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.onPrimary, fontSize: 25, textTransform: 'uppercase', textAlign: isMobile ? 'center' : 'left' }}>Torne-se um franqueado {theme.palette.nome}</Typography>
                            <Typography sx={{ textAlign: isMobile ? 'center' : 'left', color: theme.palette.text.onPrimary }}>Tenha acesso a inúmeras ferramentas e sua farmácia presente no mundo digital.</Typography>
                        </Box>
                        <Button href="https://www.farmaciasbrasilpoupalar.com.br/" target="_blank" variant='outlined' size='large' sx={{ color: theme.palette.text.onPrimary, textTransform: 'uppercase', gap: 2, borderColor: theme.palette.text.onPrimary, borderWidth: 2, fontWeight: 'bold', padding: 2, paddingLeft: 4, paddingRight: 4, '&:hover': { borderColor: theme.palette.text.onPrimary, borderWidth: 2 } }}>
                            {getIcon(20)}
                            Veja nossos benefícios
                        </Button>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ width: '100%', background: theme.palette.background.default }}>
                <Container paddingRight={0.1} paddingLeft={0.1}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 3, flexWrap: 'wrap' }}>
                        <Box sx={boxStyle}>
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 'bold' }}>Grupos</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography component={RouterLink} to={'/buscar?grupo=1'} sx={textStyle}>Medicamentos</Typography>
                                <Typography component={RouterLink} to={'/buscar?grupo=328'} sx={textStyle}>Perfumaria</Typography>
                                <Typography component={RouterLink} to={'/buscar?grupo=12'} sx={textStyle}>Mamãe e Bebê</Typography>
                                <Typography component={RouterLink} to={'/buscar?grupo=331'} sx={textStyle}>Melhor idade</Typography>
                                <Typography component={RouterLink} to={'/buscar?grupo=8'} sx={textStyle}>Higiene pessoal</Typography>
                                <Typography component={RouterLink} to={'/buscar?grupo=13'} sx={textStyle}>Beleza</Typography>
                            </Box>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 'bold' }}>Categorias</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography component={RouterLink} to={'/buscar?categoria=155'} sx={textStyle}>Alimentação infantil</Typography>
                                <Typography component={RouterLink} to={'/buscar?categoria=114'} sx={textStyle}>Transformação</Typography>
                                <Typography component={RouterLink} to={'/buscar?categoria=311'} sx={textStyle}>Alimentos e bebidas</Typography>
                                <Typography component={RouterLink} to={'/buscar?categoria=345'} sx={textStyle}>Solares</Typography>
                                <Typography component={RouterLink} to={'/buscar?categoria=137'} sx={textStyle}>Higiene oral</Typography>
                                <Typography component={RouterLink} to={'/buscar?categoria=69'} sx={textStyle}>Amamentação</Typography>
                                <Typography component={RouterLink} to={'/buscar?categoria=13'} sx={textStyle}>Fitoterápicos</Typography>
                            </Box>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 'bold' }}>Institucional</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography component={'a'} href="https://www.franchisingbrasilfarma.com.br/" target="_blank" sx={textStyle}>Quem somos</Typography>
                                <Typography component={'a'} href="https://privacidade.fbf.far.br/" target="_blank" sx={textStyle}>Política de privacidade</Typography>
                                <Typography component={'a'} href="https://www.farmaciasbrasilpoupalar.com.br/franqueados/" target="_blank" sx={textStyle}>Nossas lojas</Typography>
                            </Box>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 'bold' }}>Nossas redes</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <IconButton component={Link} href="https://www.facebook.com/farbrpl" target="_blank">
                                    <FacebookIcon sx={{ width: 40, height: 40, color: theme.palette.text.primary }} />
                                </IconButton>
                                <IconButton component={Link} href="https://www.instagram.com/farmacias.brasilpoupalar/" target="_blank">
                                    <InstagramIcon sx={{ width: 40, height: 40, color: theme.palette.text.primary }} />
                                </IconButton>
                                <IconButton component={Link} href="https://br.linkedin.com/company/farmacias-brasil-poupa-lar" target="_blank">
                                    <LinkedInIcon sx={{ width: 40, height: 40, color: theme.palette.text.primary }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    {info && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3, gap: 2}}>
                            <img src={theme.palette.logo} style={{ objectFit: 'contain', width: 150 }} />
                            <Box sx={{}}>
                                <Typography sx={{ fontSize: 12, textAlign: 'center' }}>{info}</Typography>
                                <Typography sx={{ fontSize: 12, textAlign: 'center' }}>A Brasil Poupa Lar segue as determinações da Anvisa. As informações contidas neste site não devem ser usadas para automedicação e não substituem, em hipótese alguma, as orientações dadas pelo profissional da área médica. Somente o médico está apto a diagnosticar qualquer problema de saúde e prescrever o tratamento adequado. Ao persistirem os sintomas, um médico deverá ser consultado. Realizamos o tratamento de seus dados pessoais de acordo com os princípios da boa-fé, finalidade, adequação, necessidade, lívre acesso, qualidade de dados, segurança, prevenção, não discriminação e, mais importante, transparência. Qualquer tratamento de dados pessoais, sensíveis ou não, realizados estará baseado em fundamento legal e se dará de forma adequada com a finalidade da sua coleta.</Typography>
                            </Box>
                        </Box>
                    )}
                </Container>
            </Box>
        </Box>
    )
}