import { Box, Stepper, Step, StepLabel, Typography, CircularProgress, useTheme, Button, Alert } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FBFADM } from "../../../../../apis/FBFAdm";
import { Loja } from "../../../../../contexts/loja";
import { getIcon } from "../../../../../components/icons";
import { AuthTextField } from "../../../../../components/textFields";

export function ProcessoPedido({ currentStatus, pedido, updateStatus, entregaTipo }) {
    const [status, setStatus] = useState(null);
    const { data: lojaData } = useContext(Loja);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [codRastreio, setCodRastreio] = useState('')
    const theme = useTheme()

    async function getStatusPedidos() {
        const get = await FBFADM('marketplace/loja/getStatusPedidos', 'GET', null, lojaData?.token);
        if (get.status !== 200) {
            setStatus(false);
            return;
        }
        setStatus(get?.apiReturn?.apiReturn);
    }
    async function processStatus() {
        const body = {
            pedido: pedido,
        }
        if (currentStatus === 3) {
            body.cod_rastreio = codRastreio
        }
        setLoading(true)
        const save = await FBFADM('marketplace/loja/progressPedido', 'POST', body, lojaData?.token)
        setLoading(false)
        const message = save?.apiReturn?.message && save?.apiReturn?.message
        if (save?.status !== 200) {
            setError(message || 'Ocorreu um erro ao processar o pedido')
            return
        }
        if (message) {
            setError(message)
        }
        else {
            setError('')
        }
        updateStatus(save?.apiReturn?.apiReturn)
    }
    useEffect(() => {
        getStatusPedidos();
    }, []);
    if (!status) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
        );
    }
    const stepsWithActions = [1, 2, 3, 4]
    return (
        <Box sx={{ maxWidth: '100%', margin: '0 auto' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Progresso do pedido</Typography>
            <Stepper
                activeStep={currentStatus}
                alternativeLabel
                sx={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    '& .MuiStepIcon-root': {
                        color: 'gray',
                        '&.Mui-completed': {
                            color: 'green',
                        },
                        '&.Mui-active': {
                            color: stepsWithActions.includes(currentStatus) ? 'red' : theme.palette.secondary.main,
                        },
                    },
                }}
            >
                {status.map((step) => {
                    if (step.id > currentStatus) return null;
                    if (step.id === 5 && entregaTipo === 3) return
                    if (entregaTipo !== 3 && step.id === 4) return
                    return (
                        <Step key={step.id}>
                            <StepLabel>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: stepsWithActions.includes(currentStatus) && step.id === currentStatus ? 'red' : step.id === currentStatus && !stepsWithActions.includes(currentStatus) ? theme.palette.secondary.main : 'primary',
                                        fontWeight: step.id === currentStatus ? 'bold' : 'normal',
                                    }}
                                >
                                    {step.nome}
                                </Typography>
                                <Typography variant="caption">{step.descricao}</Typography>
                            </StepLabel>
                            {step.id === 3 && currentStatus === 3 && entregaTipo === 2 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1, marginBottom: 1 }}>
                                    <AuthTextField size={'small'} label={'Código de rastreio'} value={codRastreio} changeText={(value) => setCodRastreio(value)} />
                                </Box>
                            )}
                            {((stepsWithActions.includes(step.id) || (step.id === 5 && entregaTipo === 4)) && step.id === currentStatus) && (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{ textTransform: 'none' }}
                                        onClick={processStatus}
                                        startIcon={loading && getIcon(25, theme.palette.text.onPrimary, 13, null, true)}
                                    >
                                        Processar
                                    </Button>
                                </Box>
                            )}
                        </Step>
                    );
                })}
            </Stepper>
            {error && (
                <Alert severity='info'>{error}</Alert>
            )}
        </Box>
    );
}
