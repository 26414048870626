import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { numberToReal } from "../../../../components/masks";
import { Link } from 'react-router-dom'
import { getIcon } from "../../../../components/icons";
import { Pedido } from "../pedido";
import { useState } from "react";

export function TablePedidos({ data }) {
    const theme = useTheme()
    const [pedido, setPedido] = useState({
        visible: false,
        id: null
    })
    function updatePedido(visible, id) {
        const newP = { ...pedido }
        newP.visible = visible || false
        newP.id = id || null
        setPedido(newP)
    }
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Mais</TableCell>
                        <TableCell>Pedido</TableCell>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Data Pedido</TableCell>
                        <TableCell>Data Atualizado</TableCell>
                        <TableCell>Itens</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(data) && data?.length > 0 ? data.map((venda, index) => (
                        <TableRow key={index}>
                            <TableCell onClick={() => updatePedido(true, venda.pedido)}>{getIcon(77)}</TableCell>
                            <TableCell>{venda.pedido}</TableCell>
                            <TableCell>{venda.cliente_nome}</TableCell>
                            <TableCell>{moment(venda.data_pedido).format(' HH:mm DD/MM/YYYY')}</TableCell>
                            <TableCell>{moment(venda.data_atualizado).format(' HH:mm DD/MM/YYYY')}</TableCell>
                            <TableCell>{venda.qtd_itens}</TableCell>
                            <TableCell>R${numberToReal(venda.total)}</TableCell>
                            <TableCell sx={statusStyle(venda.status, theme)}>{venda.status_nome}</TableCell>
                        </TableRow>
                    )) : <TableRow>
                        <TableCell sx={{ color: theme.palette.tertiary.main }}>Não foram encontrados dados</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>}
                </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap: 5 }}>
                {legenda.map((e, idx) => {
                    return (
                        <Box key={idx} sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ height: 20, width: 20, backgroundColor: theme.palette[e.color].main }} />
                            <Typography sx={{ fontSize: 12 }}>{e.title}</Typography>
                        </Box>
                    )
                })}
            </Box>
            <Pedido visible={pedido.visible} pedidoId={pedido.id} dismiss={updatePedido} />
        </TableContainer>
    )
}
const legenda = [
    {
        title: 'Aguardando processo do cliente',
        color: 'secondary'
    },
    {
        title: 'Aguardando sua interação',
        color: 'warning'
    },
    {
        title: 'Pedido concluído',
        color: 'primary'
    },
    {
        title: 'Pedido Cancelado',
        color: 'tertiary'
    },
]

const statusStyle = (status, theme) => {
    const style = {
        padding: 0,
        width: 'auto',
        borderRadius: 0,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 10
    }
    const statusStyle = {
        0: { color: '#fff', backgroundColor: theme.palette.secondary.main },
        1: { color: '#fff', backgroundColor: theme.palette.warning.main },
        2: { color: '#fff', backgroundColor: theme.palette.warning.main },
        3: { color: '#fff', backgroundColor: theme.palette.warning.main },
        4: { color: '#fff', backgroundColor: theme.palette.secondary.main },
        5: { color: '#fff', backgroundColor: theme.palette.secondary.main },
        6: { color: '#fff', backgroundColor: theme.palette.primary.main },
    }
    return { ...style, ...statusStyle[status] }
}