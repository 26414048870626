import { Alert, Box, Button, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { Cliente } from "../../../contexts/cliente";
import { useNavigate } from "react-router-dom";
import { cepMask } from "../../../components/masks";



export function MeusEnderecos({ }) {
    const theme = useTheme()
    const { enderecos, deleteEndereco } = useContext(Cliente)
    const navigate = useNavigate();
    function newEndereco() {
        navigate('/minha-conta?mod=10')
    }
    async function delEndereco(id) {
        const tryDel = await deleteEndereco(id)
    }

    return (
        <Box sx={{ width: '100%', padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5" gutterBottom>Meus Endereços</Typography>
            <Box sx={{ width: '100%' }}>
                {enderecos.length === 0 ? (
                    <Alert severity='info'>{`Você ainda não possui endereços`}</Alert>
                ) : enderecos.map((e, idx) => {
                    return (
                        <Box key={idx} sx={{ display: 'flex', gap: 2, padding: 1, border: theme.palette.divider, borderWidth: 1, minWidth: 220, borderStyle: 'solid' }}>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{e?.rua}</Typography>
                                <Typography sx={{ fontSize: 14 }}>{`CEP ${cepMask(e?.cep)} - ${e.cidade} - ${e.estado}`}</Typography>
                                {e?.complemento && (
                                    <Typography sx={{ fontSize: 14 }}>{e.complemento}</Typography>
                                )}
                                <Button size='small' color="tertiary" sx={{ textTransform: 'none' }} onClick={() => delEndereco(e.id)}>Remover</Button>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <Box>
                <Button variant='contained' size='large' color="secondary" sx={{ fontWeight: 'bold', textTransform: 'none' }} onClick={newEndereco}>Cadastrar novo endereço</Button>
            </Box>
        </Box>
    )
}