import { useContext } from "react";
import { Favoritos } from "../../contexts/favoritos";
import { Alert, Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { OneProduct } from "../../components/oneProduct";
import { Container } from "../../components/container";



export function FAV({ }) {
    const theme = useTheme()
    const favoritos = useContext(Favoritos)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const data = favoritos?.data

    return (
        <Container>
            <Typography sx={{ color: theme.palette.text.primary, fontSize: 30, fontWeight: 'bold' }}>Meus Favoritos</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {data.length > 0 ? (
                    <Box sx={{ display: isMobile ? 'grid' : 'flex', gridTemplateColumns: '1fr 1fr', justifyContent: isMobile ? 'center' : 'space-between', width: '100%', gap: 0, flexWrap: 'wrap' }}>
                        {data.map((e) => {
                            return (
                                <OneProduct produto={e} margin={isMobile ? 0.1 : 0.5} width={isMobile ? '80%' : 230} isTiniPrice={true} />
                            )
                        })}
                    </Box>
                ) : (
                    <Box sx={{width: '100%'}}>
                        <Alert severity='info'>Você ainda não possui favoritos</Alert>
                    </Box>
                )}
            </Box>
        </Container>
    )

}