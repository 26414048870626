import { Box, Alert, useMediaQuery, useTheme } from '@mui/material'
import { Container } from '../../components/container'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FBFADM } from '../../apis/FBFAdm';
import { LoadingProduto } from './components/loadingProduto';
import { ProdutoBreadCrumb } from './components/produtoBreadcrumbs';
import { ModalInfo } from '../../components/modalInfo';
import { ProdutoMain } from './components/produtoMain';
import { ProdutoPrice } from './components/produtoPrice';



export function Produto({ scrollToTop, mainRef }) {
    const navigate = useNavigate();
    const theme = useTheme()
    const [data, setData] = useState(null)
    const containerRef = useRef()
    const priceContainerRef = useRef()
    const [searchParams] = useSearchParams();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isSticky, setIsSticky] = useState(false);
    const scrollContainerRef = mainRef;
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    const productId = searchParams.get('id');
    const loja = searchParams.get('loja');
    async function getData(id) {
        const endpoint = loja ? `${id}-${loja}` : id
        const get = await FBFADM(`marketplace/public/getProduto/${endpoint}`, 'GET')
        const apiData = get?.apiReturn?.apiReturn
        if (get?.status === 200 && apiData?.produto) {
            setData(apiData)
            return
        }
        setData(false)
    }
    useEffect(() => {
        if (productId) {
            getData(productId, 1);
        }
        else {
            navigate('/errorPage?error=1')
        }
    }, [productId]);
    useEffect(() => {
        const handleScroll = () => {
            const scrollContainer = scrollContainerRef.current;
            const priceContainer = priceContainerRef.current;
            if (scrollContainer && priceContainer) {
                const scrollTop = scrollContainer.scrollTop;
                const clientHeight = scrollContainer.clientHeight;
                const scrollHeight = scrollContainer.scrollHeight;
                const priceContainerHeight = priceContainer.offsetHeight - 100;
                const scrollBottom = scrollHeight - scrollTop - clientHeight;
                const triggerPosition = 200;
                if (scrollTop > triggerPosition && scrollBottom > priceContainerHeight) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            }
        };

        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const stylePriceBig = {
        width: 400,
        position: isSticky ? 'fixed' : 'relative',
        top: isSticky ? '150px' : 'auto',
        right: isSticky && !isSmallScreen ? '15.5%' : '2%',
        height: 'auto',
    }

    const stylePriceSmall = {
        width: isMobile ? '100%' : isSmallScreen && '40%'
    }

    return (
        <Container>
            <Box ref={containerRef}>
                {data === null ?
                    <LoadingProduto />
                    :
                    data === false ?
                        <Alert severity="error">Produto não encontrado ou não disponível.</Alert>
                        :
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <ProdutoBreadCrumb produto={data.produto} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: '100%', gap: isMobile && 3 }}>
                                <Box sx={{ width: isMobile ? '100%' : '60%' }}>
                                    <ProdutoMain info={data?.produto} />
                                </Box>
                                <Box sx={isSmallScreen ? stylePriceSmall : stylePriceBig}>
                                    <ProdutoPrice produto={data.produto} priceRef={priceContainerRef} bestPrice={data?.bestPrice} allPrices={data?.allPrices} />
                                </Box>
                            </Box>
                        </Box>
                }
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Container>
    )
}
