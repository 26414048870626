import { useContext, useEffect, useMemo, useState } from "react";
import { Carrinho } from "../../../contexts/carrinho";
import { Alert, Avatar, Box, Button, Divider, IconButton, Radio, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CounterField } from "../../../components/counterField";
import DeleteIcon from '@mui/icons-material/Delete';
import { cepMask, corrigirNome, numberToReal } from "../../../components/masks";
import { getIcon } from "../../../components/icons";
import { Link } from "react-router-dom";
import { Cliente } from "../../../contexts/cliente";
import { FBFADM } from "../../../apis/FBFAdm";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ValidaCEP } from "../../../components/validaCampo";
import { ModalLoja } from "../../../components/modalLoja";
import { whatVlrFinal } from "../../../components/oneProduct";
export function ListProdutos({ changeCheckedFrete, checkedFretes, changeCadEndereco, selectEnd }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [modLoja, setModLoja] = useState({
        visible: false,
        loja: null
    })
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { enderecos } = useContext(Cliente);
    const { data: carrinhoData, add, isLoading, changeQuantidade } = useContext(Carrinho);
    const [expandedStores, setExpandedStores] = useState({});
    const [cep, setCep] = useState('')
    const [loadingCep, setLoadingCep] = useState(false)
    const [calcCeps, setCalcCeps] = useState(null)
    const haveEnd = Array.isArray(enderecos) && enderecos.length > 0 && enderecos[selectEnd]
    const itensPorLoja = useMemo(() => {
        return carrinhoData?.reduce((acc, item) => {
            if (!acc[item.loja_nome]) {
                acc[item.loja_nome] = [];
            }
            acc[item.loja_nome].push(item);
            return acc;
        }, {});
    }, [carrinhoData]);
    const itensFrete = useMemo(() => {
        return Object.entries(itensPorLoja).map(([lojaNome, itens], idx) => {
            const firstItem = itens[0]
            const custoProdutos = itens.reduce((acc, item) => acc + (parseFloat(whatVlrFinal(item)) * item.quantidade), 0).toFixed(2);
            return {
                user: firstItem?.loja,
                produto: firstItem?.cod_barra,
                produtos: itens,
                quantidade: firstItem?.quantidade,
                vlrCompra: custoProdutos
            }
        })
    }, [itensPorLoja, carrinhoData])
    async function calcFrete(prevCep, bairro) {
        setLoadingCep(true)
        const whatCep = prevCep ? prevCep : cep
        if (!ValidaCEP(whatCep)) {
            setLoadingCep(false)
            return
        }
        const newItensFrete = itensFrete.map((e) => {
            e.cepDestino = whatCep
            e.bairro = bairro
            return e
        })
        const get = await FBFADM('marketplace/public/calcfrete', 'POST', newItensFrete)
        const apiData = Array.isArray(get?.apiReturn?.apiReturn) ? get?.apiReturn?.apiReturn : []
        const newCheckdFretes = []
        changeCheckedFrete(newCheckdFretes, false)
        setLoadingCep(false)
        setCalcCeps(apiData)
    }
    function updateModLoja(visible, loja) {
        const newM = { ...modLoja }
        newM.visible = visible ? visible : false
        newM.loja = loja ? loja : false
        setModLoja(newM)
    }
    function handleQuantidade(item, quantidade) {
        if (quantidade < 1) return;
        changeQuantidade(item, quantidade);
    }
    const toggleStoreVisibility = (storeName) => {
        setExpandedStores(prevState => ({
            ...prevState,
            [storeName]: !prevState[storeName]
        }));
    };
    useEffect(() => {
        if (calcCeps?.length > 0) {
            setCalcCeps(null)
            changeCheckedFrete([], true)
        }
    }, [cep])
    useEffect(() => {
        if (Array.isArray(enderecos) && enderecos.length > 0 && enderecos[selectEnd]) {
            setCep(cepMask(enderecos[selectEnd]?.cep))
            calcFrete(enderecos[selectEnd]?.cep, enderecos[selectEnd]?.bairro)
        }
    }, [selectEnd, carrinhoData])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 1, borderColor: theme.palette.divider, padding: 2 }}>
                {!haveEnd ?
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {getIcon(97, theme.palette.tertiary.main)}
                        <Typography >Você ainda não possui endereços cadastrados. Cadastre um para continuar.</Typography>
                    </Box>
                    :
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>Entrega para: <span style={{ fontWeight: 'normal' }}>{cepMask(enderecos[selectEnd]?.cep)} - {enderecos[selectEnd]?.cidade}/{enderecos[selectEnd]?.estado}</span></Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Selecione uma forma de entrega na lista abaixo para continuar***</Typography>
                    </Box>
                }
                <Button sx={{ fontWeight: 'bold', textTransform: 'none', color: theme.palette.tertiary.main }} onClick={changeCadEndereco}>
                    {!haveEnd ? 'Cadastrar endereço' : 'Alterar endereco'}
                </Button>
            </Box>
            {Object.entries(itensPorLoja).map(([lojaNome, itens], idx) => {
                const firstItem = itens[0];
                const itemsToShow = itens
                const lojaFretes = Array.isArray(calcCeps) && calcCeps.filter((e) => e.userId == firstItem.loja)
                const checkedLojaFrete = checkedFretes.find((e) => e.loja == firstItem.loja)
                const hasControlado = itens.find((b) => b.classe)
                console.log(itens, hasControlado)
                return (
                    <Box key={idx} sx={{ border: 1, borderColor: theme.palette.divider }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, gap: 2, justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Avatar src={firstItem?.loja_avatar} sx={{ width: 70, height: 70 }} />
                                <Box>
                                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: 12 }}>Vendido e entregue por</Typography>
                                    <Typography sx={{ fontWeight: 'bold' }}>{firstItem?.loja_nome}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, gap: 1, width: { xs: '100%', sm: 'auto' } }}>
                                <Button variant='outlined' color="warning" size='small' sx={{ textTransform: 'none', fontWeight: 'bold', width: { xs: '100%', sm: 'auto' }, textAlign: 'center' }} onClick={() => updateModLoja(true, firstItem?.loja)}>Informações do vendedor</Button>
                                <Button component={Link} to={`/loja?id=${firstItem.loja}`} size='small' sx={{
                                    background: theme.palette.secondary.main, color: theme.palette.text.onPrimary,
                                    textAlign: 'center', textTransform: 'none', fontWeight: 'bold', '&:hover': { background: theme.palette.secondary.dark }, width: { xs: '100%', sm: 'auto' }
                                }}>Ver mais produtos da loja</Button>
                            </Box>
                        </Box>
                        <Box sx={{ padding: 1, paddingLeft: 2, paddingRight: 2 }}>
                            <Divider />
                        </Box>
                        {itemsToShow.map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', padding: 2, gap: 2 }}>
                                <Box sx={{ display: 'flex', flex: 2, gap: 2, alignItems: 'center' }}>
                                    <Avatar component={Link} to={`/produto?id=${item?.cod_barra}`} src={item.image} sx={{ width: 40, height: 40, objectFit: 'contain' }} />
                                    <Box>
                                        <Typography component={Link} to={`/produto?id=${item?.cod_barra}`} sx={{ textDecoration: 'none', color: theme.palette.text.primary }}>{corrigirNome(item?.nome)}</Typography>
                                        <Typography sx={{ color: item?.error ? theme.palette.error.main : theme.palette.text.secondary, fontSize: 12 }}>Estoq. {String(item?.qtd_estoque)}</Typography>
                                        {item.qtd && (
                                            <Typography sx={{ color: theme.palette.secondary.dark, fontSize: 12 }}>{`Na compra de ${item.qtd}un R$${numberToReal(item.qtdPreco)}`}</Typography>
                                        )}
                                    </Box>
                                </Box>
                                {!isMobile && (
                                    <Box sx={{ display: 'flex', flex: 1 }}>
                                        <CounterField quantidade={String(item.quantidade)} handleDecrease={() => handleQuantidade(item, parseInt(item.quantidade) - 1)} handleIncrease={() => handleQuantidade(item, parseInt(item.quantidade) + 1)} />
                                        <IconButton aria-label="delete" size="small" sx={{ ml: 1 }} onClick={() => add(item)}>
                                            <DeleteIcon fontSize="small" color='error' />
                                        </IconButton>
                                    </Box>
                                )}
                                <Box sx={{ display: 'flex', gap: 1, flex: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {isMobile && (
                                        <Box sx={{ display: 'flex', flex: 1 }}>
                                            <CounterField quantidade={String(item.quantidade)} handleDecrease={() => handleQuantidade(item, parseInt(item.quantidade) - 1)} handleIncrease={() => handleQuantidade(item, parseInt(item.quantidade) + 1)} />
                                            <IconButton aria-label="delete" size="small" sx={{ ml: 1 }} onClick={() => add(item)}>
                                                <DeleteIcon fontSize="small" color='error' />
                                            </IconButton>
                                        </Box>
                                    )}
                                    <Typography sx={{ textDecoration: 'line-through', fontSize: 14, color: theme.palette.text.secondary }}>R${numberToReal(item?.vlr_venda * item?.quantidade)}</Typography>
                                    <Typography sx={{ fontSize: 18, color: theme.palette.secondary.dark }}>R${numberToReal(whatVlrFinal(item) * item?.quantidade)}</Typography>
                                </Box>
                            </Box>
                        ))}
                        {Array.isArray(calcCeps) && (
                            <Box sx={{ padding: 1, paddingLeft: 2, paddingRight: 2 }}>
                                <Divider />
                            </Box>
                        )}
                        {loadingCep &&
                            <Box sx={{ display: 'flex', gap: 2, padding: 2, justifyContent: 'center' }}>
                                {getIcon(25, theme.palette.secondary.main, 20, false, true)}
                                <Typography>Calculando frete</Typography>
                            </Box>
                        }
                        {hasControlado && (
                            <Alert severity="info">Esse pedido contém itens controlados. Só é permitido a retida na loja para estes itens.</Alert>
                        )}
                        {Array.isArray(calcCeps) && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Selecione o método de entrega</Typography>
                                {lojaFretes.length > 0 ?
                                    <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
                                        {lojaFretes.map((e, idx) => {
                                            return (
                                                <Box key={idx} sx={{ display: 'flex', gap: 2, padding: 1, border: theme.palette.divider, borderWidth: 1, minWidth: 220, borderStyle: 'solid', width: isMobile && '100%' }}>
                                                    <Radio
                                                        icon={<RadioButtonUncheckedIcon />}
                                                        checkedIcon={<CheckCircleIcon />}
                                                        checked={checkedLojaFrete?.frete === e}
                                                        onChange={() => changeCheckedFrete(e, e.userId)}
                                                    />
                                                    <Box>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{e?.tipo_nome}</Typography>
                                                        {e?.prazo && (
                                                            <Typography sx={{ fontSize: 14 }}>Entrega em {e?.prazo} dias úteis</Typography>
                                                        )}
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: 14, color: theme.palette.secondary.dark }}>R${e?.valor}</Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    : <Alert severity="error">Não foram encontrados métodos de entrega para essa loja.</Alert>}
                            </Box>
                        )}
                    </Box>
                );
            })}
            {isLoading && (
                <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                    {getIcon(25, theme.palette.secondary.main, 70, false, true)}
                    <Typography sx={{ fontSize: 20, color: theme.palette.text.primary }}>Calculando Valores</Typography>
                </Box>
            )}
            <ModalLoja visible={modLoja.visible} loja={modLoja.loja} dismiss={updateModLoja} />
        </Box>
    );
}
