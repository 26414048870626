import { Typography, Box, Divider, useTheme, useMediaQuery } from "@mui/material";
import { Container } from "../../components/container";
import { Menu } from "./components/menu";
import { Tela } from "./components/tela";



export function MinhaConta({ scrollToTop }) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const modulos = [
        {
            title: 'Histórico de compras',
            icon: 85
        },
        {
            title: 'Meus endereços',
            icon: 97
        },
        {
            title: 'Meus dados',
            icon: 33
        },
        {
            title: 'Alterar senha',
            icon: 24
        },
    ]

    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 1, height: '100%' }}>
                <Box sx={{ width: isMobile ? '100%' : '30%' }}>
                    <Menu modulos={modulos} />
                </Box>
                <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
                <Tela modulos={modulos} scrollToTop={scrollToTop} />
            </Box>
        </Container>
    )
}