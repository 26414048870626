import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { useContext, useEffect, useState } from "react";
import { Carrinho } from "../../contexts/carrinho";
import { ListProdutos } from "./components/listProdutos";
import { ResumoCompra } from "./components/resumo";
import { CadEndereco } from "./components/cadEndereco";
import { NovoEnd } from "./components/novoEnd";
import { FormasPagamento } from "./components/formasPagamento";
import { Cliente } from "../../contexts/cliente";
import { FBFADM } from "../../apis/FBFAdm";
import { whatVlrFinal } from "../../components/oneProduct";


export function FinalizarCompra({ scrollToTop }) {
    const theme = useTheme()
    const { data, getData } = useContext(Carrinho)
    const { enderecos, data: clientData } = useContext(Cliente)
    const [checkedFretes, setCheckedFretes] = useState([])
    const [cadEndereco, setCadEndereco] = useState(enderecos.length === 0 ? 0 : 2)
    const [selectEnd, setSelectEnd] = useState(0)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    function updateSelectedEnd(value) {
        setSelectEnd(value)
    }
    function changeCheckedFrete(frete, loja) {
        let newCk = [...checkedFretes]
        if (!loja) {
            newCk = frete
        }
        else {
            const findIndex = newCk.findIndex((e) => e.loja == loja)
            if (findIndex !== -1) {
                newCk[findIndex].frete = frete
            }
            else {
                newCk.push({
                    loja: loja,
                    frete: frete
                })
            }
        }
        setCheckedFretes(newCk)
    }
    async function finalizarcompra() {
        const body = {
            endereco: enderecos[selectEnd],
            pedidos: []
        }
        data.map((e) => {
            const findIndex = body.pedidos.findIndex((b) => b.loja == e.loja)
            const findFrete = checkedFretes.find((b) => b.loja == e.loja)
            if (findIndex === -1) {
                body.pedidos.push({
                    loja: e.loja,
                    frete: findFrete?.frete,
                    produtos: [{
                        cod_barra: e.cod_barra,
                        qtd_estoque: e.qtd_estoque,
                        quantidade: e.quantidade,
                        table: e.qtd ? 'proGr' : e.table,
                        vlr_venda: e.vlr_venda,
                        vlr_final: whatVlrFinal(e)
                    }]
                })
            } else {
                body.pedidos[findIndex].produtos.push({
                    cod_barra: e.cod_barra,
                    qtd_estoque: e.qtd_estoque,
                    quantidade: e.quantidade,
                    table: e.qtd ? 'proGr' : e.table,
                    vlr_venda: e.vlr_venda,
                    vlr_final: whatVlrFinal(e)
                })
            }
        })
        const realiza = await FBFADM('marketplace/private/realizaVenda', 'POST', body, clientData?.token)
    }
    function changePage(value) {
        scrollToTop()
        setCadEndereco(value ? value : 0)
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', gap: 2, width: isSmallScreen ? '100%' : '80%', alignItems: isSmallScreen && 'center' }}>
                    <Box sx={{ width: isMobile ? '100%' : isSmallScreen ? '80%' : '70%' }}>
                        {cadEndereco === 0 ?
                            <CadEndereco changeCadEndereco={changePage} updateSelectedEnd={updateSelectedEnd} selectEnd={selectEnd} /> :
                            cadEndereco === 1 ? <NovoEnd changeCadEndereco={changePage} /> : cadEndereco === 2 ?
                                <ListProdutos updateSelectedEnd={updateSelectedEnd} selectEnd={selectEnd} checkedFretes={checkedFretes} changeCheckedFrete={changeCheckedFrete} changeCadEndereco={() => changePage(0)} /> : cadEndereco === 3 && <FormasPagamento changeCadEndereco={changePage} />
                        }
                    </Box>
                    <Box sx={{ width: isSmallScreen ? '100%' : '30%' }}>
                        <ResumoCompra checkedFretes={checkedFretes} changeCadEndereco={changePage} cadEndereco={cadEndereco} finalizarcompra={finalizarcompra} />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}