import { Alert, Box, Button, Typography, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { AuthTextField } from "../../components/textFields";
import { useContext, useState } from "react";
import { TestaEMAIL, TestaSenha } from "../../components/validaCampo";
import { FBFADM } from "../../apis/FBFAdm";
import { Loja } from "../../contexts/loja";



export function AuthLoja({ }) {
    const theme = useTheme()
    const { data: lojaData, updateLoja } = useContext(Loja)
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [error, setError] = useState('')
    var CryptoJS = require("crypto-js");
    function handleEmail(value) {
        setEmail(value)
    }
    function handleSenha(value) {
        setSenha(value)
    }

    async function handleSubmit() {
        const emailIsValid = TestaEMAIL(email)
        if (!emailIsValid) {
            setError('E-mail inválido preenchido')
            return
        }
        if (!senha) {
            setError('Insira uma senha pra prosseguir')
            return
        }
        setError('')
        const get = await FBFADM('marketplace/public/loginLoja', 'POST', { email: email, senha: CryptoJS.MD5(senha).toString() })
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao realizar o login.'
            setError(message)
            return
        }
        updateLoja(get?.apiReturn?.apiReturn)
    }
    return (
        <Container>
            <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 5, gap: 2, width: 300, border: 1, borderRadius: 3, borderColor: theme.palette.divider, padding: 3 }}>
                    <Box>
                        <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>Olá, Franqueado.</Typography>
                        <Typography>Faça seu login para continuar</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box>
                            <Typography>E-mail</Typography>
                            <AuthTextField size={'small'} fullWidth value={email} changeText={handleEmail} />
                        </Box>
                        <Box>
                            <Typography>Senha</Typography>
                            <AuthTextField size={'small'} fullWidth value={senha} changeText={handleSenha} type={'password'} />
                        </Box>
                    </Box>
                    <Button color="secondary" variant='contained' size='large' sx={{ fontWeight: 'bold', textTransform: 'none' }} onClick={handleSubmit}>Entrar</Button>
                    {error && (
                        <Alert severity='error'>{error}</Alert>
                    )}
                </Box>
            </Box>
        </Container>
    )
}