import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Inicio } from "./inicio";
import { Pedidos } from "./pedidos";
import { Pedido } from "./pedido";
import { MinhasInfo } from "./minhasInfo";
import { ConfigEntrega } from "./configEntrega";



export function Tela({ modulos, scrollToTop }) {
    const [activeModulo, setActiveModulo] = useState(0)
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search);
    const urlMod = urlParams.get('mod')
    function changeCadEndereco() {
        navigate('/minha-conta?mod=1')
    }

    useEffect(() => {
        if (urlMod) {
            scrollToTop()
            setActiveModulo(urlMod)
        }
    }, [urlMod])
    return <ModToComp mod={activeModulo} modulos={modulos} changeCadEndereco={changeCadEndereco} scrollToTop={scrollToTop} />
}

function ModToComp({ mod, modulos, changeCadEndereco, scrollToTop }) {
    const mods = {
        0: <Inicio />,
        1: <Pedidos />,
        2: <MinhasInfo scrollToTop={scrollToTop} />,
        3: <ConfigEntrega scrollToTop={scrollToTop} />
    }
    if (mods.hasOwnProperty(mod)) return mods[mod]
    return <Typography>{modulos[mod]?.title}</Typography>
}