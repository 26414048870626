import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, IconButton, TextField, Paper, List, ListItem, ListItemText, useMediaQuery, useTheme, Typography, Avatar } from "@mui/material";
import { getIcon } from "../icons";
import { useDebounce } from '../debounce';
import { FBFADM } from '../../apis/FBFAdm';
import { Link, useNavigate } from 'react-router-dom';

export function Buscar({ value }) {
    const theme = useTheme();
    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const debouncedInputValue = useDebounce(inputValue, 800);
    const wrapperRef = useRef(null);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            navigateToBuscar()
        }
    };

    function navigateToBuscar() {
        navigate(`/buscar?nome=${inputValue}`)
        setSuggestions([])
        setShowSuggestions(false)
        setInputValue('')
    }
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    const handleSuggestionClick = (suggestion) => {
        setShowSuggestions(false);
    };
    const fetchSuggestions = useCallback(async (query) => {
        if (!query) return [];
        if (query.length < 3) return []
        try {
            const get = await FBFADM(`marketplace/public/buscar/nome=${query}&limit=5`, 'GET')
            const apiData = get?.apiReturn?.apiReturn
            if (get?.status === 200 && Array.isArray(apiData) && apiData.length > 0) {
                return apiData
            }
            return []
        } catch (error) {
            console.error("Erro ao buscar sugestões:", error);
            return [];
        }
    }, []);
    useEffect(() => {
        if (debouncedInputValue) {
            fetchSuggestions(debouncedInputValue).then((data) => {
                setSuggestions(data);
                setShowSuggestions(true);
            });
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }, [debouncedInputValue, fetchSuggestions]);
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Box ref={wrapperRef} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            <TextField
                placeholder="O que está procurando?"
                size="small"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                sx={{
                    width: '100%',
                    minWidth: 300,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.divider,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.divider,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.divider,
                        },
                    }
                }}
                InputProps={{
                    style: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    }
                }}
            />
            <IconButton onClick={() => navigate(`/buscar?nome=${inputValue}`)} sx={{ borderRadius: 0, background: theme.palette.primary.main, borderTopRightRadius: 3, borderBottomRightRadius: 3 }}>
                {getIcon(53, theme.palette.text.onPrimary)}
            </IconButton>
            {showSuggestions && suggestions.length > 0 && (
                <Paper
                    elevation={5}
                    sx={{
                        position: 'absolute',
                        top: '100%',
                        width: '100%',
                        maxHeight: 400,
                        background: theme.palette.background.default,
                        overflowY: 'auto',
                        zIndex: 100,
                        mt: 1,
                        borderRadius: 2,
                        boxShadow: theme.shadows[3],
                    }}
                >
                    <List>
                        {suggestions.map((suggestion, index) => (
                            <ListItem
                                component={Link}
                                to={`/produto?id=${suggestion.cod_barra}`}
                                key={index}
                                onClick={() => {
                                    setInputValue('')
                                    setShowSuggestions(false)
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    transition: 'background-color 0.3s',
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover,
                                    },
                                }}
                            >
                                <Avatar
                                    src={suggestion.image}
                                    alt={suggestion.nome}
                                    sx={{
                                        marginRight: 2,
                                        width: 50,
                                        height: 50,
                                        border: `1px solid ${theme.palette.divider}`,
                                    }}
                                />
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body1"
                                            sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                                        >
                                            {suggestion.nome}
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography variant="body2" color="text.secondary">
                                                {`R$ ${suggestion.vlr_final}`}
                                            </Typography>
                                            {suggestion?.marca &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {`Marca: ${suggestion?.marca}`}
                                                </Typography>
                                            }
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </Box>
    );
}
