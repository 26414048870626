import { Box, Button, Divider, TextField, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material'; // Importando ícones de expansão
import { useContext, useState } from "react";
import { Carrinho } from "../../../contexts/carrinho";
import { numberToReal } from "../../../components/masks";
import { whatVlrFinal } from "../../../components/oneProduct";

export function ResumoCompra({ checkedFretes, cadEndereco, changeCadEndereco, finalizarcompra }) {
    const { data: carrinhoData } = useContext(Carrinho);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [isExpanded, setIsExpanded] = useState(false);
    const custoProdutos = carrinhoData.reduce((acc, item) => acc + (parseFloat(item.vlr_venda) * item.quantidade), 0);
    const custoFinal = carrinhoData.reduce((acc, item) => acc + (parseFloat(whatVlrFinal(item)) * item.quantidade), 0);
    const desconto = Math.abs(((custoFinal - custoProdutos) / custoProdutos) * 100).toFixed(2);
    const totalLojas = new Set(carrinhoData.map(item => item.loja)).size
    checkedFretes = checkedFretes.filter((e) => {
        const find = carrinhoData.findIndex((b) => b.loja === e.loja)
        if (find !== -1) return true
    })
    let custoFrete = checkedFretes.length > 0 ? checkedFretes.reduce((acc, item) => {
        if (item.frete === false) return false;
        const findUser = carrinhoData.find((e) => e.loja == item.loja)
        if (!findUser) return acc
        const valor = typeof item?.frete?.valor === 'string' ? item?.frete?.valor.replace(',', '.') : item?.frete?.valor;
        return acc + parseFloat(valor);
    }, 0) : 0;
    const freteValid = ![null, undefined, ''].includes(custoFrete) && totalLojas === checkedFretes.length && true;
    custoFrete = custoFrete ? custoFrete : 0;
    const subtotal = custoFinal + custoFrete;
    const total = subtotal;
    const buttonText = [0, 1, 2].includes(cadEndereco) ? 'Confirmar Entrega' : 'Finalizar Pedido'
    function handleButton() {
        if (buttonText === 'Confirmar Entrega') {
            changeCadEndereco(3)
            return
        }
        finalizarcompra()
    }
    return (
        <Box>
            {isSmallScreen ? (
                <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: theme.palette.background.default, borderTop: 1, borderColor: theme.palette.divider, padding: 2, zIndex: 1000, boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Total</Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>R$ {numberToReal(total)}</Typography>
                        </Box>
                        <Button
                            disabled={checkedFretes.length === 0 || !freteValid}
                            variant="contained"
                            size='large'
                            onClick={handleButton}
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'none',
                                background: theme.palette.tertiary.main,
                                paddingX: 4,
                                width: 200,
                                fontSize: isSmallScreen && 13
                            }}
                        >
                            {buttonText}
                        </Button>
                    </Box>
                    <IconButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        sx={{ position: 'absolute', top: -20, right: 0, background: theme.palette.background.default, '&:hover': { background: theme.palette.background.default } }}
                    >
                        {!isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>

                    {isExpanded && (
                        <Box sx={{ paddingTop: 2 }}>
                            <Typography variant="h6" sx={{ mt: 1 }}>Resumo da compra</Typography>
                            <Typography variant="body2" color="textSecondary">Comprando de {totalLojas} loja(s).</Typography>
                            <Divider sx={{ my: 1 }} />
                            {[
                                { label: 'Custo dos Produtos', value: custoProdutos.toFixed(2) },
                                { label: 'Desconto', value: desconto },
                                { label: 'Custo c/ desconto', value: custoFinal.toFixed(2) },
                                { label: 'Custo do frete', value: custoFrete.toFixed(2) },
                                { label: 'Subtotal', value: subtotal.toFixed(2) },
                            ].map(({ label, value }) => (
                                <Box key={label} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'regular' }}>{label}</Typography>
                                    <Typography>{label === 'Desconto' ? `${numberToReal(value)}%` : `R$ ${numberToReal(value)}`}</Typography>
                                </Box>
                            ))}
                            <Divider sx={{ my: 1 }} />
                            <Typography variant="body2" sx={{ mb: 1 }}>Possui um cupom de desconto?</Typography>
                            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                                <TextField placeholder="Insira aqui seu cupom" fullWidth size="small" />
                                <Button variant="contained" sx={{ fontWeight: 'bold', textTransform: 'none', background: theme.palette.secondary.main, minWidth: 100 }}>Verificar</Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', border: 1, borderColor: theme.palette.divider, padding: 2, gap: 2 }}>
                    <Typography variant="h6" sx={{ mt: 1 }}>Resumo da compra</Typography>
                    <Typography variant="body2" color="textSecondary">Comprando de {new Set(carrinhoData.map(item => item.loja)).size} loja(s).</Typography>
                    <Divider sx={{ my: 1 }} />
                    {[
                        { label: 'Custo dos Produtos', value: custoProdutos.toFixed(2) },
                        { label: 'Desconto', value: desconto },
                        { label: 'Custo c/ desconto', value: custoFinal.toFixed(2) },
                        { label: 'Custo do frete', value: custoFrete.toFixed(2) },
                        { label: 'Subtotal', value: subtotal.toFixed(2) },
                    ].map(({ label, value }) => (
                        <Box key={label} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                            <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'regular' }}>{label}</Typography>
                            <Typography>{label === 'Desconto' ? `${numberToReal(value)}%` : `R$ ${numberToReal(value)}`}</Typography>
                        </Box>
                    ))}
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="h6">Total</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>R$ {numberToReal(total)}</Typography>
                    </Box>

                    <Button disabled={checkedFretes.length === 0 || !freteValid} variant="contained" size='large' fullWidth sx={{ mb: 2, fontWeight: 'bold', fontSize: 20, textTransform: 'none', background: buttonText === 'Confirmar Entrega' ? theme.palette.primary.main : theme.palette.tertiary.main }} onClick={handleButton}>{buttonText}</Button>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" sx={{ mb: 1 }}>Possui um cupom de desconto?</Typography>
                    <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <TextField placeholder="Insira aqui seu cupom" fullWidth size="small" />
                        <Button variant="contained" sx={{ fontWeight: 'bold', textTransform: 'none', background: theme.palette.secondary.main, minWidth: 100 }}>Verificar</Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
