import { Badge, Box, Button, Radio, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Cliente } from "../../../contexts/cliente";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { cepMask } from "../../../components/masks";
import { getIcon } from "../../../components/icons";





export function CadEndereco({ changeCadEndereco, updateSelectedEnd, selectEnd }) {
    const { enderecos, deleteEndereco } = useContext(Cliente);
    const haveEnd = Array.isArray(enderecos) && enderecos.length > 0
    const [isLoading, setIsLoading] = useState(false)
    const [mainError, setMainError] = useState('')
    const theme = useTheme()

    async function delEndereco(id) {
        setIsLoading(true)
        const tryDel = await deleteEndereco(id)
        if (tryDel !== true) {
            setMainError(tryDel)
        } else {
            setMainError('')
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 300)
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>Confirmar endereço</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button onClick={() => changeCadEndereco(2)} sx={{ textTransform: 'none', color: theme.palette.secondary.main }}>
                        {getIcon(96, theme.palette.secondary.main)}
                        Voltar ao carrinho
                    </Button>
                </Box>
            </Box>
            <Box sx={{ border: 1, borderColor: theme.palette.divider, padding: 3, display: 'flex', flexDirection: 'column', gap: 5 }}>
                {haveEnd ? (
                    <>
                        <Typography sx={{ fontSize: 20 }}>Selecione um dos endereços cadastrados ou cadastre um novo endereço</Typography>
                        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
                            {enderecos.map((e, idx) => {
                                return (
                                    <Box key={idx} sx={{ display: 'flex', gap: 2, padding: 1, border: theme.palette.divider, borderWidth: 1, minWidth: 220, borderStyle: 'solid' }}>
                                        <Radio
                                            icon={<RadioButtonUncheckedIcon />}
                                            checkedIcon={<CheckCircleIcon />}
                                            checked={selectEnd === idx}
                                            onChange={() => updateSelectedEnd(idx)}
                                            sx={{ '&:hover': { background: 'transparent' } }}
                                        />
                                        <Box>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{e?.rua}</Typography>
                                            <Typography sx={{ fontSize: 14 }}>{`CEP ${cepMask(e?.cep)} - ${e.cidade} - ${e.estado}`}</Typography>
                                            <Typography sx={{ fontSize: 14 }}>{`Bairro ${e?.bairro}`}</Typography>
                                            {e?.complemento && (
                                                <Typography sx={{ fontSize: 14 }}>{e.complemento}</Typography>
                                            )}
                                            <Button size='small' color="tertiary" sx={{ textTransform: 'none' }} onClick={() => delEndereco(e?.id)}>Remover</Button>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </>
                ) : <Typography>Você não possui nenhum endereço cadastrado</Typography>}
                {mainError && (
                    <Typography color='error' sx={{ fontSize: 12 }}>{mainError}</Typography>
                )}
            </Box>
            <Box sx={{ border: 1, borderColor: theme.palette.divider, padding: 3, display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Typography sx={{ fontSize: 20 }}>Quer cadastrar um novo endereço?</Typography>
                <Button variant='outlined' size='large' color="secondary" sx={{ maxWidth: 300, fontWeight: 'bold', textTransform: 'none' }} onClick={() => changeCadEndereco(1)}>
                    Cadastrar novo endereço
                </Button>
            </Box>
            <Button disabled={!haveEnd || !enderecos[selectEnd]} variant='contained' size='large' color="secondary" sx={{ maxWidth: 300, fontWeight: 'bold', textTransform: 'none' }} onClick={() => changeCadEndereco(2)}>
                Confirmar endereço
            </Button>
            {isLoading && (
                <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                    {getIcon(25, theme.palette.secondary.main, 70, false, true)}
                    <Typography sx={{ fontSize: 20, color: theme.palette.text.primary }}>Excluindo endereço</Typography>
                </Box>
            )}
        </Box >
    )
}