import { Badge, Box, Button, Collapse, Divider, IconButton, Popover, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { FBFADM } from "../apis/FBFAdm";
import { Buscar } from "./header/buscar";
import { ButtonsHeader } from "./header/buttons";
import { getIcon } from "./icons";
import { Container } from "./container";
import useWindowDimensions from "./useWindowDimensions";
import { DrawerHeader } from "./header/drawer";
import { Link } from "react-router-dom";
import { Carrinho } from "../contexts/carrinho";

function ButtonBuscar({ funcOpen, theme }) {
    return (
        <Button sx={{ textTransform: 'none' }} onClick={funcOpen}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: theme.palette.secondary.main, padding: 1.2, borderRadius: 5, height: 15, width: 15 }}>
                    {getIcon(1, theme.palette.text.onPrimary, 20)}
                </Box>
            </Box>
        </Button>
    );
}
function Logo() {
    const theme = useTheme()
    return (
        <Box component={Link} to='/'>
            <img src={theme.palette.logo} alt="Logo-brasil-poupa-lar" style={{ width: 150, height: 50, objectFit: 'contain' }} />
        </Box>
    )
}
function MegaMenu({ categs, groups, anchorEl, isOpen, handleClose }) {
    const theme = useTheme();
    const isSmallScreen = useWindowDimensions().width < 1500
    const [control, setControl] = useState(false)
    function close() {
        setControl(false)
        handleClose();
    }
    return useMemo(() => {
        const itemsPerColumn = 15;
        const columns = [];
        let currentColumn = [];
        let currentLineCount = 0;

        categs.forEach((category, categoryIndex) => {
            const isColumnStart = currentLineCount % itemsPerColumn;
            if (currentLineCount + 1 > itemsPerColumn) {
                columns.push(currentColumn);
                currentColumn = [];
                currentLineCount = 0;
            }
            currentColumn.push({ type: 'category', name: category.categ_nome, id: category.categ_id, marginTop: isColumnStart ? 3 : 0 });
            currentLineCount++;
            category.deptos.forEach((depto) => {
                if (currentLineCount + 1 > itemsPerColumn) {
                    columns.push(currentColumn);
                    currentColumn = [];
                    currentLineCount = 0;
                }
                currentColumn.push({ type: 'depto', name: depto.depto_nome, id: depto.depto_id });
                currentLineCount++;
            });
        });
        if (currentColumn.length) {
            columns.push(currentColumn);
        }
        return (
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={close}
                onMouseLeave={close}
                onMouseEnter={() => setControl(true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    display: 'flex',
                    padding: 2,
                    zIndex: 2,
                }}
                PaperProps={{
                    style: {
                        marginTop: '30px'
                    },
                    onMouseLeave: close,
                }}
            >
                <Box sx={{
                    display: 'flex', flexWrap: 'nowrap', gap: 3, maxWidth: '100%', padding: 2, background: theme.palette.secondary.main,
                }}>
                    {columns.map((column, index) => (
                        <Box key={index} sx={{
                            display: 'flex', flexDirection: 'column', gap: 1,
                        }}>
                            {column.map((item, itemIndex) => (
                                <Typography
                                    component={Link}
                                    to={`/buscar?${item.type === 'category' ? 'categoria' : 'depto'}=${item.id}`}
                                    key={itemIndex}
                                    sx={{ marginTop: item.type === 'category' ? item.marginTop : 0, color: theme.palette.text.onPrimary, fontWeight: item.type === 'category' ? 'bold' : 'normal', marginLeft: item.type === 'depto' ? 2 : 0, textDecoration: 'none' }}
                                >
                                    {item.name}
                                </Typography>
                            ))}
                        </Box>
                    ))}
                </Box>
            </Popover>
        );
    }, [categs, isOpen, anchorEl, handleClose, isSmallScreen]);
}
export function Header({ }) {
    const theme = useTheme();
    const { height: screenHeight, width: screenWidth } = useWindowDimensions()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [opBuscar, setOpBuscar] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [classEcom, setClassEcom] = useState([]);
    const [menuOpen, setMenuOpen] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const carrinho = useContext(Carrinho)
    const handleCart = carrinho.handleCart
    const carrinhoLength = carrinho?.data?.length
    function handleDrawer(visible) {
        setDrawerVisible(visible);
    }
    function handlePopoverOpen(event, id) {
        setAnchorEl(event.currentTarget);
        setMenuOpen(id);
    }
    function handlePopoverClose() {
        setAnchorEl(null);
        setMenuOpen(null);
    }
    async function getClass() {
        const get = await FBFADM('marketplace/public/getMenu', 'GET', null, null);
        if (Array.isArray(get?.apiReturn?.apiReturn)) {
            setClassEcom(get?.apiReturn?.apiReturn);
        }
    }
    useEffect(() => {
        getClass();
    }, []);

    return (
        <Box
            sx={{
                width: isMobile ? '100%' : '100%',
                position: 'fixed',
                top: 0,
                zIndex: 10,
                backgroundColor: theme.palette.background.default
            }}
            onMouseLeave={handlePopoverClose}
        >
            {/* <Box sx={{padding: 2, background: theme.palette.primary.main}}></Box> */}
            <Box sx={{ paddingTop: 2, paddingBottom: 2, borderBottom: 1, paddingRight: screenWidth < 1600 ? 5 : 2, borderColor: theme.palette.divider }}>
                <Container overflow={'visible'}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2, flexDirection: isSmallScreen && 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: isMobile && 'center', width: '100%' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {isSmallScreen && (
                                        <IconButton onClick={() => handleDrawer(true)}>
                                            {getIcon(40)}
                                        </IconButton>
                                    )}
                                    {!isMobile && <Logo />}
                                </Box>
                                {isMobile && <Logo />}
                                {isMobile && (
                                    <Badge
                                        badgeContent={carrinhoLength && String(carrinhoLength)}
                                        color='warning'
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                color: 'white',
                                                backgroundColor: theme.palette.warning.main,
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                            <IconButton sx={{ background: theme.palette.secondary.main, width: 40, height: 40, borderRadius: 20 }} onClick={handleCart}>
                                                {getIcon(85, theme.palette.text.onPrimary)}
                                            </IconButton>
                                        </Box>
                                    </Badge>
                                )}
                                {!isMobile && isSmallScreen &&
                                    <Box sx={{ display: 'flex' }}>
                                        <ButtonsHeader handleCart={handleCart} />
                                    </Box>
                                }
                            </Box>
                            <Buscar />
                            {!isSmallScreen && <ButtonsHeader handleCart={handleCart} />}
                        </Box>
                        <Collapse in={!isSmallScreen}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 20 }}>
                                <Button size='small' onClick={() => handleDrawer(true)} sx={{ textTransform: 'none', textAlign: 'left' }}>
                                    <Typography
                                        sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
                                    >
                                        Todas as Categorias
                                    </Typography>
                                </Button>
                                {classEcom.length > 0 && classEcom.map((e, idx) => {
                                    if (!e.destaque) return;
                                    return (
                                        <Box key={idx} onMouseEnter={(event) => handlePopoverOpen(event, idx)} onMouseLeave={handlePopoverClose} sx={{ zIndex: 10 }}>
                                            <Typography sx={{ color: theme.palette.text.primary, fontSize: 12, '&:hover': { cursor: 'pointer' } }}>{e.grupo_nome}</Typography>
                                            <MegaMenu
                                                categs={e.categs}
                                                anchorEl={anchorEl}
                                                isOpen={menuOpen === idx}
                                                handleClose={handlePopoverClose}
                                            />
                                        </Box>
                                    );
                                })}
                                <Button sx={{ background: theme.palette.secondary.main, borderRadius: 1, borderTopLeftRadius: 15, '&:hover': { background: theme.palette.secondary.light } }}>
                                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.onPrimary }}>+ OFERTAS</Typography>
                                </Button>
                            </Box>
                        </Collapse>
                    </Box>
                </Container>
                <DrawerHeader open={drawerVisible} closeDrawer={() => handleDrawer(false)} classEcom={classEcom} />
            </Box>
        </Box>
    );
}