import { Alert, Box, Button, Collapse, Menu, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState, Fragment } from "react";
import { getIcon } from "../../../components/icons";
import { FBFADM } from "../../../apis/FBFAdm";
import { Loja } from "../../../contexts/loja";
import { cepMask, numberToReal, onlyInteger, onlyString, onlyStringEEspaco, realMask } from "../../../components/masks";
import { BoxHover } from "../../../components/boxHover";
import { AuthTextField } from "../../../components/textFields";
import { containsCaracter, containsNumber } from "../../../components/validaCampo";

export function ConfigEntrega({ scrollToTop }) {
    const theme = useTheme();
    const [errorMessage, setError] = useState('');
    const [sucessMessage, setSucess] = useState('');
    const [loading, setLoading] = useState(false);
    const { data: lojaData } = useContext(Loja);
    const [data, setData] = useState(null);
    const [collAtive, setCollAtive] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const newEntregaInit = {
        tipo: '1',
        valor: ''
    };
    const [newEntrega, setNewEntrega] = useState(newEntregaInit);

    async function getData() {
        setLoading(true);
        const get = await FBFADM('marketplace/loja/getConfigEntrega', 'GET', null, lojaData?.token);
        setLoading(false);
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os dados.';
            setError(message);
            return;
        }
        setError('');
        setData(get?.apiReturn?.apiReturn);
    }

    function handleCollAtive(value) {
        if (value === collAtive) {
            setCollAtive(null);
        } else {
            setCollAtive(value);
        }
    }

    function removeEntrega(idx) {
        let newD = { ...data };
        newD.entregas = newD.entregas.filter((_, i) => idx !== i);
        setData(newD);
    }

    function pushNewCondition(idx) {
        const newD = { ...data };
        newD.entregas[idx].condicoes.push({ condicao_id: '', condicao: 1, condicao_valor: '' });
        setData(newD);
        setCollAtive(idx);
    }

    function removeCondition(idxEntrega, idxCondition) {
        let newD = { ...data };
        newD.entregas[idxEntrega].condicoes = newD.entregas[idxEntrega].condicoes.filter((_, idx) => idx !== idxCondition);
        setData(newD);
    }

    function changeConditionSelect(value, idxEntrega, idxCondition) {
        const newD = { ...data };
        newD.entregas[idxEntrega].condicoes[idxCondition].condicao = value;
        newD.entregas[idxEntrega].condicoes[idxCondition].condicao_valor = '';
        setData(newD);
    }

    function changeConditionValue(value, idxEntrega, idxCondition) {
        const newD = { ...data };
        newD.entregas[idxEntrega].condicoes[idxCondition].condicao_valor = value;
        setData(newD);
    }

    function changeNewEntrega(value, type) {
        const newD = { ...newEntrega };
        value = type === 'tipo' ? value : realMask(value);
        newD[type] = value;
        setNewEntrega(newD);
    }

    function saveNewEntrega() {
        const newD = { ...data };
        if (newEntrega.tipo === 4 && (!newEntrega.valor || newEntrega.valor === '00.00')) {
            setError('O campo valor não pode ser vazio.');
            scrollToTop();
            return;
        }
        const validaCorreios = newD.entregas.find((e) => e.entrega_tipo == newEntrega.tipo)
        if (validaCorreios && newEntrega.tipo !== 4) {
            setError('Já existe uma entrega cadastrada para esse tipo.')
            return
        }
        newD.entregas.unshift({
            entrega_id: 'novo',
            entrega_tipo: parseInt(newEntrega.tipo),
            entrega_valor: newEntrega.valor.replace(',', '.'),
            condicoes: []
        });
        setError('');
        setNewEntrega(newEntregaInit);
        setData(newD);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    async function saveEntregas() {
        if (!Array.isArray(data?.entregas)) return
        let erro
        data.entregas.map((e) => {
            if (!Array.isArray(e.condicoes)) return
            e.condicoes.map((b) => {
                if (b.condicao === 3 && (containsNumber(b.condicao_valor) || b.condicao_valor.length > 2)) {
                    erro = 'Verifique a condição do tipo estado.'
                }
                if (b.condicao === 4 && containsNumber(b.condicao_valor)) {
                    erro = 'Verifique a condição do tipo estado.'
                }
                if (!b.condicao_valor) {
                    erro = 'O valor da condição deve ser preenchido.'
                }
            })
        })
        if (erro) {
            setError(erro)
            return
        } else setError('')
        setLoading(true)
        const save = await FBFADM('marketplace/loja/saveConfigEntrega', 'POST', data?.entregas, lojaData?.token)
        setLoading(false)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao buscar os dados.';
            setError(message);
            return;
        }
        setError('')
        setData(save?.apiReturn?.apiReturn);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const exemplo = {
        1: '89665000 - CEP sem separação',
        2: '199.90 - Valor com decimal separado por ponto.',
        3: 'Sc - Uf do estado com a primeira letra maiúscula e segunda letra minúscula',
        4: '4 - Número inteiro com a quantidade de itens no carrinho para contar o valor',
        5: 'Centro - Bairro que o cliente irá preencher em seu endereço.'
    };
    const condicoesMask = {
        1: cepMask,
        2: realMask,
        3: onlyString,
        4: onlyInteger,
        5: (value) => value
    }
    const condicoesPlaceHolder = {
        1: '89665-000',
        2: '199,90',
        3: 'Sc',
        4: '5',
        5: 'Centro'
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            {errorMessage && (
                <Box sx={{ padding: 2, paddingRight: 0 }}>
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                </Box>
            )}
            {sucessMessage && (
                <Box sx={{ padding: 2, paddingRight: 0 }}>
                    <Alert severity='success'>
                        {sucessMessage}
                    </Alert>
                </Box>
            )}
            <Box sx={{ paddingLeft: 2, display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, color: theme.palette.secondary.dark }}>
                    Configurações de entrega
                </Typography>
                {loading && (
                    <Alert severity='info' icon={getIcon(25, theme.palette.secondary.main, 15, null, true)} sx={{ fontSize: 12 }}>
                        Carregando
                    </Alert>
                )}
            </Box>
            <BoxHover width={'auto'} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 18, color: theme.palette.secondary.dark }}>Adicionar nova entrega</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 2 }}>
                        <Box>
                            <Typography>Tipo</Typography>
                            <Select sx={{ width: 200 }} size="small" value={newEntrega.tipo} onChange={(value) => changeNewEntrega(value.target.value, 'tipo')}>
                                {Array.isArray(data?.tipos) && data.tipos.map((e, idx) => (
                                    <MenuItem key={idx} value={e.id}>{e?.nome}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box>
                            <Typography>Valor</Typography>
                            <AuthTextField disabled={newEntrega.tipo !== 4} value={newEntrega.valor} size={'small'} placeHolder={'0,00'} changeText={(value) => changeNewEntrega(value, 'valor')} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Button variant='contained' color="secondary" sx={{ textTransform: 'none' }} onClick={saveNewEntrega}>Adicionar</Button>
                        <Button variant='contained' color="error" sx={{ textTransform: 'none' }} onClick={saveEntregas} >Salvar entregas</Button>
                    </Box>
                </Box>
            </BoxHover>
            <BoxHover width={'auto'} padding={2}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell align="center">Condições</TableCell>
                                <TableCell align="center">Nova Condição</TableCell>
                                <TableCell align="center">Excluir</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(data?.entregas) && data.entregas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e, idx) => {
                                const findEntrega = data.tipos.find((b) => b.id === e.entrega_tipo);
                                const valor = e.entrega_tipo !== 4 ? 'Não utiliza' : String(numberToReal(parseFloat(e.entrega_valor).toFixed(2)));
                                const arrow = collAtive === idx ? 37 : 36;
                                return (
                                    <Fragment key={idx}>
                                        <TableRow>
                                            <TableCell>{e.entrega_id}</TableCell>
                                            <TableCell>{findEntrega?.nome}</TableCell>
                                            <TableCell>{valor}</TableCell>
                                            <TableCell align="center" sx={{ width: '15%' }} onClick={() => handleCollAtive(idx)}>
                                                {getIcon(arrow, theme.palette.secondary.main)}
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: '15%' }} onClick={() => pushNewCondition(idx)}>
                                                {getIcon(55, theme.palette.secondary.main)}
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: '15%' }} onClick={() => removeEntrega(idx)}>
                                                {getIcon(28, theme.palette.tertiary.main)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Collapse in={collAtive === idx} timeout="auto" unmountOnExit>
                                                    <Table size="small" aria-label="condições" sx={{ border: 1, borderColor: '#eee' }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>ID</TableCell>
                                                                <TableCell>Descrição</TableCell>
                                                                <TableCell>Valor</TableCell>
                                                                <TableCell>Exemplo</TableCell>
                                                                <TableCell align="center">Excluir</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {Array.isArray(e.condicoes) && e.condicoes.map((condicao, i) => {
                                                                const mask = condicoesMask[condicao.condicao]
                                                                return (
                                                                    <TableRow key={i}>
                                                                        <TableCell width={10}>{condicao.condicao_id}</TableCell>
                                                                        <TableCell>
                                                                            <Select value={condicao.condicao} sx={{ width: 200 }} size="small" onChange={(value) => changeConditionSelect(value.target.value, idx, i)}>
                                                                                {data.condicoes.map((b, v) => (
                                                                                    <MenuItem key={v} value={b.id}>{b.nome}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </TableCell>
                                                                        <TableCell><AuthTextField size='small' value={mask(condicao.condicao_valor)} changeText={(value) => changeConditionValue(value, idx, i)} placeHolder={condicoesPlaceHolder[condicao.condicao]} /></TableCell>
                                                                        <TableCell align="center" sx={{ width: '10%' }}>
                                                                            <Tooltip title={exemplo[condicao.condicao]}>
                                                                                {getIcon(103, theme.palette.secondary.dark)}
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                        <TableCell align="center" sx={{ width: '10%' }} onClick={() => removeCondition(idx, i)}>
                                                                            {getIcon(28, theme.palette.tertiary.main)}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={data?.entregas?.length || 0}
                    page={page}
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página"
                />
            </BoxHover>
        </Box>
    );
}
