import { Box, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Swiper, SwiperSlide } from 'swiper/react';
import { OneProduct } from "../../../components/oneProduct";
import { BoxHover } from "../../../components/boxHover";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css'
import { useState } from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../components/useWindowDimensions";

export function Sections({ data }) {
    const sections = data?.sections
    const theme = useTheme()
    return (
        <Box id={'home-sections'} sx={{ width: '100%', minHeight: 60 }}>
            {sections ?
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {sections.map((e, idx) => {
                        const subtitle = e.tipo === 1 ? 'Mais produtos' : e.tipo === 3 ? 'Ver Marcas' : ''
                        return (
                            <Box key={idx}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 5 }}>
                                    <Typography sx={{ fontSize: 25, color: '#000', fontWeight: 'bold', textTransform: 'uppercase' }}>{e.nome}</Typography>
                                </Box>
                                {[1, 3].includes(e.tipo) && (
                                    <Slide produtos={e.data} tipo={e.tipo} idx={idx} />
                                )}
                            </Box>
                        )
                    })}
                </Box>
                : <Skeleton width={'100%'} height={300} variant='rectangular' />
            }
        </Box>
    )
}

function Slide({ produtos, tipo, idx }) {
    const theme = useTheme()
    const width = useWindowDimensions().width
    const [isHovered, setIsHovered] = useState(null)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const slidesPerView = {
        400: 1,
        600: 2,
        800: 3,
        1000: 4,
        1300: 5,
        1750: 5,
    };
    let slidesVisible = slidesPerView[400];
    if (isUpLg) slidesVisible = slidesPerView[1750];
    else if (isUpMd) slidesVisible = slidesPerView[1300];
    else if (isUpSm) slidesVisible = slidesPerView[1000];

    const showNavigation = produtos.length > slidesVisible;
    return (
        <Box onMouseEnter={() => setIsHovered(idx)} onMouseLeave={() => setIsHovered(null)} sx={{ position: 'relative', width: '100%' }}>
            <Swiper
                slidesPerView={1}
                breakpoints={{
                    300: { slidesPerView: 2 },
                    600: { slidesPerView: 2 },
                    850: { slidesPerView: 3 },
                    1170: { slidesPerView: 4 },
                    1370: { slidesPerView: 5 },
                    1600: { slidesPerView: 4 },
                    1750: { slidesPerView: 5 },
                }}
                navigation={{
                    nextEl: `.swiper-button-next-${idx}`,
                    prevEl: `.swiper-button-prev-${idx}`,
                }}
                loop={true}
                autoplay={tipo === 3 && {
                    delay: 2000,
                    pauseOnMouseEnter: true
                }}
                modules={[Pagination, Navigation, Autoplay]}
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden'
                }}
                spaceBetween={isMobile ? width < 400 ? 30 : 30 : 0}
                initialSlide={produtos.length / 2}
            >
                {produtos.map((e, idx) => {
                    if (!e.cod_barra && tipo === 1) return null;
                    return (
                        <SwiperSlide key={idx} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {tipo === 1 ? (
                                <OneProduct produto={e} margin={isMobile ? 0.1 : 0.5} width={isMobile ? '75%' : 200} isTiniPrice={isMobile} />
                            ) : (
                                <BoxHover margin={0.1} notShadow>
                                    <Link to={`/buscar?marca=${e.id}`}>
                                        <img src={e.image} style={{ objectFit: 'contain', marginTop: '10px', width: 100 }} alt="Product" />
                                    </Link>
                                </BoxHover>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            {showNavigation && tipo !== 3 && ['prev', 'next'].map((e, index) => {
                const right = index === 0 ? 'unset' : '-1%'
                const left = index === 1 ? 'unset' : '-1%'
                const isActive = isHovered === idx; // Verifica se o slide está sendo "hovered"
                return (
                    <Box
                        key={index}
                        className={`swiper-button-${e}-${idx}`}
                        onMouseEnter={() => setIsHovered(idx)}
                        onMouseLeave={() => setIsHovered(null)}
                        sx={{
                            position: 'absolute',
                            top: '40%',
                            left: left,
                            right: right,
                            transform: 'translateY(-40%)',
                            zIndex: 5,
                            cursor: 'pointer',
                            backgroundColor: theme.palette.primary.main,
                            opacity: isActive ? 1 : 0.5, // Mais transparente quando não está "hovered"
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '35px',
                            height: '35px',
                            color: theme.palette.text.onPrimary,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            transition: 'opacity 0.3s ease', // Suaviza a transição de opacidade
                            '&:hover': {
                                backgroundColor: theme.palette.primary.light,
                                opacity: 1, // Quando hover, opacidade completa
                            }
                        }}
                    >
                        <ArrowForwardIos sx={{ width: '20px', height: '23px', transform: index === 0 && 'rotate(180deg)' }} />
                    </Box>
                );
            })}
        </Box>
    )
}