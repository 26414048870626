import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function BreadCrumb({ mainParam }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/`} style={{ textDecoration: 'none' }}>
                <Typography color="text.primary">Início</Typography>
            </Link>
            <Typography color="text.primary">Buscar</Typography>
            <Typography color="text.primary">{mainParam}</Typography>
        </Breadcrumbs>
    );
}
