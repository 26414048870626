import React, { useState } from 'react';
import {
    Box, Button, Checkbox, Collapse, Typography, Skeleton,
    useTheme, Drawer, useMediaQuery, IconButton, Fab
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getIcon } from '../../../components/icons';

const nameToLabel = {
    grupos: 'Grupo',
    categorias: 'Categoria',
    deptos: 'Departamento',
    fabricantes: 'Fabricante',
    marcas: 'Marca',
    princ_ativos: 'Princípio Ativo'
};

const nameToParams = {
    grupos: 'grupo',
    categorias: 'categoria',
    deptos: 'depto',
    nome: 'nome',
    marcas: 'marca',
    fabricantes: 'fabricante',
    princ_ativos: 'princ_ativo'
};

const FilterItem = React.memo(({ filter, filterKey, checked, disabled, onChange }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={(event) => onChange(event, filter, nameToParams[filterKey])}
        />
        <Typography>{filter.nome}</Typography>
    </Box>
));
const FilterGroup = React.memo(({ filterKey, filters, ObjParams, closedParam, changeParamsUrl }) => (
    <Box key={filterKey}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 20, color: '#000', textTransform: 'none' }}>
            {nameToLabel[filterKey] || 'Outros'}
        </Typography>
        <Box sx={{
            maxHeight: 400, overflowY: 'scroll', overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                background: theme => theme.palette.background.default,
            },
            '&::-webkit-scrollbar-thumb': {
                background: theme => theme.palette.background.paper,
            }
        }}>
            {filters.map(filter => (
                <FilterItem
                    key={`${filterKey}-${filter.id}`}
                    filter={filter}
                    filterKey={filterKey}
                    checked={ObjParams[filterKey] == filter.id}
                    disabled={closedParam == nameToParams[filterKey]}
                    onChange={changeParamsUrl}
                />
            ))}
        </Box>
    </Box>
));

const FilterContent = ({ filters, ObjParams, closedParam, changeParamsUrl }) => {
    if (filters === null) return <LoadingSkelFilters />;
    if (filters === false) {
        return (
            <Box sx={{ display: 'flex', padding: 2, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Box sx={{ width: '50%' }}>
                    <Typography>Ops! Não foram encontrados filtros.</Typography>
                </Box>
            </Box>
        );
    }
    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 5, height: 300,
            '&::-webkit-scrollbar-thumb': {
                background: theme => theme.palette.secondary.main,
            }
        }}>
            {Object.keys(filters).map(filterKey => (
                <FilterGroup
                    key={filterKey}
                    filterKey={filterKey}
                    filters={filters[filterKey]}
                    ObjParams={ObjParams}
                    closedParam={closedParam}
                    changeParamsUrl={changeParamsUrl}
                />
            ))}
        </Box>
    );
};
export function Filters({ filters, ObjParams, changeParamsUrl, closedParam }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        setDrawerOpen(open);
    };
    return (
        <Box>
            {isSmallScreen ? (
                <>
                    <Fab color='secondary' onClick={toggleDrawer(true)} sx={{ position: 'absolute', left: '5%', bottom: '5%' }}>
                        {getIcon(95)}
                    </Fab>
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        sx={{ '& .MuiDrawer-paper': { width: isMobile ? '100%' : 300, background: theme.palette.background.default } }}
                    >
                        <Box
                            sx={{ width: '100%', height: '100%', overflowY: 'scroll', padding: 2, background: theme.palette.background.default }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            <IconButton onClick={toggleDrawer(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                                <CloseIcon />
                            </IconButton>
                            <FilterContent filters={filters} ObjParams={ObjParams} closedParam={closedParam} changeParamsUrl={changeParamsUrl} />
                        </Box>
                    </Drawer>
                </>
            ) : (
                <FilterContent filters={filters} ObjParams={ObjParams} closedParam={closedParam} changeParamsUrl={changeParamsUrl} />
            )}
        </Box>
    );
}
export function LoadingSkelFilters() {
    return (
        <Box sx={{ padding: 2 }}>
            <Skeleton variant="text" width={120} height={30} />
            {[...Array(3)].map((_, index) => (
                <Box key={index} sx={{ marginTop: 2 }}>
                    <Skeleton variant="text" width={100} height={20} />
                    <Skeleton variant="rectangular" width="100%" height={40} sx={{ marginTop: 1 }} />
                </Box>
            ))}
        </Box>
    );
}
