import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { useSearchParams, useNavigate, redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { FBFADM } from "../../apis/FBFAdm";
import { ListItens } from "./components/listItens";
import { Filters } from "./components/filters";
import { BreadCrumb } from "./components/breadcrumbs";



export function Buscar({ scrollToTop }) {
    const theme = useTheme()
    const [searchParams] = useSearchParams();
    const { id: productId, grupo, categoria: categoria, depto, nome, marca, fabricante, princ_ativo, page, limit } = Object.fromEntries(searchParams.entries());
    const history = useNavigate();
    const [closedParam, setClosedParam] = useState(null)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
    const ObjParams = {
        productId: productId,
        grupos: grupo,
        categorias: categoria,
        deptos: depto,
        nome: nome,
        marcas: marca,
        fabricantes: fabricante,
        princ_ativos: princ_ativo
    }
    const [data, setData] = useState(null)
    const [filters, setFilters] = useState(null)
    const [tamanho, setTamanho] = useState(0)
    const changeParamsUrl = (event, data, type) => {
        const isChecked = event.target.checked;
        const urlParams = new URLSearchParams(window.location.search);
        const hasParams = Array.from(urlParams.keys()).length > 0;
        if (!hasParams) {
            return;
        }
        if (isChecked) {
            urlParams.set(type, data.id);
        } else {
            urlParams.delete(type);
        }
        urlParams.set('page', 1)
        if (!urlParams.has('limit')) {
            urlParams.set('limit', 8);
        }
        history({ search: urlParams.toString() });
    };
    const changeLimit = (limit) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('limit', limit);
        urlParams.set('page', 1)
        history({ search: urlParams.toString() });
    }
    async function getProdutos(control) {
        if (data) {
            setData(null)
        }
        let searchParams = [];
        if (productId) searchParams.push(`id=${productId}`)
        if (grupo) searchParams.push(`grupo=${grupo}`)
        if (categoria) searchParams.push(`categoria=${categoria}`)
        if (depto) searchParams.push(`depto=${depto}`)
        if (nome) searchParams.push(`nome=${nome}`)
        if (marca) searchParams.push(`marca=${marca}`)
        if (fabricante) searchParams.push(`fabricante=${fabricante}`)
        if (princ_ativo) searchParams.push(`princ_ativo=${princ_ativo}`)
        if (page) searchParams.push(`page=${page}`)
        if (limit) searchParams.push(`limit=${(parseInt(limit) + 1).toString()}`)
        if (searchParams.length === 0) searchParams.push('all=all')
        const searchQuery = searchParams.join('&');
        const url = `marketplace/public/buscar/${searchQuery}`;
        try {
            const get = await FBFADM(url, 'GET', null, null);
            const apiData = get?.apiReturn?.apiReturn;
            if (get?.status === 200 && apiData) {
                setData(apiData);
                setTamanho(get?.apiReturn?.tamanho)
                setFilters(get?.apiReturn?.filters);
            } else {
                setData([])
                setFilters(false)
            }
        } catch (error) {
            setData([])
            setFilters(false)
        }
    }
    function handleChangePage(value) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', value)
        history({ search: urlParams.toString() })
        return null
    }
    useEffect(() => {
        if (!getClosedParam()) {
            scrollToTop()
            getProdutos(1)
        }
    }, [closedParam, productId, grupo, categoria, depto, nome, marca, fabricante, princ_ativo, page, limit])
    function getClosedParam(control) {
        const urlParams = new URLSearchParams(window.location.search);
        const firstParamKey = urlParams.keys().next().value;
        const firstParamValue = urlParams.get(firstParamKey);
        if (firstParamKey && firstParamValue) {
            if (firstParamKey === 'grupo') {
                setClosedParam('grupo');
            } else if (firstParamKey === 'categoria') {
                setClosedParam('categoria');
            } else if (firstParamKey === 'depto') {
                setClosedParam('depto');
            } else if (firstParamKey === 'marca') {
                setClosedParam('marca');
            } else if (firstParamKey === 'fabricante') {
                setClosedParam('fabricante');
            } else if (firstParamKey === 'princ_ativo') {
                setClosedParam('princ_ativo');
            } else if (firstParamKey === 'nome') {
                setClosedParam('grupo');
            } else {
                history('/errorPage?error=1');
                setClosedParam(null);
            }
            return;
        }
        return true;
    }

    return (
        <Container paddingRight={isMobile ? 0.1 : 2}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <BreadCrumb mainParam={closedParam ? closedParam : ''} />
                <Box sx={{ display: 'flex', gap: isMobile ? 0 : isSmallScreen ? 3 : 5 }} >
                    <Box sx={{ width: isSmallScreen ? '0%' : '18%' }}>
                        <Filters closedParam={closedParam} filters={filters} ObjParams={ObjParams} changeParamsUrl={changeParamsUrl} />
                    </Box>
                    <Box sx={{ width: isSmallScreen ? '100%' : '80%', position: 'relative', left: isMobile && -10 }}>
                        <ListItens produtos={data} tamanho={tamanho} handleChangePage={handleChangePage} changeLimit={changeLimit} />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}