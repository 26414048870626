import { Alert, Box, Typography, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FBFADM } from "../../../apis/FBFAdm";
import { Loja } from "../../../contexts/loja";
import { getIcon } from "../../../components/icons";
import { BoxHover } from "../../../components/boxHover";
import { numberToReal } from "../../../components/masks";
import { LineChart } from '@mui/x-charts/LineChart';
import moment from "moment";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TablePedidos } from "./components/tablePedidos";

export function Inicio() {
    const { data: lojaData } = useContext(Loja)
    const [data, setData] = useState(null)
    const [periodo, setPeriodo] = useState('mes')
    const [loading, setLoading] = useState(false)
    const theme = useTheme()

    async function getDashboard(init, end, isOther) {
        const adress = isOther ? `marketplace/loja/getDashboard/init=${init}&&end=${end}&&isOther=true` : 'marketplace/loja/getDashboard/isOther=false'
        const get = await FBFADM(adress, 'GET', null, lojaData?.token)
        setLoading(false)
        if (get.status !== 200) {
            setData(false)
            return
        }
        const apiData = get?.apiReturn?.apiReturn
        if (Array.isArray(apiData.vendasPorDia)) {
            let dataset
            if (apiData.vendasPorDia.length === 0) {
                dataset = [{ day: moment().format('DD/MM/YYYY'), total: 0 }]
            }
            else {
                dataset = apiData.vendasPorDia.map((venda) => ({
                    day: moment(venda.dia).format('DD/MM/YYYY'),
                    total: venda.total,
                }));
            }
            apiData.dataset = dataset;
        }
        if (isOther) {
            apiData.lastVendas = data?.lastVendas
        }
        setData(apiData)
    }

    useEffect(() => {
        setLoading(true)
        let init = moment().startOf('month').format('YYYY-MM-DD')
        let end = moment().endOf('month').format('YYYY-MM-DD')
        let isOther = false
        if (periodo === 'hoje') {
            init = moment().format('YYYY-MM-DD')
            end = moment().add(1, 'days').format('YYYY-MM-DD')
            isOther = true
        }
        if (periodo === 'ano') {
            init = moment().startOf('year').format('YYYY-MM-DD')
            end = moment().endOf('year').format('YYYY-MM-DD')
            isOther = true
        }
        if (periodo === 'all') {
            init = '2000-01-01'
            end = moment().format('YYYY-MM-DD')
            isOther = true
        }
        getDashboard(init, end, isOther)
    }, [periodo])

    const infos = data && [
        {
            title: `R$${numberToReal(data.totalVendas)}`,
            subtitle: 'Valor de vendas',
            icon: 100,
            color: 'warning'
        },
        {
            title: data.countVendas,
            subtitle: 'Quantidade de vendas',
            icon: 99,
            color: 'secondary'
        },
        {
            title: data.produtosVendidos,
            subtitle: 'Produtos vendidos',
            icon: 51,
            color: 'error'
        },
        {
            title: `R$${numberToReal(data.tkm)}`,
            subtitle: 'Ticket Médio',
            icon: 101,
            color: 'primary'
        },
    ]

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, color: theme.palette.secondary.dark, paddingLeft: 2 }}>Dashboard</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {loading && getIcon(25, theme.palette.info.main, 20, null, true)}
                    <Typography>Período</Typography>
                    <Select
                        value={periodo}
                        label="Período"
                        size="small"
                        sx={{ minWidth: 150 }}
                        onChange={(e) => setPeriodo(e.target.value)}
                    >
                        <MenuItem value={'hoje'}>Hoje</MenuItem>
                        <MenuItem value={'mes'}>Mês</MenuItem>
                        <MenuItem value={'ano'}>Ano</MenuItem>
                        <MenuItem value={'all'}>Todos</MenuItem>
                    </Select>
                </Box>
            </Box>
            {
                data === null ? (
                    <Alert severity='info' icon={getIcon(25, theme.palette.info.main, 20, null, true)}>Buscando dados</Alert>
                ) : data === false ? < Alert severity="error">Ocorreu um erro ao carregar os dados.</Alert>
                    :
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {infos.map((e, idx) => {
                                return (
                                    <BoxHover key={idx} width={'auto'} padding={2}>
                                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box>
                                                <Typography sx={{ color: theme.palette.secondary.dark, fontSize: 25, fontWeight: 'bold' }} >{e.title}</Typography>
                                                <Typography sx={{ color: theme.palette.secondary.dark, fontSize: 15 }} >{e.subtitle}</Typography>
                                            </Box>
                                            <Box sx={{ background: theme.palette[e.color].light, height: 50, width: 50, borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {getIcon(e.icon, theme.palette[e.color].dark)}
                                            </Box>
                                        </Box>
                                    </BoxHover>
                                )
                            })}
                        </Box >
                        <Box>
                            <BoxHover width={'auto'} padding={0.1} margin={3}>
                                <LineChart
                                    dataset={data.dataset}
                                    series={[
                                        {
                                            dataKey: 'total',
                                            label: 'Vendas este mês',
                                            stroke: '#E63946',
                                        },
                                    ]}
                                    xAxis={[{ scaleType: 'band', dataKey: 'day', label: 'Dia' }]}
                                    width={900}
                                    height={400}
                                />
                            </BoxHover>
                        </Box>
                        <BoxHover width={'auto'} padding={2} margin={3}>
                            <Typography sx={{ color: theme.palette.secondary.dark, fontSize: 30, fontWeight: 'bold', alignSelf: 'flex-start' }}>Vendas Recentes</Typography>
                            <TablePedidos data={data.lastVendas} />
                        </BoxHover>
                    </Box>
            }
        </Box >
    )
}
