import { createContext, useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from "../components/encDec";
import { FBFADM } from "../apis/FBFAdm";
import moment from "moment";



export const Loja = createContext()

export default function ({ children }) {
    const [data, setData] = useState(null)
    const cookies = new Cookies()
    const bandeira = process.env.REACT_APP_API_APP_BANDEIRA
    async function getLoja() {
        let user = cookies.get(`FBFMarketplace${bandeira}-loja`)
        if (!user) {
            setData(false)
            return
        }
        user = decrypt(user)
        if (!user.token) {
            setData(false)
            return
        }
        const validaCliente = await FBFADM('validadeToken', 'GET', null, user.token)
        if (validaCliente.status !== 200) {
            cookies.remove(`FBFMarketplace${bandeira}-loja`)
            setData(false)
            return
        }
        setData(validaCliente?.apiReturn?.apiReturn[0])
    }
    async function editCliente(newUser) {
        const save = await FBFADM('marketplace/private/editCliente', 'POST', newUser, data.token)
        const apiData = save?.apiReturn?.apiReturn
        if (apiData) {
            cookies.set(`FBFMarketplace${bandeira}-loja`, encrypt(apiData), {
                //secure: true,
                expires: moment().add(3, 'days').toDate(),
                sameSite: 'strict',
                maxAge: 999999,
                path: '/'
            })
            setData(apiData)
            return true
        } else {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao editar o cliente.'
            return message
        }
    }

    async function updateLoja(newUser) {
        cookies.set(`FBFMarketplace${bandeira}-loja`, encrypt(newUser), {
            //secure: true,
            expires: moment().add(3, 'days').toDate(),
            sameSite: 'strict',
            maxAge: 999999,
            path: '/'
        })
        setData(newUser)
        getLoja()
    }
    function logOff() {
        cookies.remove(`FBFMarketplace${bandeira}-loja`)
        setData(false)
    }

    useEffect(() => {
        getLoja()
    }, [])

    return (
        <Loja.Provider value={{ data, updateLoja }}>
            {children}
        </Loja.Provider>
    )
}