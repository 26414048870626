import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Compras } from "./compras";
import { MeusEnderecos } from "./endereços";
import { NovoEnd } from "../../finalizar-compra/components/novoEnd";
import { useNavigate } from "react-router-dom";
import { MeusDados } from "./meusDados";



export function Tela({ modulos, scrollToTop }) {
    const [activeModulo, setActiveModulo] = useState(2)
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search);
    const urlMod = urlParams.get('mod')
    function changeCadEndereco() {
        navigate('/minha-conta?mod=1')
    }

    useEffect(() => {
        if (urlMod) {
            setActiveModulo(urlMod)
        }
    }, [urlMod])
    return <ModToComp mod={activeModulo} modulos={modulos} changeCadEndereco={changeCadEndereco} scrollToTop={scrollToTop} />
}

function ModToComp({ mod, modulos, changeCadEndereco, scrollToTop }) {
    const mods = {
        0: <Compras />,
        1: <MeusEnderecos />,
        2: <MeusDados scrollToTop={scrollToTop} />,
        10: <Box sx={{ width: '100%', padding: 2 }}><NovoEnd changeCadEndereco={changeCadEndereco} /></Box>
    }
    if (mods.hasOwnProperty(mod)) return mods[mod]
    return <Typography>{modulos[mod]?.title}</Typography>
}