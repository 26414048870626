import { useEffect, useState } from "react";
import { Alert, Avatar, Box, Button, CircularProgress, Divider, IconButton, Modal, Portal, Typography, useMediaQuery, useTheme } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { FBFADM } from "../apis/FBFAdm";
import { Link } from "react-router-dom";
import { celularMask, cepMask, cnpjMask, telefoneMask } from "./masks";

export function ModalLoja({ visible, dismiss, loja }) {
    const theme = useTheme()
    const [data, setData] = useState(null)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    async function getData() {
        const get = await FBFADM(`marketplace/public/getLoja/${loja}`, 'GET')
        const apiData = get?.apiReturn?.apiReturn
        console.log(apiData)
        if (!Array.isArray(apiData) || apiData.length === 0) {
            setData(false)
        } else {
            setData(apiData[0])
        }
    }
    function handleDismiss() {
        if (dismiss) {
            dismiss()
        }
    }
    useEffect(() => {
        if (visible) {
            getData()
        } else {
            setData(null)
        }
    }, [visible])
    return (
        <Portal>
            <Modal onClose={handleDismiss} open={visible} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{
                    display: 'flex', '&::-webkit-scrollbar': { background: theme.palette.background.default },
                    '&::-webkit-scrollbar-thumb': { background: theme.palette.background.paper }, overflowY: 'auto', overflowX: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 2, width: '30%', height: '80%', background: theme.palette.background.default, outline: 'none', borderRadius: 3, position: 'relative', [theme.breakpoints.down('md')]: { width: '50%', height: '70%', }, [theme.breakpoints.down('lg')]: { width: '40%', height: '70%' }, [theme.breakpoints.down('sm')]: { width: '100%', height: '100%', },
                }}>
                    <IconButton sx={{ position: 'absolute', top: 8, right: 8, color: theme.palette.text.primary }} onClick={handleDismiss}><CloseIcon /></IconButton>
                    {data ?
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 3, padding: 2, width: '90%' }}>
                            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                                <Avatar src={data.avatar} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                <Box>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{data.nom_fantasia}</Typography>
                                    <Button variant={'outlined'} color="secondary" sx={{ textTransform: 'none', marginTop: 2 }} component={Link} to={`/loja?id=${loja}`}>Ver mais produtos da loja</Button>
                                </Box>
                            </Box>
                            <Divider />
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Dados da Loja</Typography>
                                <Box>
                                    <Typography>{data.raz_social}</Typography>
                                    <Typography>{`${data.rua}, ${data.numero}`}</Typography>
                                    <Typography>{`${data.bairro}, ${data.cidade_nome} - ${data.estado_uf}`}</Typography>
                                    <Typography>{`${cepMask(data.cnpj)}`}</Typography>
                                    <Typography>{`CNPJ: ${cnpjMask(data.cnpj)}`}</Typography>
                                    <Typography>{`AFE: ${cnpjMask(data.AFE)}`}</Typography>
                                    <Typography>{`Licença sanitária: ${data.alvara}`}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Contato</Typography>
                                <Box>
                                    <Typography>{`E-mail: ${data.email}`}</Typography>
                                    <Typography>{`Telefone: ${telefoneMask(data.telefone)}`}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Horário de funcionamento</Typography>
                                <Box>
                                    <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
                                        {`Telefone: ${data.hor_funcionamento}`}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Farmacêutico responsável</Typography>
                                <Box>
                                    <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
                                        {`${data.farm_resp}`}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        : data === null ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                                <Typography sx={{ marginTop: 2 }}>Carregando...</Typography>
                            </Box>
                        ) : (
                            <Alert severity="warning">Nenhum dado encontrado para esta loja.</Alert>
                        )}
                </Box>
            </Modal>
        </Portal >
    )
}