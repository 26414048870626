import { useEffect, useState } from "react";
import { Box, Typography, Skeleton, Pagination, useTheme, useMediaQuery, Select, MenuItem } from "@mui/material"
import { OneProduct } from "../../../components/oneProduct"

export function ListItens({ produtos, tamanho, handleChangePage, changeLimit }) {
    const theme = useTheme()
    const allProdutos = Array.isArray(produtos) ? [...produtos] : null
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [order, setOrder] = useState('Mais procurados');
    const legendaTamanho = tamanho === 0 ? '0 produtos' : `${tamanho} produtos`

    function handleChangePagination(event, value) {
        handleChangePage(value);
        setPage(value);
    }

    function handleOrderChange(event) {
        setOrder(event.target.value);
    }

    function handleItemsPerPageChange(value) {
        setItemsPerPage(value);
        changeLimit(value)
        setPage(1);
    }
    function orderProdutos() {
        if (!allProdutos) return null
        if (order === 'Menor preço') {
            const newP = allProdutos.sort((a, b) => parseFloat(a.vlr_final) - parseFloat(b.vlr_final))
            return newP
        }
        else if (order === 'Maior preço') {
            const newP = allProdutos.sort((a, b) => parseFloat(b.vlr_final) - parseFloat(a.vlr_final))
            return newP
        }
        return allProdutos
    }
    const sortProdutos = orderProdutos()

    useEffect(() => {
        setPage(1)
    }, [tamanho])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? 'flex-end' : 'space-between', alignItems: 'center', padding: '10px 0' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>Ordenar:</Typography>
                    <Select
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        size="small"
                        sx={{ minWidth: 150 }}
                    >
                        <MenuItem value="Mais procurados">Mais procurados</MenuItem>
                        <MenuItem value="Menor preço">Menor preço</MenuItem>
                        <MenuItem value="Maior preço">Maior preço</MenuItem>
                    </Select>
                </Box>
                {!isSmallScreen && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography>Exibir:</Typography>
                        <Select
                            value={String(itemsPerPage)}
                            onChange={(e) => handleItemsPerPageChange(e.target.value)}
                            size="small"
                            sx={{ minWidth: 100 }}
                        >
                            <MenuItem value={8}>8 por página</MenuItem>
                            <MenuItem value={20}>20 por página</MenuItem>
                            <MenuItem value={50}>50 por página</MenuItem>
                        </Select>
                        <Typography>{legendaTamanho}</Typography>
                    </Box>
                )}
            </Box>
            <Box sx={{ display: isMobile ? 'grid' : 'flex', gridTemplateColumns: '1fr 1fr', justifyContent: isMobile ? 'center' : 'space-between', flexWrap: 'wrap', width: '100%', gap: 0 }}>
                {sortProdutos ? (
                    sortProdutos.length > 0 ? sortProdutos.map((e, idx) => (
                        <OneProduct key={idx} produto={e} margin={isMobile ? 0.1 : 0.5} width={isMobile ? '75%' : 200} isTiniPrice={true} />
                    )) : (
                        <Box sx={{ display: 'flex', padding: 2, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ width: '50%' }}>
                                <Typography>Ops! Não foram encontrados produtos para os filtros selecionados.</Typography>
                            </Box>
                        </Box>
                    )
                ) : (
                    Array.from(new Array(6)).map((_, index) => (
                        <Box key={index} sx={{ width: 250, margin: 2 }}>
                            <Skeleton variant="rectangular" width={250} height={280} />
                            <Skeleton />
                            <Skeleton width="60%" />
                        </Box>
                    ))
                )}
            </Box>
            {(tamanho + 1) > itemsPerPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4, width: '100%' }}>
                    <Pagination
                        count={Math.ceil((tamanho + 1) / itemsPerPage)}
                        page={page}
                        onChange={handleChangePagination}
                        color="primary"
                    />
                </Box>
            )}
        </Box>
    )
}
