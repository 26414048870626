import { Box, Button, Collapse, Divider, Drawer, Icon, IconButton, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useWindowDimensions from "../useWindowDimensions";
import { getIcon } from "../icons";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Cliente } from "../../contexts/cliente";

const activeDataInit = {
    categs: [],
    deptos: [],
    title: ''
}
const MenuData = [
    { title: 'Minha Conta', icon: 33, link: '/minha-conta', color: 'primary' },
    { title: 'Meus pedidos', icon: 50, link: '/minha-conta?mod=0', color: 'secondary' },
    { title: 'Favoritos', icon: 84, link: '/favoritos', color: 'tertiary' },
    { title: 'Portal Loja', icon: 21, link: '/conta-loja', color: 'info' }
]

export function DrawerHeader({ open, classEcom, closeDrawer }) {
    const theme = useTheme()
    const [whtColl, setWhtColl] = useState(1)
    const [activeData, setActiveData] = useState(activeDataInit)
    const [activeId, setActiveId] = useState(null)
    const { data: clienteData } = useContext(Cliente)
    const { height: screenHeight } = useWindowDimensions()
    function updateActiveData(menu, data, title, type, id) {
        const newActiveData = { ...activeData }
        newActiveData[type] = data ? data : newActiveData[type]
        if (menu > whtColl) {
            newActiveData.title = newActiveData.title.concat(` / ${title}`)
        }
        else {
            newActiveData.title = newActiveData.title.substring(0, newActiveData.title.lastIndexOf(' / '));
        }
        setActiveId(id)
        setActiveData(newActiveData)
        setWhtColl(menu)
    }

    function handleDimiss() {
        closeDrawer()
    }
    useEffect(() => {
        setWhtColl(1)
        setActiveData(activeDataInit)
    }, [open])
    return (
        <Drawer open={open} onClose={handleDimiss} PaperProps={{
            sx: {
                '::-webkit-scrollbar': {
                    background: theme.palette.background.default,
                },
                '::-webkit-scrollbar-thumb': {
                    background: theme.palette.background.paper
                },
                overflowY: 'auto', // Para permitir o scroll
            }
        }}>
            <Box sx={{ background: theme.palette.background.default, display: 'flex', flexGrow: 1, flexDirection: 'column', width: 350, gap: 2, }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Box component={Link} to={'/'} sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', paddingLeft: 3, alignItems: 'center' }} onClick={handleDimiss}>
                        <img src={theme.palette.logo} style={{ width: 120, height: 60, objectFit: 'contain' }} />
                    </Box>
                    <Box sx={{ width: '100%', paddingLeft: 2 }}>
                        <Divider />
                        {clienteData?.token ? (
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', padding: 1, textAlign: 'left', justifyContent: 'space-between', paddingRight: 2 }}>
                                <Box>
                                    <Typography sx={{ color: theme.palette.secondary.dark, fontSize: 22, fontWeight: 'bold' }}>{`Olá, ${clienteData?.nome.split(' ')[0]}!`}</Typography>
                                    <Typography sx={{ color: theme.palette.secondary.dark }}>Seja bem vindo.</Typography>
                                </Box>
                                <Icon sx={{ display: 'flex', background: theme.palette.warning.main, width: 50, height: 50, borderRadius: 25, justifyContent: 'center', alignItems: 'center' }}>
                                    {getIcon(33, theme.palette.text.onPrimary, 25)}
                                </Icon>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', gap: 2, padding: 2, justifyContent: 'center', paddingRight: 4 }}>
                                {['Entrar', 'Cadastre-se'].map((e, idx) => {
                                    const bg = idx % 2 ? theme.palette.warning.main : theme.palette.secondary.main
                                    const color = theme.palette.text.onPrimary
                                    const to = idx === 0 ? '/entrar' : '/registrar'
                                    return (
                                        <Button component={Link} to={to} variant='contained' size='large' color="tertiary" fullWidth sx={{ color: color, background: bg, textTransform: 'none' }} onClick={handleDimiss}>{e}</Button>
                                    )
                                })}

                            </Box>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box>
                            <Divider />
                        </Box>
                        {MenuData.map((e, idx) => {
                            return (
                                <Box>
                                    <Button component={Link} to={e.link} key={idx} fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none', padding: 2 }} onClick={handleDimiss}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, alignItems: 'center' }}>
                                            <Icon sx={{ background: theme.palette[e.color].main, width: 40, height: 40, borderRadius: 20 }}>
                                                {getIcon(e.icon, theme.palette.text.onPrimary, 20)}
                                            </Icon>
                                            <Typography sx={{ color: theme.palette.secondary.dark }}>{e.title}</Typography>
                                        </Box>
                                    </Button>
                                    <Divider />
                                </Box>
                            )
                        })}
                        <IconButton onClick={handleDimiss} sx={{ position: 'absolute', top: 10, right: 10, background: theme.palette.divider }}>
                            <CloseIcon sx={{ color: theme.palette.text.onPrimary }} />
                        </IconButton>
                    </Box>
                </Box>
                {[1, 2, 3].map((e) => {
                    const whatData = e === 1 ? classEcom : e === 2 ? activeData.categs : e === 3 && activeData.deptos
                    const parameter = e === 1 ? 'grupo_nome' : e === 2 ? 'categ_nome' : e === 3 && 'depto_nome'
                    const parameterId = e === 1 ? 'grupo_id' : e === 2 ? 'categ_id' : e === 3 && 'depto_id'
                    const funcParameter = e === 1 ? 'categs' : 'deptos'
                    const urlParam = e === 2 ? 'grupo' : 'categoria'
                    const title = e === 1 ? 'Grupos' : e === 2 ? `Categorias` : e === 3 && `Departamentos`
                    return (
                        <Collapse key={e} in={whtColl === e} orientation="vertical">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingLeft: 1, paddingRight: 1 }}>
                                <Box >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography sx={{ color: theme.palette.secondary.main, fontWeight: 'bold', fontSize: 23 }}>{title}</Typography>
                                        {e !== 1 &&
                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'flex-end' }}>
                                                <Button size='small' onClick={() => updateActiveData(e - 1)} sx={{ textTransform: 'none', fontWeight: 'bold', color: theme.palette.text.primary }}>Voltar</Button>
                                                <Button component={Link} to={`buscar?${urlParam}=${activeId}`} size='small' onClick={handleDimiss} sx={{ textTransform: 'none', fontWeight: 'bold' }}>Ver todos</Button>
                                            </Box>
                                        }
                                    </Box>
                                    {activeData.title &&
                                        <Button sx={{ textTransform: 'none', padding: 0, textAlign: 'left' }} onClick={() => updateActiveData(e - 1)}>
                                            <Typography sx={{ color: theme.palette.secondary.main, fontSize: 12 }}>{activeData.title}</Typography>
                                        </Button>
                                    }
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                overflowY: 'scroll', height: screenHeight / 1.4, '&::-webkit-scrollbar': {
                                    background: theme.palette.background.default,
                                }, '&::-webkit-scrollbar-thumb': {
                                    background: theme.palette.background.paper
                                }
                            }}>
                                {Array.isArray(whatData) && whatData.map((b, idx) => {
                                    return (
                                        <Box key={idx} sx={{ width: '100%' }}>
                                            <Button component={Link} to={e === 3 ? `/buscar?depto=${b.depto_id}` : null} sx={{ textTransform: 'none', textAlign: 'left', justifyContent: 'flex-start', padding: 1 }} fullWidth size="small"
                                                onClick={() => e !== 3 ? updateActiveData(e + 1, b[funcParameter], b[parameter], funcParameter, b[parameterId]) : handleDimiss()}
                                            >
                                                <Typography sx={{ color: theme.palette.secondary.dark, textAlign: 'left' }}>{b[parameter]}</Typography>
                                            </Button>
                                            <Divider />
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Collapse>
                    )
                })}
            </Box>
        </Drawer>
    )
}