import { FBFADM } from "../apis/FBFAdm";

const { createContext, useState, useEffect } = require("react");

export const Cep = createContext()

export function CepContext({ children }) {
    const [data, setData] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    async function updateCep(value) {
        const get = await FBFADM('marketplace/public/getCep', 'POST', { cep: value })
        if (get?.status === 200 && get?.apiReturn?.apiReturn?.estado) {
            setData(value)
            return true
        } else return false
    }
    function updateData(value) {
        setData(value)
    }
    function updateDrawerOpen() {
        setIsDrawerOpen(!isDrawerOpen)
    }
    return (
        <Cep.Provider value={{ data, updateCep, updateData, isDrawerOpen, updateDrawerOpen }}>
            {children}
        </Cep.Provider>
    );
}