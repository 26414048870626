import { useState } from "react";
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";




export function ProdutoMain({ info }) {
    const { image, produto_nome, grupo_edi, marca_nome, marca_id, descricao, categ_edi } = info
    const theme = useTheme()
    const { headers, newData: rows } = formatData(info)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const [descCol, setDescCol] = useState(true)
    const formatDescricao = descCol ? descricao.slice(0, 150) : descricao
    const verText = !descCol ? 'Ver Menos' : 'Ver Mais'
    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={image} style={{ objectFit: 'contain', width: 300, height: 300 }} />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 25, color: theme.palette.secondary.dark }}>{produto_nome}</Typography>
                        <Divider />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {headers.map((header, index) => {
                                        const bgColor = index % 2 ? '#eee' : 'transparent'
                                        return (
                                            <TableRow key={index} sx={{ background: bgColor }}>
                                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', color: '#000', borderBottom: 0 }}>
                                                    {header}
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: 0 }}>
                                                    {Object.values(rows)[index]}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {[800, 801, 802].includes(grupo_edi) && ![805, 818, 830, 831, 832].includes(categ_edi) && (
                        <Box sx={{ border: 1, borderColor: '#000', padding: 1 }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 11, color: theme.palette.text.secondary }}>{`${produto_nome} É UM MEDICAMENTO. SEU USO PODE TRAZER RISCOS. PROCURE UM MÉDICO OU UM FARMACÊUTICO. LEIA A BULA. MEDICAMENTOS PODEM CAUSAR EFEITOS INDESEJADOS. EVITE A AUTOMEDICAÇÃO: INFORME-SE COM O FARMACÊUTICO.`}</Typography>
                        </Box>
                    )}
                    <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: formatDescricao }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ height: 1.5, width: '100%', background: theme.palette.divider }} />
                        <Typography component={'button'} onClick={() => setDescCol(!descCol)} sx={{ background: 'transparent', border: 'none', width: '100%' }}>{verText}</Typography>
                        <Box sx={{ height: 1.5, width: '100%', background: theme.palette.divider }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}


function formatData(data) {
    const acceptedData = ['cod_barra', 'dosagem', 'fab_nome', 'marca_nome', 'ms', 'peso', 'princ_nome', 'produto_quantidade']
    const nameToNome = {
        dosagem: 'Dosagem',
        fab_nome: 'Fabricante',
        marca_nome: 'Marca',
        ms: 'MS',
        peso: 'Peso (kg)',
        princ_nome: 'Princípio Ativo',
        quantidade: 'Quantidade',
        cod_barra: 'EAN',
        produto_quantidade: 'Quantidade'
    }
    const headers = []
    const newData = { ...data }
    for (const props in newData) {
        if (!acceptedData.includes(props) || [null, undefined, ''].includes(newData[props])) {
            delete newData[props]
        }
        else {
            headers.push(nameToNome[props])
        }
    }
    return { headers, newData }
}

const controladosInfo = {
    'Antimicrobianos': {
        'C1': 'Texto',
        'B1': 'Texto',
    },
    'Controle Especial': {
        'C1': 'Text',
        'B1': 'texto'
    }
}