import { Alert, Box, Button, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Cliente } from "../../../contexts/cliente";
import { AuthTextField } from "../../../components/textFields";
import { cepMask, onlyInteger } from "../../../components/masks";
import { ValidaCEP } from "../../../components/validaCampo";
import { FBFADM } from "../../../apis/FBFAdm";
import { getIcon } from "../../../components/icons";





export function NovoEnd({ changeCadEndereco }) {
    const { updateEndereco } = useContext(Cliente);
    const [data, setData] = useState(dataInit)
    const [isLoading, setIsLoading] = useState(false)
    const [mainError, setMainError] = useState(null)
    const [campErros, setCampErros] = useState([])
    const cepIsValid = ValidaCEP(data.cep)
    const theme = useTheme()
    function changeData(value, type, mask) {
        const newD = { ...data }
        newD[type] = mask ? mask(value) : value
        setData(newD)
    }

    function disabledLoading() {
        setTimeout(() => {
            setIsLoading(false)
        }, 300)
    }
    function validaCamposVazios() {
        let newErrors = [...campErros]
        const exCamps = ['complemento']
        Object.keys(data).map((e) => {
            if (!cepIsValid && e !== 'cep') return
            if (exCamps.includes(e)) return
            const findIndex = newErrors.findIndex((b) => b.campo === e);
            if (!data[e]) {
                const objErr = {
                    campo: e,
                    err: 'Campo precisa ser preenchido'
                }
                if (findIndex === -1) {
                    newErrors.push(objErr)
                }
                else {
                    newErrors[findIndex] = objErr
                }
            }
            else {
                newErrors = newErrors.filter((b) => b.campo !== e)
            }
        })
        return newErrors
    }

    async function saveEnd() {
        const valida = validaCamposVazios()
        if (valida.length > 0) {
            setCampErros(valida)
            return
        } else {
            setCampErros([])
        }
        setIsLoading(true)
        const trySave = await updateEndereco(data)
        setIsLoading(true)
        if (trySave !== true) {
            setMainError(trySave)
            return
        }
        changeCadEndereco(0)
    }
    async function getCep() {
        setIsLoading(true)
        const get = await FBFADM('marketplace/public/getCep', 'POST', { cep: data.cep })
        const apiData = get?.apiReturn?.apiReturn
        if (!apiData) {
            setMainError('Ocorreu um erro ao consultar o seu cep.')
            disabledLoading()
            return
        }
        setMainError('')
        const newD = { ...data, ...apiData }
        setData(newD)
        disabledLoading()
    }

    useEffect(() => {
        if (ValidaCEP(data.cep)) {
            getCep()
        }
    }, [data.cep])
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', gap: 0, flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>Cadastrar endereço</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button onClick={() => changeCadEndereco(0)} sx={{ textTransform: 'none', color: theme.palette.secondary.main }}>
                        {getIcon(96, theme.palette.secondary.main)}
                        Voltar aos endereços
                    </Button>
                </Box>
            </Box>
            <Box sx={{ border: 1, borderColor: theme.palette.divider, padding: 3, display: 'flex', flexDirection: 'column', gap: 5 }}>
                {mainError && (
                    <Alert severity='error'>{mainError}</Alert>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography>CEP *</Typography>
                        <AuthTextField fullWidth value={data.cep} changeText={changeData} prop={'cep'} placeHolder={'00000-000'} mask={cepMask} errors={campErros} />
                    </Box>
                    <Box sx={{ flex: 1, alignSelf: 'flex-end' }}>
                        <Typography>Não sabe seu CEP?</Typography>
                        <Typography sx={{ color: theme.palette.secondary.main, textDecoration: 'none', fontWeight: 'bold' }} component={'a'} href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blanck">Buscar no site dos correios</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Box sx={{ flex: 2 }}>
                        <Typography>Rua *</Typography>
                        <AuthTextField disabled={!cepIsValid} fullWidth value={data.rua} changeText={changeData} prop={'rua'} errors={campErros} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography>Número *</Typography>
                        <AuthTextField disabled={!cepIsValid} fullWidth value={data.numero} changeText={changeData} prop={'numero'} mask={onlyInteger} errors={campErros} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Box sx={{ flex: 2 }}>
                        <Typography>Bairro *</Typography>
                        <AuthTextField disabled={!cepIsValid} fullWidth value={data.bairro} changeText={changeData} prop={'bairro'} errors={campErros} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Box sx={{ flex: 2 }}>
                        <Typography>Cidade *</Typography>
                        <AuthTextField disabled fullWidth value={data.cidade} changeText={changeData} prop={'cidade'} errors={campErros} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography>Estado *</Typography>
                        <AuthTextField disabled fullWidth value={data.estado} changeText={changeData} prop={'estado'} errors={campErros} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Box sx={{ flex: 2 }}>
                        <Typography>Complemento</Typography>
                        <AuthTextField disabled={!cepIsValid} fullWidth value={data.complemento} changeText={changeData} prop={'complemento'} errors={campErros} />
                    </Box>
                </Box>
                <Button variant='contained' fullWidth color="secondary" size='large' sx={{ color: theme.palette.text.onPrimary, textTransform: 'none', fontWeight: 'bold', padding: 1.5 }} onClick={saveEnd}>Cadastrar novo endereço</Button>
            </Box>
            {isLoading && (
                <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                    {getIcon(25, theme.palette.secondary.main, 70, false, true)}
                    <Typography sx={{ fontSize: 20, color: theme.palette.text.primary }}>Buscando Cep</Typography>
                </Box>
            )}
        </Box>
    )
}

const dataInit = {
    cep: '',
    rua: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
}