import { createContext, useContext, useEffect, useState } from "react";
import { Cliente } from "./cliente";
import { FBFADM } from "../apis/FBFAdm";
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from "../components/encDec";
import moment from 'moment'



export const Favoritos = createContext()

export default function FavoritosContext({ children }) {
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const cliente = useContext(Cliente)
    const clienteToken = cliente?.data?.token
    const cookies = new Cookies()
    const bandeira = process.env.REACT_APP_API_APP_BANDEIRA
    async function getData() {
        setLoading(true)
        const getCookie = cookies.get(`FBFMarketplace${bandeira}-favoritos`)
        const favCookie = getCookie ? decrypt(getCookie) : null
        if (clienteToken) {
            const body = favCookie ? favCookie : null
            const manageApiCk = await FBFADM('marketplace/private/manageFv', 'POST', body, clienteToken)
            const apiData = manageApiCk?.apiReturn?.apiReturn
            if (manageApiCk?.status !== 200 || !Array.isArray(apiData)) {
                if (favCookie) {
                    setData(favCookie)
                    setLoading(false)
                    return
                }
            }
            setData(apiData)
            cookies.remove(`FBFMarketplace${bandeira}-favoritos`)
        } else if (favCookie) {
            let fav = favCookie ? favCookie : null
            if (fav) {
                const manageApiCk = await FBFADM('marketplace/public/manageFv', 'POST', fav, clienteToken)
                const apiData = manageApiCk?.apiReturn?.apiReturn
                if (manageApiCk?.status === 200 && Array.isArray(apiData)) {
                    fav = apiData
                }
                setData(fav)
                cookies.set(`FBFMarketplace${bandeira}-favoritos`, encrypt(fav), {
                    expires: moment().add(365, 'days').toDate(),
                    sameSite: 'strict',
                    maxAge: 999999,
                    path: '/'
                })
            }
        }
        setLoading(false)
    }
    async function add(item) {
        setLoading(item)
        item.quantidade = item.quantidade || 1
        let newD = [...data]
        const findIndex = newD.findIndex((e) => e.loja == item?.loja && e.cod_barra === item?.cod_barra)
        if (clienteToken) {
            const body = {
                cod_barra: item?.cod_barra,
                loja: item?.loja,
                quantidade: item?.quantidade || 1
            }
            const endpoint = findIndex === -1 ? 'add' : 'del'
            const save = await FBFADM(`marketplace/private/manageFv/${endpoint}`, 'POST', body, clienteToken)
            item.qtd_estoque = save?.apiReturn?.apiReturn || 0
            if (save.status !== 200) {
                setLoading(false)
                return
            }
            if (findIndex === -1) {
                newD.push(item)
            }
            else {
                newD = newD.filter((_, idx) => idx !== findIndex)
            }
        } else {
            if (findIndex === -1) {
                newD.push(item)
            }
            else {
                newD = newD.filter((_, idx) => idx !== findIndex)
            }
            const x = cookies.set(`FBFMarketplace${bandeira}-favoritos`, encrypt(newD), {
                //secure: true,
                expires: moment().add(365, 'days').toDate(),
                sameSite: 'strict',
                maxAge: 999999,
                path: '/'
            })
        }
        setTimeout(() => {
            setLoading(false)
        }, 500)
        setData(newD)
    }
    useEffect(() => {
        getData()
    }, [cliente])
    return (
        <Favoritos.Provider value={{ data, add, isLoading }}>
            {children}
        </Favoritos.Provider>
    )
}