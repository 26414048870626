import { Box, Button, Collapse, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "../../components/container";
import { useContext, useState } from "react";
import { TestaCPF, TestaEMAIL, TestaSenha } from "../../components/validaCampo";
import { cpfMask } from "../../components/masks";
import { getIcon } from "../../components/icons";
import { Link } from "react-router-dom";
import { AuthTextField } from '../../components/textFields'
import { FBFADM } from "../../apis/FBFAdm";
import { ModalInfo } from "../../components/modalInfo";
import { Cliente } from "../../contexts/cliente";



export function Login() {
    const theme = useTheme()
    const cliente = useContext(Cliente)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'))
    const [data, setData] = useState({
        usuario: '',
        senha: ''
    })
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    const [campErros, setCampErros] = useState([])
    function changeAuth(value, type) {
        const newA = { ...data }
        value = TestaCPF(value) ? cpfMask(value) : value
        newA[type] = value
        setData(newA)
    }

    async function Entrar() {
        if (testaCampos()) return
        updateModalInfo(true, false, 'Carregando', 'Efetuando o login.', 'loading')
        const get = await FBFADM('marketplace/public/loginCliente', 'POST', data)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao criar sua conta.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        cliente.updateCliente(get?.apiReturn?.apiReturn)
        updateModalInfo()
    }
    function testaCampos() {
        const newErrors = validaCamposVazios();
        let hasErrors = newErrors.length > 0;

        const handleErrors = (campo, isValid, errorMessage) => {
            const findIndex = newErrors.findIndex((e) => e.campo === campo);
            if (!isValid) {
                hasErrors = true;
                if (findIndex === -1) {
                    newErrors.push({ campo, err: errorMessage });
                } else {
                    newErrors[findIndex] = { campo, err: errorMessage };
                }
            } else if (findIndex !== -1) {
                newErrors.splice(findIndex, 1); // Remove o erro se o campo for válido
            }
        };
        if (data.usuario) {
            const isCPF = TestaCPF(data.usuario);
            const isEmail = TestaEMAIL(data.usuario);
            if (!isCPF && !isEmail) {
                handleErrors('usuario', false, 'CPF ou E-mail inválido preenchido');
            } else {
                handleErrors('usuario', true, ''); // Remove erros, se válido
            }
        }
        if (data.senha) handleErrors('senha', TestaSenha(data.senha), 'Senha precisa ter no mínimo 8 caracteres.')
        setCampErros(newErrors);
        return hasErrors;
    }
    function validaCamposVazios() {
        let newErrors = [...campErros]
        Object.keys(data).map((e) => {
            const findIndex = newErrors.findIndex((b) => b.campo === e);
            if (!data[e]) {
                const objErr = {
                    campo: e,
                    err: 'Campo precisa ser preenchido'
                }
                if (findIndex === -1) {
                    newErrors.push(objErr)
                }
                else {
                    newErrors[findIndex] = objErr
                }
            }
            else {
                newErrors = newErrors.filter((b) => b.campo !== e)
            }
        })
        return newErrors
    }
    return (
        <Container>
            <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 8, alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: isMobile ? '100%' : isSmallScreen ? '60%' : '70%', alignItems: 'center', gap: 1 }}>
                    {getIcon(30, theme.palette.primary.main, 70)}
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30, color: '#000', textAlign: 'center' }}>Olá! Para continuar é necessário ter uma conta.</Typography>
                    <Typography sx={{ color: '#000', textAlign: 'center' }}>Se ainda não possui uma, crie uma no botão abaixo. Se não, faça login para acessar seus dados.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'center', gap: 5 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 3, width: '100%' }}>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 25, color: '#000' }}>Já é um cliente?</Typography>
                            <Typography sx={{}}>Faça o login abaixo para acessar sua conta.</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold' }}>E-mail ou CPF*</Typography>
                            <AuthTextField icon={getIcon(33)} fullWidth size="small" value={data.usuario} changeText={changeAuth} prop={'usuario'} errors={campErros} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Senha</Typography>
                            <AuthTextField icon={getIcon(93)} type='password' fullWidth size="small" value={data.senha} changeText={changeAuth} prop={'senha'} errors={campErros} />
                            <Typography component={Link} to={'/redefinir-senha'} sx={{ alignSelf: 'flex-end', textAlign: 'right', textDecoration: 'none', color: theme.palette.text.primary }}>Esqueceu sua senha?</Typography>
                        </Box>
                        <Box >
                            <Button onClick={Entrar} sx={{ background: theme.palette.primary.main, color: theme.palette.text.onPrimary, fontWeight: 'bold', padding: 2, '&:hover': { background: theme.palette.secondary.main } }} fullWidth>
                                Entrar
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3, width: '100%' }}>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 25, color: '#000' }}>Ainda não sou cliente</Typography>
                            <Typography>Faça seu cadastro no botão abaixo para aproveitar de todos os nossos benefícios.</Typography>
                        </Box>
                        <Button component={Link} to='/registrar' sx={{ background: theme.palette.background.paper, color: theme.palette.text.secondary, fontWeight: 'bold', padding: 2, '&:hover': { background: theme.palette.tertiary.main, color: theme.palette.text.onPrimary } }} fullWidth>
                            Cadastre-se
                        </Button>
                    </Box>
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Container >
    )
}