import { Box, useMediaQuery, useTheme } from "@mui/material";
import useWindowDimensions from "./useWindowDimensions";



export function Container({ children, overflow, paddingRight, paddingLeft }) {
    const theme = useTheme()
    const { height: screenHeight, width: screenWidth } = useWindowDimensions()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <Box
            id='main_container'
            sx={{
                overflow: overflow ? overflow : 'hidden',
                width: screenWidth < 1600 ? '100%' : '70%',
                paddingLeft: paddingLeft ? paddingLeft : 2,
                paddingRight: paddingRight ? paddingRight : 2,
                margin: '0 auto',
                maxWidth: screenWidth
            }}
        >
            {children}
        </Box>
    )
}