import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Cliente } from "./cliente";
import { FBFADM } from "../apis/FBFAdm";
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from "../components/encDec";
import moment from 'moment'



export const Carrinho = createContext()

export default function CarrinhoContext({ children }) {
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [openCart, setOpenCart] = useState(false)
    const [tempo, setTempo] = useState(20000)
    const cliente = useContext(Cliente)
    const clienteToken = cliente?.data?.token
    const cookies = new Cookies()
    const bandeira = process.env.REACT_APP_API_APP_BANDEIRA
    async function getData(atualiza) {
        if (!atualiza) {
            setLoading(true)
        }
        const getCookie = cookies.get(`FBFMarketplace${bandeira}-carrinho`)
        const cartCookie = getCookie ? decrypt(getCookie) : null
        if (clienteToken) {
            const body = cartCookie ? cartCookie : null
            const manageApiCk = await FBFADM('marketplace/private/manageCk', 'POST', body, clienteToken)
            const apiData = manageApiCk?.apiReturn?.apiReturn
            if (manageApiCk?.status !== 200 || !Array.isArray(apiData)) {
                if (cartCookie) {
                    setData(cartCookie)
                    setLoading(false)
                    return
                }
            }
            setData(apiData)
            cookies.remove(`FBFMarketplace${bandeira}-carrinho`)
        } else if (cartCookie) {
            let cart = cartCookie ? cartCookie : null
            if (cart) {
                const manageApiCk = await FBFADM('marketplace/public/manageCk', 'POST', cart, clienteToken)
                const apiData = manageApiCk?.apiReturn?.apiReturn
                if (manageApiCk?.status === 200 && Array.isArray(apiData)) {
                    cart = apiData
                }
                setData(cart)
                cookies.set(`FBFMarketplace${bandeira}-carrinho`, encrypt(cart), {
                    expires: moment().add(365, 'days').toDate(),
                    sameSite: 'strict',
                    maxAge: 999999,
                    path: '/'
                })
            }
        }
        setLoading(false)
    }
    function resetTempo() {
        setTempo(20000)
    }
    async function add(item) {
        setLoading(item)
        resetTempo()
        item.quantidade = item.quantidade || 1
        let newD = [...data]
        const findIndex = newD.findIndex((e) => e.loja == item?.loja && e.cod_barra === item?.cod_barra)
        if (clienteToken) {
            const body = {
                cod_barra: item?.cod_barra,
                loja: item?.loja,
                quantidade: item?.quantidade || 1
            }
            const endpoint = findIndex === -1 ? 'add' : 'del'
            const save = await FBFADM(`marketplace/private/manageCk/${endpoint}`, 'POST', body, clienteToken)
            item.qtd_estoque = save?.apiReturn?.apiReturn || 0
            if (save.status !== 200) {
                setLoading(false)
                return
            }
            if (findIndex === -1) {
                newD.push(item)
            }
            else {
                newD = newD.filter((_, idx) => idx !== findIndex)
            }
        } else {
            if (findIndex === -1) {
                newD.push(item)
            }
            else {
                newD = newD.filter((_, idx) => idx !== findIndex)
            }
            const x = cookies.set(`FBFMarketplace${bandeira}-carrinho`, encrypt(newD), {
                //secure: true,
                expires: moment().add(365, 'days').toDate(),
                sameSite: 'strict',
                maxAge: 999999,
                path: '/'
            })
        }
        setTimeout(() => {
            setLoading(false)
        }, 500)
        setData(newD)
    }
    async function changeQuantidade(item, quantidade) {
        setLoading(true)
        resetTempo()
        item.quantidade = quantidade || 1
        let newD = [...data]
        const findIndex = newD.findIndex((e) => e.loja == item?.loja && e.cod_barra === item?.cod_barra)
        if (findIndex === -1) {
            setLoading(false)
            return
        }
        if (clienteToken) {
            const body = {
                cod_barra: item?.cod_barra,
                loja: item?.loja,
                quantidade: item?.quantidade
            }
            const endpoint = 'add'
            const save = await FBFADM(`marketplace/private/manageCk/${endpoint}`, 'POST', body, clienteToken)
            const apiData = save?.apiReturn
            if (save.status !== 200) {
                setLoading(false)
                return
            }
            if (apiData?.valid !== 'Ok') {
                item.error = apiData?.valid?.length > 5 ? apiData?.valid : 'Ocorreu um erro ao atualizar a quantidade'
                item.quantidade = apiData?.apiReturn
            }
            else {
                delete item?.error
            }
            newD[findIndex] = item
        } else {
            const manageApiCk = await FBFADM('marketplace/public/manageCk', 'POST', newD)
            const apiData = manageApiCk?.apiReturn?.apiReturn
            if (manageApiCk?.status !== 200 && !Array.isArray(apiData)) {
                setLoading(false)
                return
            }
            newD = apiData
            cookies.set(`FBFMarketplace${bandeira}-carrinho`, encrypt(newD), {
                //secure: true,
                expires: moment().add(365, 'days').toDate(),
                sameSite: 'strict',
                maxAge: 999999,
                path: '/'
            })
        }
        setTimeout(() => {
            setLoading(false)
        }, 500)
        setData(newD)
    }
    function handleCart(value) {
        if (value) {
            setOpenCart(value)
        }
        else {
            setOpenCart(!openCart)
        }
    }
    useEffect(() => {
        getData()
    }, [cliente])

    useEffect(() => {
        if (tempo < 2000) {
            resetTempo()
            getData(true)
        }
        const interval = setInterval(() => {
            setTempo((tempo) => tempo - 1000)
        }, 1000)
        return () => clearInterval(interval)
    }, [tempo])
    return (
        useMemo(() => (
            <Carrinho.Provider value={{ data, add, changeQuantidade, isLoading, openCart, handleCart, getData }}>
                {children}
            </Carrinho.Provider>
        ), [data, isLoading, openCart])
    )
}