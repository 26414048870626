import { useState, useMemo, useEffect, createContext } from "react";
import { createTheme } from "@mui/material/styles";
import Cookies from 'universal-cookie';
import { BrasilTheme } from "./layouts/brasilTheme";


export const ColorModeContext = createContext({
    toggleColorMode: () => { }
})
export const useMode = () => {
    const [mode, setMode] = useState("light")
    const cookies = new Cookies()
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                cookies.set('FBFMarketplace_mode', mode === 'dark' ? 'light' : 'dark', { path: '/', maxAge: 999999999999999 });
                setMode((prev) => (prev === 'dark' ? 'light' : 'dark'))
            }
        })
    )
    useEffect(() => {
        const getMode = cookies.get('FBFMarketplace_mode')
        if (getMode) {
            setMode(getMode)
        }
    }, [])
    const theme = useMemo(() => createTheme(BrasilTheme(mode)), [mode])
    return [theme, colorMode, mode]
}