import { Box, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Container } from "../../components/container";




export function ErrorPage() {
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error')
    const theme = useTheme()

    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Box>
                    <Typography sx={{ color: theme.palette.tertiary.main, fontWeight: 'bold', fontSize: 120, textAlign: 'center' }}>404</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'center' }}>Ops!!!</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}>Ocorreu um erro ao processar essa tarefa</Typography>
                    <Typography sx={{ textAlign: 'center' }}>{errors(error)}</Typography>
                </Box>
            </Box>
        </Container>
    )
}



const errors = (erro) => {
    const whatErrors = {
        '1': 'Parâmetros de busca inválidos.',
        '2': 'Servidor não respondeu com os dados solicitados.',
    }
    if (whatErrors.hasOwnProperty(erro)) return whatErrors[erro]
    return 'Erro não identificado'

}