import { TextField, InputAdornment, useTheme } from '@mui/material'
import { forwardRef, useMemo } from 'react';


export function AuthTextField({ fullWidth, placeHolder, icon, type, value, mask, prop, changeText, errors, disableTab, disabled, size, isSubmit, enterFunc, label }) {
    const haveError = errors ? errors.filter((e) => e.campo === prop) : []
    const error = haveError.length > 0 ? haveError[0] : false
    const handleKeyDown = (event) => {
        if (event.key === 'Tab' && disableTab) {
            event.preventDefault();
        }
        if (event.key === 'Enter' && enterFunc) {
            enterFunc()
        }
    };
    return (
        <TextField fullWidth={fullWidth} size={size ? size : 'medium'} placeholder={placeHolder}
            type={type ? type : 'text'}
            disabled={disabled ? disabled : false}
            value={value}
            label={label && label}
            InputProps={{
                startAdornment: icon && (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                        {icon}
                    </InputAdornment>
                ),
                style: {
                    borderTopRightRadius: isSubmit && 0,
                    borderBottomRightRadius: isSubmit && 0
                }
            }}
            helperText={error && error?.err}
            error={error}
            onChange={(value) => changeText(value.target.value, prop, mask)}
            onKeyDown={handleKeyDown}
        />
    )
}

export const CTextField = forwardRef(({ value, type, onChangeText, label, security, icon, iconPosition, autoFocus, onKeyPress, prevData, variant, width, disabled }, ref) => {
    const theme = useTheme()
    return (
        useMemo(() => (
            <TextField
                inputRef={ref ? ref : null}
                disabled={disabled ? disabled : false}
                autoFocus={autoFocus ? autoFocus : false}
                variant={variant ? variant : 'standard'}
                onKeyDown={(value) => {
                    if (onKeyPress) {
                        onKeyPress(value, type)
                    }
                }}
                InputLabelProps={{
                    style: { color: theme.palette.text.primary },
                }}
                sx={{
                    width: width ? width : '30%',
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1',
                        borderColor: theme.palette.divider
                    },
                    color: theme.palette.text.primary
                }}
                type={security ? 'password' : 'text'}
                InputProps={icon ? {
                    startAdornment: iconPosition === 'start' ? <InputAdornment position={'start'} sx={{ marginRight: '8px' }}>{icon}</InputAdornment> : null,
                    endAdornment: iconPosition === 'end' ? icon : null
                } : null}
                label={label ? label : ' '}
                value={value}
                onChange={(value) => {
                    if (onChangeText) {
                        onChangeText(value.target.value, type)
                    }
                }}
            />
        ), [value, type, prevData, width])
    )
})

export function MultTextField({ fullWidth, placeHolder, icon, type, value, mask, prop, changeText, errors, disableTab, disabled, size, isSubmit, enterFunc, label, preWrap, minRows }) {
    const haveError = errors ? errors.filter((e) => e.campo === prop) : []
    const error = haveError.length > 0 ? haveError[0] : false
    const handleKeyDown = (event) => {
        if (event.key === 'Tab' && disableTab) {
            event.preventDefault();
        }
        if (event.key === 'Enter' && enterFunc) {
            enterFunc()
        }
    };
    return (
        <TextField fullWidth={fullWidth} size={size ? size : 'medium'} placeholder={placeHolder}
            type={type ? type : 'text'}
            disabled={disabled ? disabled : false}
            value={value}
            label={label && label}
            rows={5}
            multiline
            InputProps={{
                startAdornment: icon && (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                        {icon}
                    </InputAdornment>
                ),
                style: {
                    borderTopRightRadius: isSubmit && 0,
                    borderBottomRightRadius: isSubmit && 0,
                }
            }}
            helperText={error && error?.err}
            error={error}
            onChange={(value) => changeText(value.target.value, prop, mask)}
            onKeyDown={handleKeyDown}
        />
    )
}