import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { getIcon } from "../../../components/icons";
import { useContext } from "react";
import { Cliente } from "../../../contexts/cliente";
import { Link } from "react-router-dom";



export function Menu({ modulos }) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { data, logOff } = useContext(Cliente)
    const nome = data.nome.split(' ')[0]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ fontSize: 30, fontWeight: 'bold' }}>Olá, {nome}</Typography>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column', flexWrap: isMobile && 'wrap', }}>
                {modulos.map((e, idx) => {
                    const to = idx === 3 ? '/redefinir-senha' : `/minha-conta?mod=${idx}`
                    return (
                        <Link key={idx} to={to} style={{ color: theme.palette.text.secondary, textAlign: 'left', textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, padding: 2, paddingLeft: 0 }}>
                                {getIcon(e.icon, theme.palette.secondary.main)}
                                <Typography>{e.title}</Typography>
                            </Box>
                        </Link>
                    )
                })}
            </Box>
            <Divider />
            <Box>
                <Link style={{ color: theme.palette.text.secondary, textAlign: 'left', textDecoration: 'none' }} onClick={() => logOff()}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, padding: 2, paddingLeft: 0 }}>
                        {getIcon(43, theme.palette.secondary.main)}
                        <Typography sx={{ textDecoration: 'none' }}>Sair</Typography>
                    </Box>
                </Link>
            </Box>
        </Box >
    )
}